<div class="top-of-form"></div>
<merchant-app-card
  [title]="'Add bank account'"
  [description]="
    'Enter the bank account information where funds will be deposited and debited. Checking accounts are preferred.'
  "
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form
    data-test-id="bank-account-step"
    class="w-full"
    [formGroup]="bankAccountForm"
    [ngClass]="{
      '-mt-6': !hasBankVerificationEnabled
    }"
  >
    <!-- used for hidden element to define button width -->
    <form [formGroup]="hiddenForm" class="invisible -mb-13 opacity-0">
      <tilled-input [merchantApp]="true" name="hidden" class="pointer-events-none flex"> </tilled-input>
    </form>
    <div [class]="!forConsole ? 'w-1/2 pr-8' : ''">
      <div *ngIf="!showBankAccountForm" class="mb-5">
        <ul class="space-y-3 text-tilled-primary">
          <li>
            <tilled-paragraph-p3>Ensure that your bank account:</tilled-paragraph-p3>
            <ul class="ml-7 list-disc leading-normal">
              <li>
                <tilled-paragraph-p3
                  >Is registered with the same legal name or DBA as your merchant account.</tilled-paragraph-p3
                >
              </li>
              <li><tilled-paragraph-p3>Can receive both ACH credits and debits.</tilled-paragraph-p3></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="isLoading" class="mt-6 flex w-1/2 flex-col border-b border-t pb-3">
      <div *ngIf="falconProcessing" class="align-center font-semiboldr mb-6 mt-3 self-center">
        Preparing your data on our super secure servers.
      </div>
      <mat-spinner class="mx-auto" diameter="50"></mat-spinner>
    </div>

    <div [hidden]="isLoading" [ngClass]="{ 'flex w-1/2 flex-col': hasPlaid && !showBankAccountForm }">
      <div
        [attr.style]="'width: ' + tilledInputWidth"
        class="flex"
        [ngClass]="{
          'flex-col': !showBankAccountForm
        }"
      >
        <!-- Connect with Plaid -->
        <div
          *ngIf="!verifiedByPlaid && hasPlaid"
          data-test-id="bank-account-connect-plaid"
          class="bank-connect-wrapper mb-3 flex w-full items-center self-center rounded-md"
          [ngClass]="{
            'tilled-gradient': !(isWhiteLabel$ | async)
          }"
        >
          <button
            class="connect-plaid-btn z-50 flex w-full flex-row items-center justify-center bg-white hover:bg-tilled-secondary-50"
            [ngClass]="{
              'max-h-16 rounded-md border-2 border-black': (isWhiteLabel$ | async),
              'm-[0.5px] max-h-[60px] rounded-[5px]': !(isWhiteLabel$ | async)
            }"
            (click)="connectWithPlaidInstead()"
          >
            <tilled-paragraph-p3
              [bold]="true"
              class="*:flex *:items-center"
              [styles]="!(isWhiteLabel$ | async) ? 'h-[61px]' : 'h-16'"
            >
              Connect with Plaid
              <mat-icon class="ml-2 text-tilled-primary" fontSet="material-symbols-rounded"> account_balance</mat-icon>
            </tilled-paragraph-p3>
          </button>
        </div>

        <!-- Connect manually -->
        <div
          *ngIf="!showBankAccountForm || verifiedByPlaid"
          data-test-id="bank-account-connect-manual"
          class="bank-connect-wrapper mb-3 flex w-full items-center self-center rounded-md"
        >
          <button
            class="connect-manually-btn z-50 flex max-h-16 w-full flex-row items-center justify-center rounded-md border-2 border-black hover:bg-tilled-secondary-50"
            (click)="verifiedByPlaid ? deletePlaidVerifiedAccount() : showManualVerification()"
          >
            <tilled-paragraph-p3
              [bold]="true"
              class="text-tilled-primary"
              [styles]="'flex h-16 items-center text-tilled-primary'"
            >
              Enter details manually
              <mat-icon class="ml-2 text-tilled-primary" fontSet="material-symbols-rounded"> account_balance</mat-icon>
            </tilled-paragraph-p3>
          </button>
        </div>
      </div>
      <tilled-paragraph-p3 class="mt-2 flex w-11/12" [styles]="'leading-normal'" *ngIf="!showBankAccountForm">
        By connecting your account with Plaid, you can skip providing a Bank Letter or Voided Check.
        <a
          class="inline-flex font-semibold underline"
          href="https://paymentsonline.zendesk.com/hc/en-us/articles/30743606830100"
          target="_blank"
          >Learn more about Plaid
          <mat-icon
            class="ml-1.5 pb-px text-tilled-primary icon-size-5"
            [svgIcon]="'heroicons_outline:arrow-top-right-on-square'"
          ></mat-icon>
        </a>
      </tilled-paragraph-p3>
      <!-- Bank Account Details -->
      <div *ngIf="showBankAccountForm" class="grid w-full grid-cols-1">
        <!-- first row -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="account-holder"
            class="w-full"
            name="accountHolder"
            label="Account Holder or Business Name"
            tilledLabel="true"
            placeholder="Enter the name on the account"
          ></tilled-input>
        </div>
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="bank-name"
            class="w-full"
            name="bankName"
            label="Bank Name"
            tilledLabel="true"
            placeholder="Enter the name of the bank"
          ></tilled-input>
        </div>
        <!-- third row -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="account-number"
            class="w-full"
            name="accountNumber"
            label="Account Number"
            tilledLabel="true"
            placeholder="Enter the account number"
            maxLength="20"
          ></tilled-input>
        </div>
        <!-- Confirm account number -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            *ngIf="showBankAccountForm && showManualUpload && !verifiedByPlaid"
            [merchantApp]="true"
            data-test-id="confirm-account-number"
            class="w-full"
            [ngClass]="{ 'pointer-events-none opacity-50': !bankAccountForm.get('accountNumber').value }"
            name="confirmAccountNumber"
            label="Confirm Account Number"
            tilledLabel="true"
            placeholder="Re-enter the account number"
            [maxLength]="bankAccountForm.get('accountNumber').value?.length"
            [readonly]="!bankAccountForm.get('accountNumber').value"
            [errors]="
              bankAccountForm.get('accountNumber').value &&
              bankAccountForm.get('confirmAccountNumber').hasError('accountNumberMismatch') &&
              bankAccountForm.get('confirmAccountNumber').touched
            "
            errorMessage="Account numbers do not match."
          ></tilled-input>
        </div>
        <!-- fourth row -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="routing-number"
            class="w-full"
            name="routingNumber"
            placeholder="Enter the routing number"
            [label]="routingNumberLabel"
            tilledLabel="true"
            maxLength="9"
            [errors]="
              bankAccountForm.get('routingNumber').hasError('invalidRoutingNumber') &&
              bankAccountForm.get('routingNumber').touched
            "
            errorMessage="{{ routingNumberLabel }} is invalid."
          ></tilled-input>
        </div>
      </div>
    </div>
    <!-- Manual Upload -->
    <div [hidden]="!showManualUpload" class="mt-6" [attr.style]="'width: ' + tilledInputWidth">
      <tilled-paragraph-p2 bold="true"> Upload a Voided Check or Bank Letter </tilled-paragraph-p2>
      <!-- Voided Check Guidelines -->
      <div class="mt-2.5" [ngClass]="{ 'mr-8': !forConsole }">
        <div>
          <ul class="space-y-3 text-tilled-primary">
            <li>
              <tilled-paragraph-p3 [bold]="true">Voided Check Guidelines</tilled-paragraph-p3>
              <tilled-paragraph-p3>The Voided Check should:</tilled-paragraph-p3>
              <ul class="ml-7 mt-px list-disc leading-normal">
                <li>
                  <tilled-paragraph-p3>Be a pre-printed voided check (not a temporary check). </tilled-paragraph-p3>
                </li>
                <li>
                  <tilled-paragraph-p3>Include the merchant legal name.</tilled-paragraph-p3>
                </li>
                <li>
                  <tilled-paragraph-p3>Include the merchant business address. </tilled-paragraph-p3>
                </li>
              </ul>
            </li>
          </ul>
          <!-- Bank Letter Guidelines -->
          <ul class="space-y-3 pt-6 text-tilled-primary">
            <li>
              <tilled-paragraph-p3 [bold]="true">Bank Letter Guidelines</tilled-paragraph-p3>
              <tilled-paragraph-p3>The Bank Letter should: </tilled-paragraph-p3>
              <ul class="ml-7 mt-px list-disc leading-normal">
                <li>
                  <tilled-paragraph-p3>Be on a bank letterhead.</tilled-paragraph-p3>
                </li>
                <li>
                  <tilled-paragraph-p3>Include the registered name of your business.</tilled-paragraph-p3>
                </li>
                <li>
                  <tilled-paragraph-p3>Include an ACH routing number. </tilled-paragraph-p3>
                </li>
                <li>
                  <tilled-paragraph-p3>Include an ACH account number.</tilled-paragraph-p3>
                </li>
                <li>
                  <tilled-paragraph-p3
                    >Be signed and dated by a bank representative within the last 6 months.
                  </tilled-paragraph-p3>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <app-file-upload
        class="-ml-1 mb-6 pt-8"
        [fileCountLimit]="2"
        [isMerchantApp]="true"
        [showOverlay]="false"
        [purpose]="filePurpose"
        [allowedFileTypes]="fileTypes"
        [fileDescriptions]="fileDescriptions"
        [existingFiles$]="existingFiles$"
        [slim]="true"
        (fileDeleted)="fileDeleted($event)"
        (pendingFilesChange)="pendingFilesChange($event)"
        [requiredFileDescriptions]="['Voided Check', 'Bank Letter']"
        (requiredFilesUploaded)="requiredFilesUploaded($event)"
        [fileAccountId]="fileAccountId"
      ></app-file-upload>
    </div>
  </form>
</merchant-app-card>
