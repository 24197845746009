import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AUTH_LOGIN_ROUTE, HOMEPAGE_ROUTE } from 'app/core/constants';
import { filter, map, of, take } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!AuthService.isTokenExpired() || !AuthService.isRefreshTokenExpired()) {
      return this.authService.account$.pipe(
        filter((account) => account != null),
        take(1),
        map(() => {
          if (this.authService.isPartnerUser()) {
            return true;
          } else {
            // Logged in user but not a partner account. Redirect to dashboard
            this.router.navigate(['/' + HOMEPAGE_ROUTE]);
            return false;
          }
        }),
      );
    } else {
      // No one logged in or token is expired
      // Redirect to login
      this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
      return of(false);
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!AuthService.isTokenExpired() || !AuthService.isRefreshTokenExpired()) {
      return this.authService.account$.pipe(
        filter((account) => account != null),
        take(1),
        map(() => {
          if (this.authService.isPartnerUser()) {
            return true;
          } else {
            // Logged in user but not a partner account. Redirect to dashboard
            this.router.navigate(['/' + HOMEPAGE_ROUTE]);
            return false;
          }
        }),
      );
    } else {
      // No one logged in or token is expired
      // Redirect to login
      this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
      return of(false);
    }
  }
}
