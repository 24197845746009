<div class="top-of-form"></div>
<merchant-app-card
  [title]="title || null"
  [description]="description || null"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async) && !isLoading"
>
  <form data-test-id="details-step" class="w-full" [formGroup]="businessDetailsForm" *ngIf="!isLoading">
    <div class="col-span-1 flex w-full flex-col" *ngIf="busTypeStep">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 class="w-full" secondary="true">Business type</tilled-label-l1>
      </div>
      <tilled-select
        [classOverride]="forConsole ? 'w-full' : ''"
        [merchantApp]="true"
        data-test-id="details-entity-type"
        [multiple]="false"
        [options]="entityTypesOptions"
        [controlName]="businessDetailsForm.get('type')"
        [placeholder]="'Choose One'"
        [errors]="businessDetailsForm.get('type')?.hasError('required') && businessDetailsForm.get('type')?.touched"
        [ngClass]="{
          'mb-2': businessDetailsForm.get('type')?.hasError('required') && businessDetailsForm.get('type')?.touched
        }"
        errorMessage="A business entity type is required"
        [hint]="'Choose your legal business structure as registered for tax filing.'"
      ></tilled-select>
      <a
        class="mt-2 flex w-fit"
        href="https://paymentsonline.zendesk.com/hc/en-us/articles/35420853263124-Onboarding-FAQ"
        target="_blank"
      >
        <tilled-paragraph-p3 [bold]="true" [styles]="'underline'">
          Learn more about business types
        </tilled-paragraph-p3>
        <mat-icon
          class="ml-2 text-tilled-primary icon-size-5"
          [svgIcon]="'heroicons_outline:arrow-top-right-on-square'"
        ></mat-icon>
      </a>
    </div>
    <ng-container *ngIf="!busTypeStep">
      <!-- Verification status alerts -->
      <ng-container *ngIf="verificationAlerts">
        <div
          *ngFor="let alert of verificationAlerts"
          class="mb-6 flex flex-row space-x-4 rounded-xl border border-[#F8D562] bg-[#FFF6D7] px-4 py-3"
          [attr.style]="'width: ' + tilledInputWidth"
        >
          <div class="flex w-full flex-col space-y-4">
            <tilled-paragraph-p3 bold="true">{{ alert.title }}</tilled-paragraph-p3>
            <div class="whitespace-pre-wrap" [innerHTML]="alert.message"></div>
            <div class="btnGroup flex flex-nowrap justify-evenly space-x-2">
              <tilled-button
                *ngIf="alert?.ignoreText"
                styles="flex-1 w-full outline-tilled-neutral-400 {{
                  (isWhiteLabel$ | async) ? ' *:*:*:text-tilled-secondary ' : ''
                }}"
                class="w-full"
                (buttonClick)="handleVerificationAction(alert, false)"
                [buttonText]="alert?.ignoreText"
                [secondary]="true"
                [forceWhite]="false"
                [whiteText]="false"
                bgColor="white"
                outlineColor="tilled-neutral-400"
                [attr.data-test-id]="'ignore-suggestion-btn'"
              ></tilled-button>
              <tilled-button
                [styles]="'flex-1 w-full'"
                class="w-full"
                (buttonClick)="handleVerificationAction(alert, alert?.updateText || alert?.reviewText ? true : false)"
                [buttonText]="alert?.updateText || alert?.reviewText"
                textColor="white"
                [forceWhite]="true"
                [whiteText]="true"
                [bgColor]="(isWhiteLabel$ | async) ? 'black' : 'tilled-primary'"
                rounded="true"
                [attr.data-test-id]="'review-update-btn'"
              ></tilled-button>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="mb-2 flex flex-row items-center" [attr.style]="'width: ' + tilledInputWidth">
        <tilled-heading-h5 class="self-center whitespace-nowrap pr-2 leading-normal tracking-normal">
          Business overview
        </tilled-heading-h5>
        <mat-divider class="w-full"></mat-divider>
      </div>
      <tilled-input
        [merchantApp]="true"
        data-test-id="details-legal-name"
        class="w-full"
        name="legalName"
        placeholder="Full Legal Business Name"
        label="LEGAL BUSINESS NAME"
        [hint]="
          merchantApp?.legal_entity?.structure === 'sole_proprietorship'
            ? 'Enter your full personal name here—it\'s your Sole Proprietorship\'s legal business name. You\'ll have a chance to add a \'Doing Business As\' (DBA) name later on.'
            : 'Enter your legal business name exactly as it appears on your IRS issued documents.'
        "
        tilledLabel="true"
      ></tilled-input>
      <tilled-input
        [merchantApp]="true"
        data-test-id="details-business-identifier"
        class="w-full"
        name="businessIdentifier"
        placeholder="Tax ID #, Canadian Business #, Social Security # or EIN"
        [label]="!isCanadian ? 'Tax ID / Employer Identification Number (EIN)' : 'Business number'"
        tilledLabel="true"
        mask="000000000"
        [errors]="
          businessDetailsForm.get('businessIdentifier')?.invalid &&
          businessDetailsForm.get('businessIdentifier')?.touched
        "
        errorMessage="Business Identifier must be 9 digits."
        [hint]="
          merchantApp?.legal_entity?.structure === 'sole_proprietorship'
            ? 'As a Sole Proprietorship, if you use your Social Security Number (SSN) for business tax purposes, you can enter that instead. We are required to collect this information to satisfy regulatory obligations.'
            : !isCanadian
              ? 'Enter your Tax ID / EIN exactly as it appears on your IRS issued documents.'
              : 'Enter your business number exactly as it appears on your CRA issued documents.'
        "
        [sentenceCaseTitle]="false"
      ></tilled-input>
      <tilled-input
        [merchantApp]="true"
        data-test-id="details-dba"
        class="w-full"
        name="dba"
        placeholder="Business “DBA” or Trading Name"
        label="Doing business as (optional)"
        tilledLabel="true"
        [hint]="'Enter the business name you present to customers, if it\'s different from your legal name.'"
        [sentenceCaseTitle]="false"
      ></tilled-input>
      <tilled-input
        [merchantApp]="true"
        data-test-id="details-date-of-inc"
        name="dateOfInc"
        label="Date of incorporation"
        placeholder="MM/DD/YYYY"
        [sentenceCasePlaceholder]="false"
        tilledLabel="true"
        mask="M0/d0/0000"
        [dropMaskCharacters]="false"
        [leadZeroDateTime]="true"
        [hint]="'Enter the date that the business was legally incorporated.'"
        [errors]="businessDetailsForm.get('dateOfInc')?.errors && businessDetailsForm.get('dateOfInc')?.touched"
        errorMessage="Date of incorporation must be a valid date."
      ></tilled-input>
      <div class="mb-2 mt-6 flex flex-row items-center" [attr.style]="'width: ' + tilledInputWidth">
        <tilled-heading-h5 class="self-center whitespace-nowrap pr-2 leading-normal tracking-normal">
          Business address
        </tilled-heading-h5>
        <mat-divider class="w-full"></mat-divider>
      </div>
      <div class="col-span-1 mt-4 flex flex-col">
        <div class="w-full pb-2.5">
          <tilled-label-l1 class="w-full" secondary="true">Address</tilled-label-l1>
        </div>
        <app-autocomplete
          [merchantApp]="true"
          data-test-id="contact-street"
          name="street"
          placeholder="123 Main St"
          [autocompleteAddress]="businessDetailsForm.value.street"
          (setAddress)="setBusinessAddress($event)"
          [hint]="
            merchantApp?.legal_entity?.structure === 'sole_proprietorship'
              ? 'You can use your home address if you don\'t have a business address. P.O. boxes are not accepted.'
              : 'This should be the address where your business is physically located. P.O. boxes are not accepted.'
          "
        ></app-autocomplete>
        <mat-error
          class="flex pt-1.5 text-sm font-medium text-warn"
          *ngIf="businessDetailsForm.get('street').errors && businessDetailsForm.get('street').touched"
        >
          P.O. boxes are not accepted.
        </mat-error>
      </div>
      <tilled-input
        [merchantApp]="true"
        data-test-id="contact-street2"
        class="w-full"
        name="street2"
        label="Apt, Suite, etc. (optional)"
        placeholder="Suite 123"
        [sentenceCaseTitle]="false"
        tilledLabel="true"
      ></tilled-input>
      <div class="col-span-1 flex flex-col">
        <tilled-input
          [merchantApp]="true"
          data-test-id="contact-city"
          name="city"
          placeholder="Louisville"
          label="CITY"
          tilledLabel="true"
        ></tilled-input>
      </div>
      <div class="col-span-1 flex flex-col">
        <div class="w-full pb-2.5 pt-4">
          <tilled-label-l1 secondary="true">{{ isCanadian ? 'Province' : 'State' }}</tilled-label-l1>
        </div>
        <tilled-select
          [merchantApp]="true"
          [multiple]="false"
          [options]="stateCodeMap"
          [placeholder]="'Choose One'"
          [controlName]="businessDetailsForm.get('state')"
          data-test-id="contact-state"
          [classOverride]="forConsole ? 'w-full' : ''"
        ></tilled-select>
      </div>
      <tilled-input
        [merchantApp]="true"
        data-test-id="contact-zip"
        class="w-1/2"
        name="zip"
        placeholder="80205"
        [label]="isCanadian ? 'Postal code' : 'Zip code'"
        tilledLabel="true"
        maxLength="10"
      ></tilled-input>
    </ng-container>
  </form>
  <div
    class="mr-auto flex flex-col space-y-10 border-y py-24 text-center"
    [ngClass]="{
      'w-7/12': windowWidth < 1280,
      'w-1/2': windowWidth >= 1280
    }"
    *ngIf="isLoading"
  >
    <div class="z-90">
      <div class="flex flex-col items-center space-y-6">
        <div class="max-w-120 text-center">
          <tilled-heading-h4>Verifying your business details</tilled-heading-h4>
        </div>
        <img
          class="max-w-24"
          [src]="(isWhiteLabel$ | async) ? '/assets/images/docusign_wl.png' : '/assets/images/docusign_default.png'"
        />
        <mat-progress-bar
          id="verification-load"
          class="h-4 w-10/12 rounded-lg *:rounded-2xl"
          [color]="'primary'"
          [mode]="'determinate'"
          [value]="progressValue$ | async"
          [ngClass]="{
            wl: (isWhiteLabel$ | async)
          }"
        >
        </mat-progress-bar>
      </div>
    </div>
  </div>
</merchant-app-card>
