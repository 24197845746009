import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentBase } from 'app/core/componentBase';
import { FileDownload } from 'app/core/models/file-download';
import { FileDownloadService } from 'app/core/services/file-download.service';
import { ReportRunsAppService } from 'app/core/services/report-runs.app.service';
import { Observable, Subscription, interval } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { FilesService } from '../../../../projects/tilled-api-client/src/api/files.service';
import { ReportRun } from '../../../../projects/tilled-api-client/src/model/reportRun';

@Component({
  selector: 'file-download',
  templateUrl: './file-download.component.html',
  standalone: true,
})
export class FileDownloadComponent extends ComponentBase implements OnInit, OnDestroy {
  public fileDownloads$: Observable<FileDownload>;
  private displaySubscription: Subscription;

  constructor(
    private _filesService: FilesService,
    private _fileDownloadService: FileDownloadService,
    private _reportRunsService: ReportRunsAppService,
  ) {
    super();
  }

  ngOnInit() {
    this.fileDownloads$ = this._fileDownloadService.newFileDownload$;

    this.displaySubscription = this.fileDownloads$.subscribe({
      next: (fileDownload) => {
        if (!fileDownload) {
          return;
        }
        let createReportResponse$ = this._reportRunsService.createReportRun1ClickDownload(
          fileDownload.reportRunParams,
          fileDownload.accountId,
        );
        createReportResponse$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
          next: (reportRuns) => {
            this._fileDownloadService.downloadCenterSubmitted();
            let downloadSub = interval(5000)
              .pipe(startWith(0), takeUntil(this._unsubscribeAll))
              .subscribe((res) => {
                let getReportResponse$ = this._reportRunsService.getReportRun(reportRuns.id, fileDownload.accountId);
                getReportResponse$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
                  next: (reportRun) => {
                    if (reportRun.status === ReportRun.StatusEnum.FINISHED) {
                      this._fileDownloadService.downloadsCenterStarted();
                      let file_name = reportRun.result?.filename
                        ? (reportRun.result.filename as string).split('/').pop()
                        : '';
                      let file_id = reportRun.result?.id ? reportRun.result.id : '';
                      this.downloadFile(file_id, file_name, fileDownload.accountId);
                      downloadSub.unsubscribe();
                    }
                    if (reportRun.status == ReportRun.StatusEnum.FAILED) {
                      this._fileDownloadService.donwloadCenterFailed(
                        'Could not create report - ' + reportRun.failure_message?.toLowerCase(),
                      );
                      downloadSub.unsubscribe();
                    }
                  },
                });
              });
          },
          error: (err) => {
            let errorMessage = 'Could not create report - ' + err?.error?.message?.toLowerCase();
            this._fileDownloadService.donwloadCenterFailed(errorMessage);
          },
        });
      },
    });
  }

  ngOnDestroy(): void {
    this._fileDownloadService.deleteAll();
    this.displaySubscription.unsubscribe();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  downloadFile(fileId: string, fileName: string, accountId: string): void {
    this._fileDownloadService.download(fileId, fileName, null, accountId);
  }
}
