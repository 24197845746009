import { Injectable } from '@angular/core';
import { ULD_TABLE_NAME } from 'app/core/constants';
import { Observable } from 'rxjs';
import { Account } from '../../../../../projects/tilled-api-client/src/model/account';
import { IndexedDBService } from '../indexed-db/indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class UserLocalDataService {
  constructor(private _indexedDbService: IndexedDBService) {}

  public getRecentAccounts(userId: string): Observable<UserLocalDataObject> {
    return this._indexedDbService.getByKey(ULD_TABLE_NAME, userId);
  }

  public updateRecentAccounts(value: RecentAccountData[], key: string): Observable<UserLocalDataObject> {
    let params: UserLocalDataObject = {
      id: key,
      recent_accounts: value,
    };
    return this._indexedDbService.update(ULD_TABLE_NAME, params);
  }
}

export class UserLocalDataObject {
  id: string;
  recent_accounts: RecentAccountData[];
}

export class RecentAccountData {
  account_id: string;
  type: Account.TypeEnum;
  street: string;
  name: string;
  owner_name: string;
}
