import { CommonModule, NgIf } from '@angular/common';
import {
  Attribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { NgxMaskModule } from 'ngx-mask';
import { SentenceCasePipe } from '../../../core/pipes/sentence-case.pipe';
import { AllowOnlyNumbersDirective } from '../../allow-only-numbers.directive';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP4Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';

@Component({
  selector: 'tilled-input',
  templateUrl: './tilled-input.component.html',
  styleUrls: ['../form-fields.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TilledLabelL1Component,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule,
    AllowOnlyNumbersDirective,
    TilledSelectComponent,
    MatIconModule,
    MatTooltipModule,
    TilledParagraphP4Component,
    SentenceCasePipe,
    CommonModule,
    NgIf,
  ],
})
export class TilledInputComponent implements OnInit {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() name?: string;
  @Input() control?: FormControl;
  @Input() groupName?: string;
  @Input() fieldType: string = 'text';
  @Input() styles?: string = '';
  @Input() showPercent?: boolean;
  @Input() showCurrency?: boolean;
  @Input() mask?: string;
  @Input() dropMaskCharacters?: boolean = true;
  @Input() leadZeroDateTime?: boolean = false;
  @Input() thousandSeparator?: string = ',';
  @Input() readonly?: boolean = false;
  @Input() errors?: boolean = false;
  @Input() errorMessage?: string;
  @Input() hideRequiredMarker?: boolean = false;
  @Input() minLength?: number;
  @Input() maxLength?: number = 255;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() appearance: 'fill' | 'outline' = 'fill';
  @Input() tilledLabel: boolean = false;
  @Input() sentenceCaseTitle?: boolean = true;
  @Input() sentenceCasePlaceholder?: boolean = true;
  @Input() toolTip: string = null;
  @Input() required: boolean = false;
  @Input() showClear?: boolean = false;
  @Input() hint?: string = null;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() prefixSuffixBorder?: 'prefix' | 'suffix' | 'both';
  @Input() prefixMultiple?: boolean = true;
  @Input() suffixMultiple?: boolean = true;
  @Input() prefixOptions?: { value: string; viewValue: string }[];
  @Input() suffixOptions?: { value: string; viewValue: string }[];
  @Input() prefixPlaceholder?: string;
  @Input() suffixPlaceholder?: string;
  @Input() suffixControlName?: FormControl;
  @Input() prefixControlName?: FormControl;
  @Input() suffixReadonly?: boolean;
  @Input() copyToClipboard?: boolean = false;
  @Input() copyClickText?: string = 'Generate';
  @Input() merchantApp?: boolean;
  @Output() copyClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFocus: EventEmitter<string> = new EventEmitter<string>();

  public windowWidth: any;
  public isFocused: boolean = false;
  public hasAppTrim: boolean;

  @ViewChild('nativeInput', { static: false })
  nativeInput: ElementRef<HTMLInputElement>;

  constructor(
    @Attribute('appTrim') appTrimAttr: string | null = null,
    private cdr: ChangeDetectorRef,
  ) {
    this.hasAppTrim = appTrimAttr != null;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
  }
  ngOnInit() {
    this.windowWidth = window.innerWidth;
    if (this.merchantApp) {
      this.merchantApp = document.querySelector('app-account-details') ? false : true;
    }
  }

  // This method should be wired to the native input’s (blur) event.
  // If the host element was marked with appTrim, we trim the input value.
  onInputBlur() {
    if (this.hasAppTrim && this.nativeInput) {
      const inputEl = this.nativeInput.nativeElement;
      if (typeof inputEl.value === 'string') {
        const trimmed = inputEl.value;
        if (trimmed !== inputEl.value) {
          // Use a setTimeout to let the current blur/validation cycle finish
          setTimeout(() => {
            // Update the native input’s value
            inputEl.value = trimmed;
            // If a FormControl is bound, update its value and force re-validation
            if (this.control) {
              // Use setValue with emitEvent so that Angular picks up the change
              this.control.setValue(trimmed, { emitEvent: true });
              this.control.updateValueAndValidity();
            }
            // Dispatch an input event so that Angular picks up the change immediately
            inputEl.dispatchEvent(new Event('input', { bubbles: true }));
            // Run change detection if needed
            this.cdr.detectChanges();
          }, 0);
        }
      }
    }
    this.onBlur.emit(this.name);
  }

  onInputFocus() {
    this.onFocus.emit(this.name);
    this.isFocused = true;
  }

  onCopyClick(text: string) {
    this.copyClick.emit(text);
  }
}
