/* eslint-disable @typescript-eslint/member-ordering */
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FuseAlertService } from '@fuse/components/alert';
import { ComponentBase } from 'app/core/componentBase';
import {
  ACH_DEBIT_TERMS_LINK_NAME,
  CARD_TERMS_LINK_NAME,
  PORTAL_TERMS_LINK_NAME,
  PRIVACY_POLICY_LINK_NAME,
} from 'app/core/constants';
import { MerchantApplicationStepType } from 'app/core/data/onboarding-types';
import { ApplicationStep, IsStepComplete } from 'app/core/models/application-step';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { TooltipListPipe } from 'app/core/pipes/tooltip-list.pipe';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import {
  LegalNameVerificationStatus,
  MiddeskBusinessWithVerification,
  TinVerificationStatus,
} from 'app/core/services/middesk.app.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import { TilledButtonComponent } from 'app/shared/buttons/tilled-button.component';
import { CardPresentPricingCardComponent } from 'app/shared/cards/card-present-pricing-card/card-present-pricing-card.component';
import {
  CardPresentPricingTemplateViewModel,
  CardPricingTemplateViewModel,
  DebitPricingTemplateViewModel,
} from 'app/shared/connected-account/connected-account-dialog/connected-account-dialog.component';
import { VerificationAlertConfig } from 'app/shared/merchant-app-steps/business-details/business-details-step.component';
import { DocusignLoadingComponent } from 'app/shared/merchant-app-steps/docusign-loading/docusign-loading.component';
import { TilledParagraphP3Component } from 'app/shared/tilled-text';
import { middeskEntityToTilledStructure } from 'app/shared/utils/onboarding-utils';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription, distinctUntilChanged, take, takeUntil } from 'rxjs';
import {
  InternalAccount,
  InternalService,
  MiddeskBusiness,
  MiddeskSuggestions,
  OnboardingApplication,
  OnboardingLegalEntity,
  PAArticlesOfIncorporation,
  PatriotActDetails,
  PricingTemplate,
  PrincipalCreateParams,
  SubmitApplicationResponse,
  SuggestionOptions,
  User,
  UserInvitation,
} from '../../../../../projects/tilled-api-client/src';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { CardPricingCardComponent } from '../../cards/card-pricing-card/card-pricing-card.component';
import { DebitPricingCardComponent } from '../../cards/debit-pricing-card/debit-pricing-card.component';
import { MerchantAppAlertComponent } from '../../cards/merchant-application/merchant-app-alert/merchant-app-alert.component';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledParagraphP1Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p1.component';

interface IPrincipalSigner {
  email: string;
  name: string;
  isPrimary: boolean;
  percentOwnership: number;
}

@Component({
  selector: 'submit-application-step',
  templateUrl: './submit-application-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    TilledHeadingH4Component,
    MerchantAppAlertComponent,
    CardPricingCardComponent,
    CardPresentPricingCardComponent,
    DebitPricingCardComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    TilledParagraphP1Component,
    TilledParagraphP3Component,
    TilledButtonComponent,
    MatFormFieldModule,
    FuseAlertComponent,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    DocusignLoadingComponent,
  ],
})
export class SubmitApplicationStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() merchantAccountId$: Observable<string> = null;
  @Input() stepNumber: number;
  @Input() allSteps: ApplicationStep[];
  @Output() inviteApplicant: EventEmitter<{ applicantInvite: boolean; emailExists: boolean; applicant: any }> =
    new EventEmitter<{
      applicantInvite: boolean;
      emailExists: boolean;
      applicant: any;
    }>();

  public submitApplicationForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public cardPricingTemplate: CardPricingTemplateViewModel;
  public cardPresentPricingTemplate: CardPresentPricingTemplateViewModel;
  public debitPricingTemplate: DebitPricingTemplateViewModel;
  public hasMultiple: boolean;
  private _cardPricingTemplate$ = new BehaviorSubject<CardPricingTemplateViewModel>(null);
  public cardPricingTemplate$ = this._cardPricingTemplate$.asObservable();
  private _cardPresentPricingTemplate$ = new BehaviorSubject<CardPresentPricingTemplateViewModel>(null);
  public cardPresentPricingTemplate$ = this._cardPresentPricingTemplate$.asObservable();
  private _debitPricingTemplate$ = new BehaviorSubject<DebitPricingTemplateViewModel>(null);
  public debitPricingTemplate$ = this._debitPricingTemplate$.asObservable();
  public disableButton: boolean;

  public verifyYourBusinessComplete: IsStepComplete;
  public businessTypeComplete: IsStepComplete;
  public businessDetailsComplete: IsStepComplete;
  public businessContactComplete: IsStepComplete;
  public applicationSignersComplete: IsStepComplete;
  public businessOwnersComplete: IsStepComplete;
  public productsAndServicesComplete: IsStepComplete;
  public paymentAcceptanceComplete: IsStepComplete;
  public processingVolumeComplete: IsStepComplete;
  public bankAccountComplete: IsStepComplete;
  public businessDocumentsComplete: IsStepComplete;
  public pricingAndTermsComplete: IsStepComplete;
  public canSubmitApplication: boolean;
  public termsAgreed: boolean;
  public canadianVisaMcProcessing: boolean;

  public merchantTermsLink: string;
  public portalTermsLink: string;
  public privacyPolicyLink: string;
  public achDebitTermsLink: string;

  private submittedApplicationErrors$: Observable<any>;
  private submittedApplicationResponse$: Observable<SubmitApplicationResponse>;
  private _displayAlert$ = new Subject<boolean>();
  public displayAlert$ = this._displayAlert$.asObservable();
  public alertMessage: string;
  private accountId: string;
  private _submittingApp$ = new Subject<boolean>();
  public submittingApp$ = this._submittingApp$.asObservable();
  private subscriptions: Subscription[] = [];

  private supportEmail: string;

  public businessTypeSubStep = MerchantApplicationStepType.BUSINESS_TYPE_SUB_STEP;
  public businessDetailsSubStep = MerchantApplicationStepType.BUSINESS_DETAILS_SUB_STEP;
  public businessContactSubStep = MerchantApplicationStepType.CONTACT_INFORMATION_SUB_STEP;
  public applicationSignersSubStep = MerchantApplicationStepType.APPLICATION_SIGNER_SUB_STEP;
  public businessOwnersSubStep = MerchantApplicationStepType.BUSINESS_OWNERS_SUB_STEP;
  public productsAndServicesSubStep = MerchantApplicationStepType.PRODUCTS_AND_SERVICES_SUB_STEP;
  public paymentAcceptanceSubStep = MerchantApplicationStepType.PAYMENT_ACCEPTANCE_SUB_STEP;
  public processingVolumesSubStep = MerchantApplicationStepType.PROCESSING_VOLUMES_SUB_STEP;
  public bankAccountStep = MerchantApplicationStepType.BANK_ACCOUNT;
  public businessDocumentsStep = MerchantApplicationStepType.BUSINESS_DOCUMENTS;

  public hasTsysProvider = false;
  public hasPaysafeProvider = false;
  public hasNorthProvider = false;
  public applicant: PrincipalCreateParams;
  public isPrimaryApplicant = false;
  public applicantExists = false;
  public users$ = this._usersAppService.users$;
  public users: User[] = [];
  public invitations$ = this._usersAppService.userInvitations$;
  public invitations: UserInvitation[] = [];
  public loading$ = new BehaviorSubject<boolean>(true);
  public merchantUsersAndInvitations: any = [];
  public verificationAlerts: VerificationAlertConfig[] = [];
  public middeskBusiness: MiddeskBusiness;
  public middeskBusiness$: Observable<MiddeskBusiness>;
  public isWhiteLabel$: Observable<boolean>;
  public signers: IPrincipalSigner[] = [];
  public signersTooltipText = '';
  public isCanadian: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _router: Router,
    private _fuseAlertService: FuseAlertService,
    private _alertService: AlertService,
    private _accountAppService: AccountAppService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _usersAppService: UsersAppService,
    private _internalService: InternalService,
    private _brandingService: BrandingService,
    private _tooltipListPipe: TooltipListPipe,
    private _sanitizer: DomSanitizer,
  ) {
    super();
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
    this.middeskBusiness$ = this._merchantAppService.middeskBusiness$;
  }

  ngOnInit(): void {
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.handleUpdatedApplication();
      });

    this.middeskBusiness$.pipe(take(2)).subscribe((business) => {
      if (business) {
        this.middeskBusiness = business;
        this.handleMiddeskSuggestions();
      }
    });

    if (this.disabled$) {
      this.disabled$.subscribe((isDisabled) => {
        if (isDisabled) {
          this.submitApplicationForm.disable();
          this.disableButton = true;
        } else {
          this.submitApplicationForm.enable();
          this.disableButton = false;
        }
      });
    }

    this._merchantAppService.hasTsysProvider$
      .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged())
      .subscribe((hasTsys) => {
        this.hasTsysProvider = hasTsys;
        if (this.hasTsysProvider) {
          this.getPrimaryApplicant();
        }
      });

    this._merchantAppService.hasPaysafeProvider$
      .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged())
      .subscribe((hasPaysafe) => {
        this.hasPaysafeProvider = hasPaysafe;
      });

    this._merchantAppService.hasNorthProvider$
      .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged())
      .subscribe((hasNorth) => {
        this.hasNorthProvider = hasNorth;
        if (this.hasNorthProvider) {
          this.getPrimaryApplicant();

          if (!this.signers) {
            this.signers = [];
          }
          for (const principal of this.merchantApp?.legal_entity?.principals) {
            if (principal.is_applicant || principal.percent_ownership >= 25) {
              this.signers.push({
                email: principal.email,
                name: principal.first_name + ' ' + principal.last_name,
                isPrimary: principal.is_applicant,
                percentOwnership: principal.percent_ownership,
              });
            }
          }
          this.signers.sort((a, b) => (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0));
        }
      });

    this._merchantAppService.updateProviderData();

    this.submittedApplicationResponse$ = this._merchantAppService.submittedApplicationResponse$;
    this.submittedApplicationResponse$.subscribe({
      next: (response) => {
        this._submittingApp$.next(false);
        const { signing_links } = response;
        if (this.forConsole) {
          const message: TilledAlert = {
            message: `Application for ${this.merchantApp.legal_entity?.legal_name} was submitted successfully`,
            title: 'Application submitted',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);
          this.goToStep(this.businessDetailsSubStep);
          this._accountAppService.getConnectedAccountById(this.accountId);
        } else {
          // eventually, we will have multiple links
          // but for now, we can just take the first/only link
          const url = signing_links[0]?.url;

          if (url) {
            window.location.href = url;
          } else {
            this._router.navigate(['/onboarding/submitted']);
          }
        }
      },
    });

    this.submittedApplicationErrors$ = this._merchantAppService.submittedApplicationErrors$;
    this.submittedApplicationErrors$.subscribe({
      next: (errResponse) => {
        this._submittingApp$.next(false);
        if (errResponse?.error?.message) {
          const text = errResponse.error.message.split('\n');
          this.alertMessage = '\u2022' + text.splice(0, text.length - 1).join('\n\u2022');

          if (this.alertMessage != '\u2022') {
            this._displayAlert$.next(true);
            this._fuseAlertService.show('merchantAppAlertBox');
          }

          if (this.alertMessage === '\u2022') {
            const message: TilledAlert = {
              message: `There was an error submitting your merchant application, please contact ${this.supportEmail} for next steps`,
              title: 'Submission error',
              type: 'error',
            };
            this._alertService.showAlert(message);
          }
        }
      },
    });

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: async (account) => {
        this._internalService.internalGetAccountSettingsBranding({ tilledAccount: account.id }).subscribe((asb) => {
          // defaults
          this.supportEmail = 'support@tilled.com';

          // customize for white labeled merchant
          if (
            asb &&
            asb.is_white_label &&
            (account?.type === InternalAccount.TypeEnum.MERCHANT || account?.type === InternalAccount.TypeEnum.PARTNER)
          ) {
            if (asb.support_email) {
              this.supportEmail = asb.support_email;
            }
          }
        });
      },
    });

    if (this.merchantAccountId$) {
      this.merchantAccountId$.subscribe({
        next: (accountId) => {
          this.accountId = accountId;
        },
      });
    }

    const signersList = this._tooltipListPipe.transform([
      ' The individual listed on the Application Signer step',
      ' Owners with at least 25% ownership',
    ]);
    this.signersTooltipText = 'Who is required to sign?\n' + signersList;
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onAcceptsCanadaDebit(checkbox: MatCheckboxChange): void {
    this.canadianVisaMcProcessing = checkbox.checked;
  }

  onAgreeTermsClicked(checkbox: MatCheckboxChange): void {
    this.termsAgreed = checkbox.checked;
  }

  onContinueClicked(event: string): void {
    if (this.submitApplicationForm.invalid || !this.canSubmitApplication) {
      return;
    }

    // default values for paysafe apps (data not required through UI)
    this._merchantAppService.hasTsysProvider$.subscribe((hasTsysProvider) => {
      if (!hasTsysProvider) {
        this.merchantApp.legal_entity.patriot_act_details = {} as PatriotActDetails;
        this.merchantApp.legal_entity.patriot_act_details.articles_of_incorporation = {
          state: PAArticlesOfIncorporation.StateEnum.AK,
          issued_at: moment('01-01-2000', 'MM-DD-YYYY').toISOString(),
          //document_id: 'not_required',
        };
      }
    });
    this._merchantAppService.updateProviderData();

    this.merchantApp.tos_acceptance = this.submitApplicationForm.value.acceptedTerms;
    this.merchantApp.canada_visa_mc_processing = this.submitApplicationForm.value.onAcceptsCanadaDebit;
    this._submittingApp$.next(true);

    this._merchantAppService.updateAndSubmitMerchantApplication(this.merchantApp, this.accountId);
  }

  goToStep(stepType: MerchantApplicationStepType): void {
    let newStep = this.allSteps.filter((step) => step.type === stepType)[0];
    this._merchantAppService.updateCurrentStep(newStep.order);
  }

  getTermsLinks(): void {
    this.merchantTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === CARD_TERMS_LINK_NAME,
    )?.link;
    this.portalTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === PORTAL_TERMS_LINK_NAME,
    )?.link;
    this.privacyPolicyLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === PRIVACY_POLICY_LINK_NAME,
    )?.link;
    this.achDebitTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === ACH_DEBIT_TERMS_LINK_NAME,
    )?.link;
  }

  private getUsersAndInvites(): void {
    const merchantAcctId = AuthService.getCurrentAccountId();
    const params = { tilledAccount: merchantAcctId };
    this._usersAppService.getAllUsers(params);
    if (this._authService.isScopeAble('user_invitations:read')) {
      this._usersAppService.getAllUserInvitations(params);
    }

    this.users$.subscribe({
      next: (usersData) => {
        this.users = usersData;
        this.invitations$.subscribe({
          next: (invitationsData) => {
            this.invitations = invitationsData;
            this.merchantUsersAndInvitations = [...(this.users || []), ...(this.invitations || [])];
            // check if applicant email exists in users or invitations
            this.merchantUsersAndInvitations.forEach((user) => {
              if (user.email === this.applicant?.email) {
                this.applicantExists = true;
              }
            });
            this.loading$.next(false);
          },
          error: (err) => {
            const message: TilledAlert = {
              message: 'Could not load user invitations',
              title: 'Server error',
              type: 'error',
            };
            this._alertService.showAlert(message);
            this.loading$.next(false);
            throw new Error('Error loading user invitations ' + JSON.stringify(err));
          },
        });
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not load users',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this.loading$.next(false);
        throw new Error('Error loading users ' + JSON.stringify(err));
      },
    });
  }

  getPrimaryApplicant(): void {
    this.applicant = this.merchantApp?.legal_entity?.principals?.find((rep) => rep.is_applicant);
    // check if primary applicant is the current user
    if (this.applicant?.email?.toLowerCase() === this._authService.user.email?.toLowerCase()) {
      this.isPrimaryApplicant = true;
    }

    if (!this.isPrimaryApplicant) {
      this.getUsersAndInvites();
    }
  }

  invitePrimaryApplicant(): void {
    this.inviteApplicant.emit({
      applicantInvite: true,
      emailExists: this.applicantExists,
      applicant: this.applicant,
    });
  }

  openDocuSignModal(): void {
    // TODO: open DocuSign modal logic
    console.log('openDocuSignModal');
  }

  private handleUpdatedApplication(): void {
    this.getTermsLinks();

    this.submitApplicationForm = this._formBuilder.group({
      onAcceptsCanadaDebit: new FormControl<boolean>(this.merchantApp?.canada_visa_mc_processing || false),
      acceptedTerms: new FormControl<boolean>(this.merchantApp?.tos_acceptance || false),
    });
    this.termsAgreed = this.merchantApp?.tos_acceptance;
    this.verifyYourBusinessComplete = this._merchantAppService.isVerifyYourBusinessStepComplete(this.merchantApp);
    this.businessTypeComplete = this._merchantAppService.isBusinessTypeSubStepComplete(this.merchantApp);
    this.businessDetailsComplete = this._merchantAppService.isBusinessDetailsSubStepComplete(this.merchantApp);
    this.businessContactComplete = this._merchantAppService.isBusinessContactInformationComplete(this.merchantApp);
    this.applicationSignersComplete = this._merchantAppService.isApplicationSignersComplete(this.merchantApp);
    this.businessOwnersComplete = this._merchantAppService.isBusinessOwnersComplete(this.merchantApp);
    this.productsAndServicesComplete = this._merchantAppService.isProductsAndServicesComplete(this.merchantApp);
    this.paymentAcceptanceComplete = this._merchantAppService.isPaymentAcceptanceComplete(this.merchantApp);
    this.processingVolumeComplete = this._merchantAppService.isPaymentProcessingVolumeComplete(this.merchantApp);
    this.bankAccountComplete = this._merchantAppService.isBankingInformationComplete(this.merchantApp);
    this.businessDocumentsComplete = this._merchantAppService.isBusinessDocumentsComplete(this.merchantApp);
    this.pricingAndTermsComplete = this._merchantAppService.isReviewPricingAndTermsComplete(this.merchantApp);
    this.isCanadian = this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.CAD;

    this.canSubmitApplication =
      this.businessTypeComplete.complete &&
      this.businessDetailsComplete.complete &&
      this.businessContactComplete.complete &&
      this.applicationSignersComplete.complete &&
      this.businessOwnersComplete.complete &&
      this.productsAndServicesComplete.complete &&
      this.paymentAcceptanceComplete.complete &&
      this.processingVolumeComplete.complete &&
      this.bankAccountComplete.complete &&
      this.businessDocumentsComplete.complete;

    let pricingCount = 0;
    const cardPricing = this.merchantApp.pricing_templates.find(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
    );
    if (cardPricing) {
      this.cardPricingTemplate = new CardPricingTemplateViewModel(cardPricing);
    }
    if (this.cardPricingTemplate) {
      pricingCount += 1;
      this._cardPricingTemplate$.next(this.cardPricingTemplate);
    }

    const cardPresentPricing = this.merchantApp.pricing_templates.find(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT,
    );
    if (cardPresentPricing) {
      this.cardPresentPricingTemplate = new CardPresentPricingTemplateViewModel(cardPresentPricing);
    }
    if (this.cardPresentPricingTemplate) {
      pricingCount += 1;
      this._cardPresentPricingTemplate$.next(this.cardPresentPricingTemplate);
    }

    const debitPricing = this.merchantApp.pricing_templates.find(
      (p) =>
        p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
        p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
    );
    if (debitPricing) {
      this.debitPricingTemplate = new DebitPricingTemplateViewModel(debitPricing);
    }
    if (this.debitPricingTemplate) {
      pricingCount += 1;
      this._debitPricingTemplate$.next(this.debitPricingTemplate);
    }

    this.hasMultiple = pricingCount > 1;

    this._changeDetectorRef.markForCheck();
  }

  /* eslint-disable quotes */
  public handleMiddeskSuggestions(): void {
    const business = this.middeskBusiness as MiddeskBusinessWithVerification;
    const suggestions = business?.suggestions as MiddeskSuggestions;

    const nameMismatchStatus = [LegalNameVerificationStatus.ALTERNATE_NAME, LegalNameVerificationStatus.SIMILAR_MATCH];

    const unknownEntityStructures = [
      OnboardingLegalEntity.StructureEnum.CHARITY,
      OnboardingLegalEntity.StructureEnum.COMMUNITY_INTEREST_COMPANY,
      OnboardingLegalEntity.StructureEnum.LIMITED,
      OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_PARTNERSHIP,
      OnboardingLegalEntity.StructureEnum.PUBLIC_LIMITED_COMPANY,
      OnboardingLegalEntity.StructureEnum.GOVERNMENT,
    ];

    const isUnknownEntityMatch =
      unknownEntityStructures.includes(this.merchantApp?.legal_entity?.structure) &&
      business?.entity_type === MiddeskBusiness.EntityTypeEnum.UNKNOWN;

    if (
      [TinVerificationStatus.MISMATCH, TinVerificationStatus.NOT_FOUND].includes(business?.verification?.tinStatus) &&
      !suggestions?.tin
    ) {
      const tinMismatchAlert: VerificationAlertConfig = {
        title: 'Review your legal business name and Tax ID',
        message:
          "The legal business name and Tax ID you entered cannot be verified with your state's Secretary of State.\n\n" +
          "For a smoother application process, the legal business name and Tax ID must exactly match your business' registration documents.",
        order: 1,
        type: 'tinNameReview',
        reviewText: 'Review',
        ignoreText: 'Ignore',
      };
      this.verificationAlerts.push(tinMismatchAlert);
    }

    if (
      nameMismatchStatus.includes(business?.verification?.legalNameStatus) &&
      this.merchantApp?.legal_entity?.legal_name !== business?.legal_name_of_business &&
      !suggestions?.name
    ) {
      const nameMismatchAlert: VerificationAlertConfig = {
        title: 'Confirm your legal business name',
        message:
          'Your legal business name must match the name on your state registration documents. Based on our check with the Secretary of State, your legal business name may be:' +
          `\n<span class="font-semibold font-openSans">${business?.legal_name_of_business}</span>\n\n` +
          'If this is correct, click "Update Legal Name" to update your legal business name. Otherwise, you can ignore this suggestion.',
        order: 0,
        type: 'updateName',
        updateText: 'Update Legal Name',
        ignoreText: 'Ignore',
      };

      this.verificationAlerts.push(nameMismatchAlert);
    }

    if (
      !(
        isUnknownEntityMatch ||
        (middeskEntityToTilledStructure(business?.entity_type) === this.merchantApp?.legal_entity?.structure &&
          business?.entity_type !== MiddeskBusiness.EntityTypeEnum.UNKNOWN)
      ) &&
      !suggestions?.entity_type
    ) {
      const entityMatchAlert: VerificationAlertConfig = {
        title: 'Confirm your business type',
        message:
          `Based on information from your state's Secretary of State, your business might be registered as a ` +
          `<span class="font-semibold font-openSans">${business?.entity_type
            ?.toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}</span>.\n\n` +
          'If this is correct, click "Update Business Type" to update your business type. Otherwise, you can ignore this suggestion.',
        order: 2,
        type: 'updateStructure',
        updateText: 'Update Business Type',
        ignoreText: 'Ignore',
      };
      this.verificationAlerts.push(entityMatchAlert);
    }

    this.verificationAlerts = this.verificationAlerts
      .filter((value, index, self) => index === self.findIndex((t) => t.type === value.type))
      .sort((a, b) => a.order - b.order);
  }

  public handleVerificationAction(alert: VerificationAlertConfig, update?: boolean): void {
    let review = false;
    let suggestions: MiddeskSuggestions = this.middeskBusiness?.suggestions;

    if (update) {
      let message: TilledAlert = {
        message: '',
        type: 'success',
        timer: 5000,
      };
      switch (alert.type) {
        case 'tinNameReview':
          review = true;
          suggestions.tin = SuggestionOptions.ACCEPTED;
          this.goToStep(this.businessDetailsSubStep);
          break;
        case 'updateName':
          this.merchantApp.legal_entity.legal_name = this.middeskBusiness?.legal_name_of_business;
          message.title = 'Legal business name updated';
          suggestions.name = SuggestionOptions.ACCEPTED;
          break;
        case 'updateStructure':
          this.merchantApp.legal_entity.structure = middeskEntityToTilledStructure(this.middeskBusiness?.entity_type);
          message.title = 'Business type updated';
          suggestions.entity_type = SuggestionOptions.ACCEPTED;
          break;
        default:
          break;
      }
      if (this.verificationAlerts?.length > 1 && !review) {
        this._merchantAppService.updateMerchantApplication(
          this.merchantApp,
          this.stepNumber,
          this.middeskBusiness?.account_id,
        );
        this._alertService.showAlert(message);
      }
    } else {
      switch (alert.type) {
        case 'tinNameReview':
          suggestions.tin = SuggestionOptions.IGNORED;
          break;
        case 'updateName':
          suggestions.name = SuggestionOptions.IGNORED;
          break;
        case 'updateStructure':
          suggestions.entity_type = SuggestionOptions.IGNORED;
          break;
        default:
          break;
      }
    }

    this._merchantAppService.updateMiddeskBusiness(this.middeskBusiness?.account_id, { suggestions });
    this.verificationAlerts = this.verificationAlerts.filter((alert) => alert.type !== alert.type);
    this.handleMiddeskSuggestions();
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
}
