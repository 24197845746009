/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CardCheckoutMethodBreakdown } from './cardCheckoutMethodBreakdown';
import { CharityDocumentData } from './charityDocumentData';
import { OnboardingAddress } from './onboardingAddress';
import { BLEBankAccount } from './bLEBankAccount';
import { ProcessingVolume } from './processingVolume';
import { PatriotActDetails } from './patriotActDetails';
import { PrincipalCreateParams } from './principalCreateParams';


export interface OnboardingLegalEntity { 
    /**
     * Registered legal address of the business.
     */
    address: OnboardingAddress;
    /**
     * Banking details for the business\'s payouts.
     */
    bank_account: BLEBankAccount;
    /**
     * The method by which the business accepts card payments. The total value of all the methods must equal 100.
     */
    card_checkout_method_breakdown: CardCheckoutMethodBreakdown;
    /**
     * The document data for the charity document. Required if the `mcc` is `8398`.
     */
    charity_document: CharityDocumentData;
    /**
     * The date when the business was incorporated in YYY-MM-DD format.
     */
    date_of_incorporation: string;
    /**
     * Required for TSYS merchants. The number of days billed prior to goods being shipped.
     */
    days_billed_prior_to_shipment?: number;
    /**
     * The merchant\'s trade name or Doing Business As Name (e.g. \"ACME\"), if different from the `legal_name`.
     */
    dba_name?: string;
    /**
     * Required if the merchant has processed credit cards before. If left blank, that indicates that the merchant did not process cards before.
     */
    existing_processor_name?: string;
    /**
     * Whether or not the business is a is a registered 501(c)(3) organization. If true, a copy of the 501(c)(3) letter must be uploaded. Only required if the `mcc` is `8398` or `8661`.
     */
    is_501c3: boolean;
    /**
     * Registered legal business name (e.g. \"ACME Corporation\").
     */
    legal_name: string;
    /**
     * The 4-digit merchant category code for the business. MCCs are used to classify businesses based on the goods or services they provide. For more information see the description [here](https://docs.tilled.com/api-reference#tag/onboarding/GET/v1/onboarding/mcc)
     */
    mcc: OnboardingLegalEntity.MccEnum;
    /**
     * Estimation of the number of terminal readers the business will need to order. Default is 1 for card-present merchants.
     */
    number_of_terminals?: number;
    /**
     * Information required by the Patriot Act. For TSYS applications, either the business license OR the articles of incorporation (AOI) details are required, not both.
     */
    patriot_act_details?: PatriotActDetails;
    /**
     * Percentage of business-to-business (B2B) that the business conducts
     */
    percent_business_to_business: number;
    /**
     * Array of Principals (ie Business Owners).
     */
    principals: Array<PrincipalCreateParams>;
    /**
     * An estimate of the processing volume of the business.
     */
    processing_volume: ProcessingVolume;
    /**
     * Description of the product sold by, or service provided by, the business.
     */
    product_description: string;
    /**
     * Region for the merchant account
     */
    region: OnboardingLegalEntity.RegionEnum;
    /**
     * Provides information about a payment that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that’s set on the account to form the complete statement descriptor. Maximum 20 characters for the concatenated descriptor.
     */
    statement_descriptor: string;
    /**
     * The category identifying the legal structure of the legal entity.
     */
    structure: OnboardingLegalEntity.StructureEnum;
    /**
     * Publicly available email address for sending support issues to.
     */
    support_email?: string;
    /**
     * Customer support phone number of the business. Cannot contain special characters.
     */
    support_phone: string;
    /**
     * The company\'s tax ID number (TIN)
     */
    tax_id_number: string;
    /**
     * The business\'s publicly available website.
     */
    website?: string;
}
export namespace OnboardingLegalEntity {
    export type MccEnum = '0742' | '0780' | '1520' | '1711' | '1731' | '1740' | '1750' | '1761' | '1771' | '1799' | '4119' | '4121' | '4214' | '4816' | '4900' | '5044' | '5399' | '5411' | '5499' | '5811' | '5812' | '5814' | '5965' | '5970' | '6513' | '7230' | '7299' | '7349' | '7372' | '7379' | '7392' | '7523' | '7542' | '7699' | '7922' | '7999' | '8011' | '8021' | '8031' | '8041' | '8042' | '8043' | '8049' | '8050' | '8099' | '8111' | '8211' | '8220' | '8249' | '8299' | '8351' | '8398' | '8661' | '8699' | '8931' | '8999';
    export const MccEnum = {
        _0742: '0742' as MccEnum,
        _0780: '0780' as MccEnum,
        _1520: '1520' as MccEnum,
        _1711: '1711' as MccEnum,
        _1731: '1731' as MccEnum,
        _1740: '1740' as MccEnum,
        _1750: '1750' as MccEnum,
        _1761: '1761' as MccEnum,
        _1771: '1771' as MccEnum,
        _1799: '1799' as MccEnum,
        _4119: '4119' as MccEnum,
        _4121: '4121' as MccEnum,
        _4214: '4214' as MccEnum,
        _4816: '4816' as MccEnum,
        _4900: '4900' as MccEnum,
        _5044: '5044' as MccEnum,
        _5399: '5399' as MccEnum,
        _5411: '5411' as MccEnum,
        _5499: '5499' as MccEnum,
        _5811: '5811' as MccEnum,
        _5812: '5812' as MccEnum,
        _5814: '5814' as MccEnum,
        _5965: '5965' as MccEnum,
        _5970: '5970' as MccEnum,
        _6513: '6513' as MccEnum,
        _7230: '7230' as MccEnum,
        _7299: '7299' as MccEnum,
        _7349: '7349' as MccEnum,
        _7372: '7372' as MccEnum,
        _7379: '7379' as MccEnum,
        _7392: '7392' as MccEnum,
        _7523: '7523' as MccEnum,
        _7542: '7542' as MccEnum,
        _7699: '7699' as MccEnum,
        _7922: '7922' as MccEnum,
        _7999: '7999' as MccEnum,
        _8011: '8011' as MccEnum,
        _8021: '8021' as MccEnum,
        _8031: '8031' as MccEnum,
        _8041: '8041' as MccEnum,
        _8042: '8042' as MccEnum,
        _8043: '8043' as MccEnum,
        _8049: '8049' as MccEnum,
        _8050: '8050' as MccEnum,
        _8099: '8099' as MccEnum,
        _8111: '8111' as MccEnum,
        _8211: '8211' as MccEnum,
        _8220: '8220' as MccEnum,
        _8249: '8249' as MccEnum,
        _8299: '8299' as MccEnum,
        _8351: '8351' as MccEnum,
        _8398: '8398' as MccEnum,
        _8661: '8661' as MccEnum,
        _8699: '8699' as MccEnum,
        _8931: '8931' as MccEnum,
        _8999: '8999' as MccEnum
    };
    export type RegionEnum = 'US' | 'CA';
    export const RegionEnum = {
        US: 'US' as RegionEnum,
        CA: 'CA' as RegionEnum
    };
    export type StructureEnum = 'charity' | 'community_interest_company' | 'corporation' | 'government' | 'limited' | 'limited_liability_company' | 'limited_liability_partnership' | 'non_profit' | 'partnership' | 'public_limited_company' | 'sole_proprietorship' | 'trust';
    export const StructureEnum = {
        CHARITY: 'charity' as StructureEnum,
        COMMUNITY_INTEREST_COMPANY: 'community_interest_company' as StructureEnum,
        CORPORATION: 'corporation' as StructureEnum,
        GOVERNMENT: 'government' as StructureEnum,
        LIMITED: 'limited' as StructureEnum,
        LIMITED_LIABILITY_COMPANY: 'limited_liability_company' as StructureEnum,
        LIMITED_LIABILITY_PARTNERSHIP: 'limited_liability_partnership' as StructureEnum,
        NON_PROFIT: 'non_profit' as StructureEnum,
        PARTNERSHIP: 'partnership' as StructureEnum,
        PUBLIC_LIMITED_COMPANY: 'public_limited_company' as StructureEnum,
        SOLE_PROPRIETORSHIP: 'sole_proprietorship' as StructureEnum,
        TRUST: 'trust' as StructureEnum
    };
}


