import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CapabilityStatusPipe } from 'app/core/pipes/capability-status.pipe';
import { PhoneNumberPipe } from 'app/core/pipes/phone-number.pipe';
import {
  AccountProcessingCapabilities,
  BusinessRepresentative,
  InternalAccount,
  InternalService,
  ProcessingCapability,
} from '../../../../projects/tilled-api-client/src';

import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { ComponentBase } from 'app/core/componentBase';
import { takeUntil } from 'rxjs';
import { FuseCardComponent } from '../../../@fuse/components/card/card.component';
import { TilledCardRowComponent } from '../tilled-card-row/tilled-card-row.component';
import { TilledHeadingH4Component } from '../tilled-text/tilled-heading/tilled-heading-h4.component';

@Component({
  selector: 'company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseCardComponent,
    TilledHeadingH4Component,
    MatDividerModule,
    TilledCardRowComponent,
    PhoneNumberPipe,
    CapabilityStatusPipe,
    CommonModule,
  ],
})
export class CompanyProfileComponent extends ComponentBase implements OnInit {
  @Input() account: InternalAccount;
  public accountType: InternalAccount.TypeEnum;
  public processingCapabilities: AccountProcessingCapabilities;
  public cardCapabilityProviders: ProcessingCapability.ProviderEnum[];
  public bankCapabilityProviders: ProcessingCapability.ProviderEnum[];
  public cardPresentCapabilityProviders: ProcessingCapability.ProviderEnum[];
  public isW9Uploaded: boolean = false;
  public isVoidedCheckOrBLUploaded: boolean = false;

  constructor(private _internalService: InternalService) {
    super();
  }

  ngOnInit(): void {
    this.accountType = this.account?.type;
    if (this.accountType === InternalAccount.TypeEnum.PARTNER) {
      this.getProcessingCapabilities(this.account);
    }
  }

  getRepName(businessRep: BusinessRepresentative): string {
    let nameString = '';
    nameString = businessRep.first_name + ' ';
    if (businessRep.middle_name) {
      nameString += businessRep.middle_name + ' ';
    }
    nameString += businessRep.last_name;

    return nameString;
  }

  getProcessingCapabilities(account: InternalAccount): void {
    this._internalService
      .internalGetAccountProcessingCapabilities({
        tilledAccount: this.account.id,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        this.processingCapabilities = result;
        this.cardCapabilityProviders = this.processingCapabilities?.card?.map((c) => c?.provider);
        this.bankCapabilityProviders = this.processingCapabilities?.debit?.map((c) => c?.provider);
        this.cardPresentCapabilityProviders = this.processingCapabilities?.card_present?.map((c) => c?.provider);
      });
  }
}
