import { DocumentDto, MiddeskBusiness, OnboardingLegalEntity, ProcessingCapability } from '@tilled-api-client';

export function mapSubtypeToTitle(
  subtype: DocumentDto.SubtypeEnum,
  requestNotes?: string,
  includeRequestNotes: boolean = false,
): string {
  let noteAddOn = '';
  if (requestNotes && includeRequestNotes) {
    noteAddOn = ' - ' + requestNotes;
  }
  switch (subtype) {
    //file
    case DocumentDto.SubtypeEnum.BANK_LETTER_VOIDED_CHECK:
      return 'Bank Letter or Voided Check' + noteAddOn;
    case DocumentDto.SubtypeEnum.EIN_LETTER:
      return 'EIN Letter' + noteAddOn;
    case DocumentDto.SubtypeEnum.ARTICLES_OF_INCORP:
      return 'Articles of Incorporation' + noteAddOn;
    case DocumentDto.SubtypeEnum.BUSINESS_LICENSE:
      return 'Business License' + noteAddOn;
    case DocumentDto.SubtypeEnum.UTILITY_BILL:
      return 'Utility Bill' + noteAddOn;
    case DocumentDto.SubtypeEnum.PROCESSING_STATEMENT:
      return 'Processing Statement (past 3 - 6 months)' + noteAddOn;
    case DocumentDto.SubtypeEnum.FINANCIAL_STATEMENT:
      return 'Financial Statement (past 1 - 2 years, include P&L Statement and Balance Sheet)' + noteAddOn;
    case DocumentDto.SubtypeEnum.BANK_STATEMENT:
      return 'Bank Statements (past 3 - 6 months)';
    case DocumentDto.SubtypeEnum._501C3:
      return '501c3' + noteAddOn;
    case DocumentDto.SubtypeEnum.TAX_RETURN:
      return 'Tax Return' + noteAddOn;
    case DocumentDto.SubtypeEnum.INVOICE:
      return 'Invoice' + noteAddOn;
    case DocumentDto.SubtypeEnum.DL_PASSPORT:
      return 'Driver’s License/Passport' + noteAddOn;
    case DocumentDto.SubtypeEnum.DL_BACK:
      return 'Driver’s License - Back side' + noteAddOn;
    //written
    case DocumentDto.SubtypeEnum.BUSINESS_MODEL:
      return 'Business model details' + noteAddOn;
    case DocumentDto.SubtypeEnum.PRICING_MODEL:
      return 'Pricing model details' + noteAddOn;
    case DocumentDto.SubtypeEnum.WEBSITE:
      return 'Website' + noteAddOn;
    case DocumentDto.SubtypeEnum.SHOPPING_CART:
      return 'No shopping cart' + noteAddOn;
    case DocumentDto.SubtypeEnum.OTHER:
      //always return requestNotes on 'Other' if present
      if (requestNotes) {
        return 'Other - ' + requestNotes;
      }
      return 'Other';
    default:
      return '';
  }
}

export function middeskEntityToTilledStructure(
  entity: MiddeskBusiness.EntityTypeEnum,
): OnboardingLegalEntity.StructureEnum {
  switch (entity) {
    case MiddeskBusiness.EntityTypeEnum.CORPORATION:
      return OnboardingLegalEntity.StructureEnum.CORPORATION;
    case MiddeskBusiness.EntityTypeEnum.LLC:
      return OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_COMPANY;
    case MiddeskBusiness.EntityTypeEnum.PARTNERSHIP:
      return OnboardingLegalEntity.StructureEnum.PARTNERSHIP;
    case MiddeskBusiness.EntityTypeEnum.SOLE_PROPRIETORSHIP:
      return OnboardingLegalEntity.StructureEnum.SOLE_PROPRIETORSHIP;
    case MiddeskBusiness.EntityTypeEnum.NON_PROFIT:
      return OnboardingLegalEntity.StructureEnum.NON_PROFIT;
    case MiddeskBusiness.EntityTypeEnum.TRUST:
      return OnboardingLegalEntity.StructureEnum.TRUST;
    default:
      return;
  }
}

export const checkSsnRequired = (processor: ProcessingCapability.ProviderEnum, structure): boolean => {
  switch (processor) {
    case ProcessingCapability.ProviderEnum.TSYS ||
      ProcessingCapability.ProviderEnum.VALOR ||
      ProcessingCapability.ProviderEnum.MULTI_PASS:
      return structure !== OnboardingLegalEntity.StructureEnum.GOVERNMENT;
    case ProcessingCapability.ProviderEnum.PAYSAFE || ProcessingCapability.ProviderEnum.HANDPOINT:
      return (
        structure !== OnboardingLegalEntity.StructureEnum.GOVERNMENT &&
        structure !== OnboardingLegalEntity.StructureEnum.NON_PROFIT &&
        structure !== OnboardingLegalEntity.StructureEnum.CHARITY
      );
    default:
      // this will apply to all other processors, including North
      return true; // default to true
  }
};
