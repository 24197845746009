import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  MerchantAppMissingFields,
  MerchantApplicationStepType,
  NorthApplicationSteps,
  PaysafeApplicationSteps,
  TsysApplicationSteps,
} from 'app/core/data/onboarding-types';
import { AccountAppService } from 'app/core/services/account.app.service';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { FilesAppService } from 'app/core/services/files.app.service';
import { MiddeskAppService } from 'app/core/services/middesk.app.service';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  combineLatest,
  filter,
  map,
  takeUntil,
  tap,
} from 'rxjs';
import {
  AccountInternalMetadata,
  BankVerification,
  CreateMiddeskBusinessRequestParams,
  DocumentDto,
  GetOnboardingApplicationRequestParams,
  InternalAccount,
  InternalAccountCapability,
  InternalPartnerOnboardingDefaultSettings,
  InternalService,
  InternalUpdatePartnerOnboardingDefaultSettingsParams,
  MiddeskBusiness,
  MiddeskBusinessAddressCreateParams,
  MiddeskBusinessUpdateParams,
  ModelFile,
  OnboardingApplication,
  OnboardingApplicationCreateParams,
  OnboardingLegalEntity,
  OnboardingService,
  PricingTemplate,
  ProcessingVolume,
  RegenerateSigningLinksParams,
  SigningLink,
  SubmitApplicationParams,
  SubmitApplicationResponse,
  UpdateMiddeskBusinessRequestParams,
  UpdateOnboardingApplicationRequestParams,
} from '../../../../projects/tilled-api-client/src';
import {
  GetPlaidLinkTokenRequestParams,
  PlaidService,
  PlaidUpdateMerchantApplicationRequestParams,
} from '../../../../projects/tilled-api-client/src/api/plaid.service';
import { ApplicationStep, IsStepComplete } from '../../core/models/application-step';
import { AuthService } from '../../core/services/auth.service';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
import { PlaidConfig, PlaidEventsConfig, PlaidLinkAppService } from './plaid-link.app.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantAppService implements OnDestroy {
  private stepsSubject = new BehaviorSubject<ApplicationStep[]>(null);
  private currentStepSubject = new BehaviorSubject<ApplicationStep>(null);
  private applicationResponse = new BehaviorSubject<OnboardingApplication>(null);
  private submittedApplicationResponse = new Subject<SubmitApplicationResponse>();
  public merchantAppSteps$: Observable<ApplicationStep[]> = this.stepsSubject.asObservable();
  public merchantApplicationResponse$: Observable<OnboardingApplication> = this.applicationResponse.asObservable();
  //.pipe(skipWhile((value, index) => !value));
  public currentStep$: Observable<ApplicationStep> = this.currentStepSubject.asObservable();
  public submittedApplicationResponse$: Observable<SubmitApplicationResponse> =
    this.submittedApplicationResponse.asObservable();

  private regenerateSigningLinksResponse = new Subject<SigningLink[]>();
  public regenerateSigningLinksResponse$: Observable<SigningLink[]> =
    this.regenerateSigningLinksResponse.asObservable();

  private submittedApplicationErrors = new BehaviorSubject<any>(null);
  public submittedApplicationErrors$: Observable<any> = this.submittedApplicationErrors.asObservable();

  private _applicationUpdatedAt$ = new BehaviorSubject<string>(null);
  public applicationUpdatedAt$: Observable<any> = this._applicationUpdatedAt$.asObservable();
  private _applicationSuccessfullyUpdatedByPlaid = new Subject<boolean>();
  public applicationSuccessfullyUpdatedByPlaid$: Observable<boolean> =
    this._applicationSuccessfullyUpdatedByPlaid.asObservable();

  private hasBankVerificationEnabled: boolean = false;
  private bankVerificationMethods: BankVerification.VerificationMethodEnum[] = [];
  private areBankDocsRequired: boolean = false;
  private requiredBankDocs: string[] = ['Voided Check', 'Bank Letter'];
  private currentBankDocs: string[] = [];
  private onboardingDocs: DocumentDto[] = [];

  private unsubscribe$ = new Subject<void>();

  private _hasTsysProvider$ = new BehaviorSubject<boolean>(false);
  public hasTsysProvider$: Observable<boolean> = this._hasTsysProvider$.asObservable();
  private _hasNorthProvider$ = new BehaviorSubject<boolean>(false);
  public hasNorthProvider$: Observable<boolean> = this._hasNorthProvider$.asObservable();
  private _hasPaysafeProvider$ = new BehaviorSubject<boolean>(false);
  public hasPaysafeProvider$: Observable<boolean> = this._hasPaysafeProvider$.asObservable();
  private _multipleOwners$ = new BehaviorSubject<boolean>(false);
  public multipleOwners$: Observable<boolean> = this._multipleOwners$.asObservable();
  private _partnerOnboardingDefaults$ = new BehaviorSubject<InternalPartnerOnboardingDefaultSettings>(null);
  public partnerOnboardingDefaults$: Observable<InternalPartnerOnboardingDefaultSettings> =
    this._partnerOnboardingDefaults$.asObservable();

  private _middeskBusiness$ = new BehaviorSubject<MiddeskBusiness>(null);
  public middeskBusiness$: Observable<MiddeskBusiness> = this._middeskBusiness$.asObservable();

  private _drawerOpen = new BehaviorSubject<boolean>(true);
  public drawerOpen$: Observable<boolean> = this._drawerOpen.asObservable();

  constructor(
    private _merchantApplicationService: OnboardingService,
    private _internalService: InternalService,
    private _plaidService: PlaidService,
    private _alertService: AlertService,
    private _plaidLinkAppService: PlaidLinkAppService,
    private _filesAppService: FilesAppService,
    private _documentsAppService: DocumentsAppService,
    private _accountService: AccountAppService,
    private _authService: AuthService,
    private _router: Router,
    private _middeskAppService: MiddeskAppService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public updateCurrentStep(step: number): void {
    const allSteps = this.stepsSubject.getValue();

    let currentStep = allSteps.filter((s) => s.order === step)[0];
    if (currentStep.subSteps?.length > 0) {
      currentStep = allSteps.filter((step) => step.order === currentStep.subSteps[0])[0];
    }
    if (this._router.url.includes('/onboarding/application')) {
      this._router.navigate(['/onboarding/application'], { queryParams: { step: currentStep.type } });
    }
    this.currentStepSubject.next(currentStep);
  }

  public getLinkToken(eventListeners: PlaidEventsConfig, redirectUri: string): void {
    const params: GetPlaidLinkTokenRequestParams = {
      tilledAccount: AuthService.getCurrentAccountId(),
      redirect: redirectUri,
    };
    this._plaidService.getPlaidLinkToken(params).subscribe({
      next: (response) => {
        const linkScript: any = document.createElement('script');
        linkScript.type = 'text/javascript';
        linkScript.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
        linkScript.onerror = (e: any) => console.log(e);

        const plaidConfig: PlaidConfig = {
          token: response.token,
          ...eventListeners,
        };
        this._plaidLinkAppService.createPlaid(plaidConfig).then((linkHandler) => {
          linkHandler?.open();
        });
        return;
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not load Plaid interface.',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this._applicationSuccessfullyUpdatedByPlaid.next(false);
      },
    });
  }

  public async updatePlaidAccessToken(token: string, accountId: string): Promise<void> {
    const params: PlaidUpdateMerchantApplicationRequestParams = {
      token: token,
      tilledAccount: accountId ?? AuthService.getCurrentAccountId(),
    };
    this._plaidService.plaidUpdateMerchantApplication(params).subscribe({
      next: (response) => {
        this.applicationResponse.next(response);
        this._applicationSuccessfullyUpdatedByPlaid.next(true);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not update application.',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this._applicationSuccessfullyUpdatedByPlaid.next(false);
      },
    });
  }

  public getApplication(
    internalMetadata: AccountInternalMetadata,
    accountId?: string,
  ): Observable<OnboardingApplication> {
    const accountIdToUse = accountId ?? AuthService.getCurrentAccountId();
    const params: GetOnboardingApplicationRequestParams = {
      tilledAccount: accountIdToUse,
    };

    this.getUploadedBankDocs(accountIdToUse);
    this.getOnboardingDocs(accountIdToUse);

    return this._merchantApplicationService.getOnboardingApplication(params).pipe(
      tap((application) => {
        if (!application.legal_entity) {
          application.legal_entity = {} as OnboardingLegalEntity;
        }
        this.updateProviderData();
        this.getMiddeskBusiness(accountIdToUse);
        this.loadSteps(application);
        this.updateBankDocsRequired(application, internalMetadata);
        this.applicationResponse.next(application);
        this._applicationUpdatedAt$.next(application.updated_at);
      }),
      catchError((err) => {
        throw JSON.stringify(err);
      }),
    );
  }

  public updateMerchantApplication(updatedApp: OnboardingApplication, nextStep: number, accountId?: string): void {
    const params: UpdateOnboardingApplicationRequestParams = {
      tilledAccount: accountId ?? AuthService.getCurrentAccountId(),
      onboardingApplicationCreateParams: this.mapResponseToParams(updatedApp),
    };
    this.checkAndUpdateFromPricingTemplate(updatedApp);
    this.updateProviderData();
    this.getMiddeskBusiness(accountId ?? AuthService.getCurrentAccountId());
    this._merchantApplicationService
      .updateOnboardingApplication(params)
      .pipe(
        tap((application) => {
          this.loadSteps(application);
          this.currentStepSubject.next(this.stepsSubject.getValue().filter((s) => s.order === nextStep)[0]);

          if (this.currentStepSubject.getValue().subSteps?.length > 0) {
            this.currentStepSubject.next(
              this.stepsSubject.getValue().filter((s) => s.order === this.currentStepSubject.getValue().subSteps[0])[0],
            );
          }
          if (this._router.url.includes('/onboarding/application')) {
            this._router.navigate(['/onboarding/application'], {
              queryParams: { step: this.currentStepSubject.getValue().type },
            });
          }

          this._applicationUpdatedAt$.next(application.updated_at);
        }),
        catchError((err) => {
          if (err.error) {
            if (err.error.statusCode === 400) {
              const message: TilledAlert = {
                message: err.error.message,
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            } else {
              // generic catch all for error responses
              const message: TilledAlert = {
                message: 'Could not update application, please try again or contact support for assistance',
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            }
          }
          throw new Error('Error loading application: ' + JSON.stringify(err));
        }),
      )
      .subscribe((response) => {
        if (
          response.validation_errors?.find((error) => error.includes('each principal must have a unique id_number')) &&
          this.currentStepSubject.getValue().type === MerchantApplicationStepType.PRODUCTS_AND_SERVICES_SUB_STEP
        ) {
          const message: TilledAlert = {
            message:
              'The same SSN is associated with multiple application signers. Please ensure that each SSN is unique.',
            title: 'Representatives',
            type: 'error',
          };
          this._alertService.showAlert(message);
          this.currentStepSubject.next(
            this.stepsSubject
              .getValue()
              .filter((s) => s.type === MerchantApplicationStepType.APPLICATION_SIGNER_SUB_STEP)[0],
          );
        }
        this.applicationResponse.next(response);
      });
  }

  public updateAndSubmitMerchantApplication(updatedApp: OnboardingApplication, accountId?: string): void {
    const params: UpdateOnboardingApplicationRequestParams = {
      tilledAccount: accountId ?? AuthService.getCurrentAccountId(),
      onboardingApplicationCreateParams: this.mapResponseToParams(updatedApp),
    };
    this._merchantApplicationService.updateOnboardingApplication(params).subscribe({
      next: (response) => {
        this.applicationResponse.next(response);
        this.submitMerchantApplication(accountId);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not update application, please try again or contact support for assistance',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  public isVerifyYourBusinessStepComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };
    const businessType = this.isBusinessTypeSubStepComplete(application);
    const businessDetails = this.isBusinessDetailsSubStepComplete(application);
    const businessContactInformationComplete = this.isBusinessContactInformationComplete(application);
    const businessRepComplete = this.isApplicationSignersComplete(application);
    const businessOwnersComplete = this.isBusinessOwnersComplete(application);
    const productsAndServicesComplete = this.isProductsAndServicesComplete(application);
    const paymentAcceptanceComplete = this.isPaymentAcceptanceComplete(application);
    const paymentProcessingVolumeComplete = this.isPaymentProcessingVolumeComplete(application);

    if (!businessType.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = businessType.missingFields;
    }
    if (!businessDetails.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = businessDetails.missingFields;
    }
    if (!businessContactInformationComplete.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = businessContactInformationComplete.missingFields;
    }
    if (!businessRepComplete.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = businessRepComplete.missingFields;
    }
    if (!businessOwnersComplete.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = businessOwnersComplete.missingFields;
    }
    if (!productsAndServicesComplete.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = productsAndServicesComplete.missingFields;
    }
    if (!paymentAcceptanceComplete.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = paymentAcceptanceComplete.missingFields;
    }
    if (!paymentProcessingVolumeComplete.complete) {
      stepComplete.complete = false;
      stepComplete.missingFields = paymentProcessingVolumeComplete.missingFields;
    }
    return stepComplete;
  }

  public isBusinessTypeSubStepComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };
    if (!application.legal_entity.structure) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessTypeSubStep.BUSINESS_TYPE);
    }
    return stepComplete;
  }

  public isBusinessDetailsSubStepComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!application.legal_entity.legal_name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsSubStep.LEGAL_NAME);
    }
    if (!application.legal_entity.tax_id_number) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsSubStep.BUSINESS_ID);
    }
    if (!application.legal_entity.date_of_incorporation) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsSubStep.DATE_OF_INC);
    }
    if (!application.legal_entity.address?.street) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsSubStep.STREET);
    }
    if (!application.legal_entity.address?.city) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsSubStep.CITY);
    }
    if (!application.legal_entity.address?.state) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsSubStep.STATE);
    }
    if (!application.legal_entity.address?.postal_code) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsSubStep.POSTAL);
    }
    // Will be moved to another step, keeping for now.
    // if (
    //   !(
    //     application.legal_entity.percent_business_to_business >= 0 &&
    //     application.legal_entity.percent_business_to_business <= 100
    //   )
    // ) {
    //   stepComplete.complete = false;
    //   stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDetailsStep.PERCENT_B2B);
    // }
    return stepComplete;
  }

  public isBusinessContactInformationComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!application.legal_entity.support_phone) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoSubStep.PHONE);
    }
    if (!application.legal_entity.support_email) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoSubStep.EMAIL);
    }
    // TBD
    /*if (!application.legal_entity.website) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ContactInfoSubStep.WEBSITE);
    }*/
    return stepComplete;
  }

  public isProductsAndServicesComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };
    if (!application.legal_entity?.mcc) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ProductsAndServicesSubStep.INDUSTRY);
    }
    if (!application.legal_entity?.product_description) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ProductsAndServicesSubStep.PRODUCT_DESCRIPTION);
    }
    if (!(application.legal_entity?.days_billed_prior_to_shipment >= 0)) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ProductsAndServicesSubStep.FULFILLMENT_TIMEFRAME);
    }
    if (!application.legal_entity?.percent_business_to_business) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ProductsAndServicesSubStep.PERCENT_B2B);
    }
    return stepComplete;
  }

  public isPaymentAcceptanceComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    const cardPresentPricing = application?.pricing_templates
      ? application.pricing_templates.find(
          (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT,
        )
      : null;

    if (!application.legal_entity?.existing_processor_name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.PaymentAcceptanceSubStep.PREVIOUS_PROCESSOR);
    }

    if (!application.legal_entity?.statement_descriptor) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.PaymentAcceptanceSubStep.STATEMENT_DESCRIPTOR);
    }

    if (
      application.legal_entity?.card_checkout_method_breakdown?.percent_e_commerce +
        application.legal_entity?.card_checkout_method_breakdown?.percent_manual_card_not_present +
        application.legal_entity?.card_checkout_method_breakdown?.percent_swiped !==
      100
    ) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.PaymentAcceptanceSubStep.TRX_PERCENTS);
    }

    if (cardPresentPricing && !(application.legal_entity?.number_of_terminals >= 0)) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.PaymentAcceptanceSubStep.NUMBER_OF_TERMINALS);
    }

    return stepComplete;
  }

  public isPaymentProcessingVolumeComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };
    if (!application.legal_entity?.processing_volume?.monthly_transaction_count) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesSubStep.AVERAGE_TRX_PER_MONTH);
    }
    if (!application.legal_entity?.processing_volume?.monthly_processing_volume) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesSubStep.MONTHLY_VOLUME);
    }
    if (!application.legal_entity?.processing_volume?.high_ticket_amount) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesSubStep.HIGH_TICKET_AMOUNT);
    }

    const cardPricing = application?.pricing_templates
      ? application.pricing_templates.find((p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD)
      : null;
    const debitPricing = application?.pricing_templates
      ? application.pricing_templates.find(
          (p) =>
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
            p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
        )
      : null;
    if (!application.legal_entity?.processing_volume?.average_transaction_amount_card && cardPricing) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesSubStep.AVERAGE_AMOUNT_CARD);
    }
    if (!application.legal_entity?.processing_volume?.average_transaction_amount_debit && debitPricing) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.VolumesSubStep.AVERAGE_AMOUNT_DEBIT);
    }
    return stepComplete;
  }

  public isBusinessOwnersComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!this._multipleOwners$.getValue()) {
      return stepComplete;
    }

    if (!application.legal_entity?.principals?.length) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessOwnersSubStep.NO_OWNERS);
      return stepComplete;
    }

    let totalOwnership = 0;
    for (const principal of application.legal_entity?.principals) {
      totalOwnership += principal.percent_ownership;
    }

    if (totalOwnership > 0 && !application.legal_entity?.principals?.some((p) => p.percent_ownership == null)) {
      stepComplete.complete = true;
      return stepComplete;
    }

    if (!(totalOwnership > 0)) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(
        application.legal_entity?.principals?.some((p) => !p.is_applicant)
          ? MerchantAppMissingFields.BusinessOwnersSubStep.OWNERSHIP_TOTAL
          : MerchantAppMissingFields.BusinessOwnersSubStep.NO_OWNERS,
      );
      return stepComplete;
    }
    let ssnRequired = true;
    if (
      this._hasTsysProvider$.getValue() &&
      application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.GOVERNMENT
    ) {
      ssnRequired = false;
    } else if (
      this._hasPaysafeProvider$.getValue() &&
      (application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.GOVERNMENT ||
        application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.NON_PROFIT ||
        application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.CHARITY)
    ) {
      ssnRequired = false;
    }

    const businessOwners = application.legal_entity?.principals?.filter((p) => !p.is_applicant);

    for (const [index, principal] of businessOwners.entries()) {
      let ownerIndex = index + 1;
      if (principal.is_applicant && principal.percent_ownership <= 0) {
        continue;
      }
      if (
        !(
          application.pricing_templates[0]?.currency === ProcessingVolume.CurrencyEnum.CAD ||
          !ssnRequired ||
          principal.id_number
        )
      ) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.SSN,
        );
      }
      if (!principal.first_name) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.FIRST_NAME,
        );
      }
      if (!principal.last_name) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.LAST_NAME,
        );
      }
      if (!principal.phone) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.PHONE,
        );
      }
      if (!principal.date_of_birth) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.DOB,
        );
      }
      if (!principal.job_title) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.JOB_TITLE,
        );
      }
      if (principal.percent_ownership == null) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.OWNERSHIP,
        );
      }
      if (!principal.address?.street) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.STREET,
        );
      }
      if (!principal.address?.city) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.CITY,
        );
      }
      if (!principal.address?.state) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.STATE,
        );
      }
      if (!principal.address?.postal_code) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(
          'Business Owner ' + `(${ownerIndex}) — ` + MerchantAppMissingFields.BusinessOwnersSubStep.POSTAL,
        );
      }
    }

    return stepComplete;
  }

  public isApplicationSignersComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!application.legal_entity?.principals) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.NO_APPLICATION_SIGNER);
      return stepComplete;
    }

    const applicantAndControlProng = application.legal_entity?.principals?.filter(
      (p) => p.is_applicant && p.is_control_prong,
    );
    if (applicantAndControlProng.length !== 1) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.NO_APPLICATION_SIGNER);
    }

    let ssnRequired = true;
    if (
      this._hasTsysProvider$.getValue() &&
      application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.GOVERNMENT
    ) {
      ssnRequired = false;
    } else if (
      this._hasPaysafeProvider$.getValue() &&
      (application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.GOVERNMENT ||
        application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.NON_PROFIT ||
        application.legal_entity?.structure === OnboardingLegalEntity.StructureEnum.CHARITY)
    ) {
      ssnRequired = false;
    }

    const representative = application.legal_entity?.principals?.find((p) => p.is_control_prong && p.is_applicant);
    if (representative) {
      if (
        !(
          application.pricing_templates[0]?.currency === ProcessingVolume.CurrencyEnum.CAD ||
          !ssnRequired ||
          representative.id_number
        )
      ) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.SSN);
      }
      if (!representative.first_name) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.FIRST_NAME);
      }
      if (!representative.last_name) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.LAST_NAME);
      }
      if (!representative.phone) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.PHONE);
      }
      if (!representative.date_of_birth) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.DOB);
      }
      if (!representative.job_title) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.JOB_TITLE);
      }
      if (representative.percent_ownership == null) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.OWNERSHIP);
      }
      if (!representative.address?.street) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.STREET);
      }
      if (!representative.address?.city) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.CITY);
      }
      if (!representative.address?.state) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.STATE);
      }
      if (!representative.address?.postal_code) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.POSTAL);
      }
      if (!representative.email) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.EMAIL);
      }

      const needPreviousAddress =
        application.pricing_templates[0]?.currency === ProcessingVolume.CurrencyEnum.CAD &&
        representative.years_at_address <= 3;
      if (needPreviousAddress && !representative.previous_address?.street) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.PREV_STREET);
      }
      if (needPreviousAddress && !representative.previous_address?.city) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.PREV_CITY);
      }
      if (needPreviousAddress && !representative.previous_address?.state) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.PREV_STATE);
      }
      if (needPreviousAddress && !representative.previous_address?.postal_code) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.ApplicationSignersSubStep.PREV_POSTAL);
      }
    }

    return stepComplete;
  }

  public isBankingInformationComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    this.updateBankDocsRequired(application);
    let docRequirementsMet = !this.areBankDocsRequired;
    if (!docRequirementsMet) {
      for (const currentBankDoc of this.currentBankDocs) {
        if (this.requiredBankDocs.includes(currentBankDoc)) {
          docRequirementsMet = true;
          break;
        }
      }
    }

    if (!application.legal_entity?.bank_account?.account_number) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ACCOUNT_NUMBER);
    }
    if (!application.legal_entity?.bank_account?.routing_number) {
      stepComplete.complete = false;
      if (application.pricing_templates[0]?.currency === ProcessingVolume.CurrencyEnum.CAD) {
        stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ROUTING_NUMBER_CA);
      } else {
        stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ROUTING_NUMBER);
      }
    }
    if (!application.legal_entity?.bank_account?.type) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.TYPE);
    }
    if (!application.legal_entity?.bank_account?.account_holder_name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.ACCOUNT_HOLDER_NAME);
    }
    if (!application.legal_entity?.bank_account?.bank_name) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.BANK_NAME);
    }
    if (!docRequirementsMet) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BankAccountStep.DOC_UPLOAD);
    }

    return stepComplete;
  }

  public isReviewPricingAndTermsComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: application.tos_acceptance,
      missingFields: [],
    };
    return stepComplete;
  }

  public isBusinessDocumentsComplete(application: OnboardingApplication): IsStepComplete {
    const stepComplete: IsStepComplete = {
      complete: true,
      missingFields: [],
    };

    if (!this._hasTsysProvider$.getValue()) {
      return stepComplete;
    }

    if (!application.legal_entity.patriot_act_details) {
      stepComplete.complete = false;
      stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BUSINESS_DOCS);
    } else {
      if (
        !application.legal_entity.patriot_act_details.articles_of_incorporation &&
        !application.legal_entity.patriot_act_details.business_license
      ) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BUSINESS_DOCS);
      } else if (application.legal_entity.patriot_act_details.articles_of_incorporation) {
        /*if (!application.legal_entity.patriot_act_details.articles_of_incorporation.document_id) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.AOI_DOCUMENT);
        }*/
        if (!application.legal_entity.patriot_act_details.articles_of_incorporation.issued_at) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.AOI_ISSUED_AT);
        }
        if (!application.legal_entity.patriot_act_details.articles_of_incorporation.state) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.AOI_STATE);
        }
      } else if (application.legal_entity.patriot_act_details.business_license) {
        /*if (!application.legal_entity.patriot_act_details.business_license.document_id) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BL_DOCUMENT);
        }*/
        if (!application.legal_entity.patriot_act_details.business_license.expires_at) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BL_EXPIRES_AT);
        }
        if (!application.legal_entity.patriot_act_details.business_license.issued_at) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BL_ISSUED_AT);
        }
        if (!application.legal_entity.patriot_act_details.business_license.state) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BL_STATE);
        }
        if (!application.legal_entity.patriot_act_details.business_license.name) {
          stepComplete.complete = false;
          stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BL_NAME);
        }
      }
    }
    const validDocStatuses = [DocumentDto.StatusEnum.SUBMITTED, DocumentDto.StatusEnum.VERIFIED];
    // Only required for 501c3 merchants
    if (application.legal_entity.is_501c3) {
      if (
        !application.legal_entity.charity_document.document_id ||
        !validDocStatuses.includes(application.legal_entity.charity_document.status)
      ) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.DOCUMENT_501C3);
      }
    }

    if (this.onboardingDocs?.length) {
      const bankStatement = this.onboardingDocs.find((doc) => doc.subtype === DocumentDto.SubtypeEnum.BANK_STATEMENT);
      const processingStatement = this.onboardingDocs.find(
        (doc) => doc.subtype === DocumentDto.SubtypeEnum.PROCESSING_STATEMENT,
      );
      if (bankStatement && !validDocStatuses.includes(bankStatement.status)) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.BANK_STATEMENT);
      }
      if (processingStatement && !validDocStatuses.includes(processingStatement.status)) {
        stepComplete.complete = false;
        stepComplete.missingFields.push(MerchantAppMissingFields.BusinessDocumentsStep.PROCESSING_STATEMENT);
      }
    }

    return stepComplete;
  }

  public updateBankDocsRequiredFromComponent(required: boolean, current?: string[]): void {
    this.areBankDocsRequired = required;
    if (current && current.length > 0) {
      this.currentBankDocs = current;
    }
  }

  private getUploadedBankDocs(accountId: string): void {
    this._filesAppService.filesAll$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (files) => {
        if (files) {
          this.currentBankDocs = [];
          for (const file of files) {
            this.currentBankDocs.push(file.title?.split(':')[0]);
          }
        }
        return;
      },
      error: (err) => {
        if (this.areBankDocsRequired) {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load Files.',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        }
      },
    });
    this._filesAppService.listAllFiles(accountId, [ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION]);
  }

  private getOnboardingDocs(accountId: string): void {
    this._documentsAppService.documentsAll$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (documents) => {
        if (documents) {
          this.onboardingDocs = documents.filter(
            (doc) => doc.status !== DocumentDto.StatusEnum.REJECTED && doc.subtype !== DocumentDto.SubtypeEnum._501C3,
          );
        }
        return;
      },
    });
    this._documentsAppService.getAllDocuments(accountId);
  }

  private submitMerchantApplication(accountId?: string, submitApplicationParams?: SubmitApplicationParams): void {
    this._merchantApplicationService
      .submitOnboardingApplication({
        tilledAccount: accountId ?? AuthService.getCurrentAccountId(),
        submitApplicationParams: submitApplicationParams ? submitApplicationParams : {},
      })
      .subscribe({
        next: (response) => {
          this.submittedApplicationResponse.next(response);
        },
        error: (err) => {
          this.submittedApplicationErrors.next(err);
        },
      });
  }

  public regenerateSigningLinks(accountId?: string, regenerateSigningLinksParams?: RegenerateSigningLinksParams): void {
    this._merchantApplicationService
      .regenerateSigningLinks({
        tilledAccount: accountId ?? AuthService.getCurrentAccountId(),
        regenerateSigningLinksParams: regenerateSigningLinksParams ? regenerateSigningLinksParams : {},
      })
      .subscribe({
        next: (response) => {
          this.regenerateSigningLinksResponse.next(response);
        },
      });
  }

  private checkAndUpdateFromPricingTemplate(application: OnboardingApplication): void {
    const cardPricing = application.pricing_templates.find(
      (p) => p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
    );
    const debitPricing = application.pricing_templates.find(
      (p) =>
        p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
        p?.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
    );

    if (application.legal_entity) {
      if (!application.legal_entity.processing_volume) {
        application.legal_entity.processing_volume = {} as ProcessingVolume;
      }
      const validPricingTemplate = cardPricing || debitPricing;
      if (validPricingTemplate?.currency === PricingTemplate.CurrencyEnum.USD) {
        application.legal_entity.processing_volume.currency = ProcessingVolume.CurrencyEnum.USD;
        application.legal_entity.region = OnboardingLegalEntity.RegionEnum.US;
      } else if (validPricingTemplate?.currency === PricingTemplate.CurrencyEnum.CAD) {
        application.legal_entity.processing_volume.currency = ProcessingVolume.CurrencyEnum.CAD;
        application.legal_entity.region = OnboardingLegalEntity.RegionEnum.CA;
      } else {
        application.legal_entity.processing_volume.currency = ProcessingVolume.CurrencyEnum.USD;
        application.legal_entity.region = OnboardingLegalEntity.RegionEnum.US;
      }
    }
  }

  private loadSteps(application: OnboardingApplication): void {
    const steps = new Array<ApplicationStep>();

    switch (application.legal_entity?.structure) {
      case OnboardingLegalEntity.StructureEnum.CORPORATION:
      case OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_COMPANY:
      case OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_PARTNERSHIP:
      case OnboardingLegalEntity.StructureEnum.LIMITED:
      case OnboardingLegalEntity.StructureEnum.PARTNERSHIP:
      case OnboardingLegalEntity.StructureEnum.TRUST:
        this._multipleOwners$.next(true);
        break;
      default:
        this._multipleOwners$.next(false);
        break;
    }

    // Paysafe Application
    if (
      this._hasPaysafeProvider$.getValue() &&
      !this._hasNorthProvider$.getValue() &&
      !this._hasTsysProvider$.getValue()
    ) {
      steps.push(
        {
          order: PaysafeApplicationSteps.WELCOME,
          type: MerchantApplicationStepType.WELCOME,
          title: 'Welcome',
          icon: 'heroicons_solid:face-smile',
          complete: {
            complete: true,
            missingFields: [],
          },
        },
        {
          order: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
          type: MerchantApplicationStepType.VERIFY_YOUR_BUSINESS,
          title: 'Verify Your Business',
          icon: 'format_align_left',
          complete: this.isVerifyYourBusinessStepComplete(application),
          subSteps: [
            PaysafeApplicationSteps.BUSINESS_TYPE_SUB_STEP,
            PaysafeApplicationSteps.BUSINESS_DETAILS_SUB_STEP,
            PaysafeApplicationSteps.CONTACT_INFORMATION_SUB_STEP,
            PaysafeApplicationSteps.APPLICATION_SIGNER_SUB_STEP,
            PaysafeApplicationSteps.BUSINESS_OWNERS_SUB_STEP,
            PaysafeApplicationSteps.PRODUCTS_AND_SERVICES_SUB_STEP,
            PaysafeApplicationSteps.PAYMENT_ACCEPTANCE_SUB_STEP,
            PaysafeApplicationSteps.PROCESSING_VOLUMES_SUB_STEP,
          ],
        },
        // Verify your business subSteps
        {
          order: PaysafeApplicationSteps.BUSINESS_TYPE_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_TYPE_SUB_STEP,
          title: 'Business type',
          icon: 'circle',
          complete: this.isBusinessTypeSubStepComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: PaysafeApplicationSteps.BUSINESS_DETAILS_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_DETAILS_SUB_STEP,
          title: 'Business details',
          icon: 'circle',
          complete: this.isBusinessDetailsSubStepComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: PaysafeApplicationSteps.CONTACT_INFORMATION_SUB_STEP,
          type: MerchantApplicationStepType.CONTACT_INFORMATION_SUB_STEP,
          title: 'Contact information',
          icon: 'circle',
          complete: this.isBusinessContactInformationComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: PaysafeApplicationSteps.APPLICATION_SIGNER_SUB_STEP,
          type: MerchantApplicationStepType.APPLICATION_SIGNER_SUB_STEP,
          title: 'Application signer',
          icon: 'circle',
          complete: this.isApplicationSignersComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: PaysafeApplicationSteps.PRODUCTS_AND_SERVICES_SUB_STEP,
          type: MerchantApplicationStepType.PRODUCTS_AND_SERVICES_SUB_STEP,
          title: 'Products and services',
          icon: 'circle',
          complete: this.isProductsAndServicesComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: PaysafeApplicationSteps.PAYMENT_ACCEPTANCE_SUB_STEP,
          type: MerchantApplicationStepType.PAYMENT_ACCEPTANCE_SUB_STEP,
          title: 'Payment acceptance',
          icon: 'circle',
          complete: this.isPaymentAcceptanceComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: PaysafeApplicationSteps.PROCESSING_VOLUMES_SUB_STEP,
          type: MerchantApplicationStepType.PROCESSING_VOLUMES_SUB_STEP,
          title: 'Processing volumes',
          icon: 'credit_card',
          complete: this.isPaymentProcessingVolumeComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: PaysafeApplicationSteps.BANK_ACCOUNT_STEP,
          type: MerchantApplicationStepType.BANK_ACCOUNT,
          title: 'Add Bank Account',
          icon: 'account_balance',
          complete: this.isBankingInformationComplete(application),
        },
        {
          order: PaysafeApplicationSteps.SUBMIT_STEP,
          type: MerchantApplicationStepType.SUBMIT,
          title: 'Submit Application',
          icon: 'check',
          complete: this.isReviewPricingAndTermsComplete(application),
        },
      );
      if (this._multipleOwners$.getValue()) {
        steps.push({
          order: PaysafeApplicationSteps.BUSINESS_OWNERS_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_OWNERS_SUB_STEP,
          title: 'Business owners',
          icon: 'circle',
          complete: this.isBusinessOwnersComplete(application),
          isSubStep: true,
          parentStep: PaysafeApplicationSteps.VERIFY_YOUR_BUSINESS,
        });
        steps.sort((a, b) => a.order - b.order);
      }
    } else if (this._hasTsysProvider$.getValue()) {
      steps.push(
        {
          order: TsysApplicationSteps.WELCOME,
          type: MerchantApplicationStepType.WELCOME,
          title: 'Welcome',
          icon: 'heroicons_solid:face-smile',
          complete: {
            complete: true,
            missingFields: [],
          },
        },
        {
          order: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
          type: MerchantApplicationStepType.VERIFY_YOUR_BUSINESS,
          title: 'Verify Your Business',
          icon: 'format_align_left',
          complete: this.isVerifyYourBusinessStepComplete(application),
          subSteps: [
            TsysApplicationSteps.BUSINESS_TYPE_SUB_STEP,
            TsysApplicationSteps.BUSINESS_DETAILS_SUB_STEP,
            TsysApplicationSteps.CONTACT_INFORMATION_SUB_STEP,
            TsysApplicationSteps.APPLICATION_SIGNER_SUB_STEP,
            TsysApplicationSteps.BUSINESS_OWNERS_SUB_STEP,
            TsysApplicationSteps.PRODUCTS_AND_SERVICES_SUB_STEP,
            TsysApplicationSteps.PAYMENT_ACCEPTANCE_SUB_STEP,
            TsysApplicationSteps.PROCESSING_VOLUMES_SUB_STEP,
          ],
        },
        // Verify your business subSteps
        {
          order: TsysApplicationSteps.BUSINESS_TYPE_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_TYPE_SUB_STEP,
          title: 'Business type',
          icon: 'circle',
          complete: this.isBusinessTypeSubStepComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: TsysApplicationSteps.BUSINESS_DETAILS_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_DETAILS_SUB_STEP,
          title: 'Business details',
          icon: 'circle',
          complete: this.isBusinessDetailsSubStepComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: TsysApplicationSteps.CONTACT_INFORMATION_SUB_STEP,
          type: MerchantApplicationStepType.CONTACT_INFORMATION_SUB_STEP,
          title: 'Contact information',
          icon: 'circle',
          complete: this.isBusinessContactInformationComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: TsysApplicationSteps.APPLICATION_SIGNER_SUB_STEP,
          type: MerchantApplicationStepType.APPLICATION_SIGNER_SUB_STEP,
          title: 'Application signer',
          icon: 'badge',
          complete: this.isApplicationSignersComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: TsysApplicationSteps.PRODUCTS_AND_SERVICES_SUB_STEP,
          type: MerchantApplicationStepType.PRODUCTS_AND_SERVICES_SUB_STEP,
          title: 'Products and services',
          icon: 'circle',
          complete: this.isProductsAndServicesComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: TsysApplicationSteps.PAYMENT_ACCEPTANCE_SUB_STEP,
          type: MerchantApplicationStepType.PAYMENT_ACCEPTANCE_SUB_STEP,
          title: 'Payment acceptance',
          icon: 'circle',
          complete: this.isPaymentAcceptanceComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: TsysApplicationSteps.PROCESSING_VOLUMES_SUB_STEP,
          type: MerchantApplicationStepType.PROCESSING_VOLUMES_SUB_STEP,
          title: 'Processing volumes',
          icon: 'credit_card',
          complete: this.isPaymentProcessingVolumeComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: TsysApplicationSteps.BANK_ACCOUNT_STEP,
          type: MerchantApplicationStepType.BANK_ACCOUNT,
          title: 'Add Bank Account',
          icon: 'account_balance',
          complete: this.isBankingInformationComplete(application),
        },
        {
          order: TsysApplicationSteps.BUSINESS_DOCUMENTS_STEP,
          type: MerchantApplicationStepType.BUSINESS_DOCUMENTS,
          title: 'Business Documents',
          icon: 'heroicons_solid:arrow-up-tray',
          complete: this.isBusinessDocumentsComplete(application),
        },
        {
          order: TsysApplicationSteps.SUBMIT_STEP,
          type: MerchantApplicationStepType.SUBMIT,
          title: 'Submit Application',
          icon: 'check',
          complete: this.isReviewPricingAndTermsComplete(application),
        },
      );
      if (this._multipleOwners$.getValue()) {
        steps.push({
          order: TsysApplicationSteps.BUSINESS_OWNERS_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_OWNERS_SUB_STEP,
          title: 'Business owners',
          icon: 'circle',
          complete: this.isBusinessOwnersComplete(application),
          isSubStep: true,
          parentStep: TsysApplicationSteps.VERIFY_YOUR_BUSINESS,
        });
        steps.sort((a, b) => a.order - b.order);
      }
    } else {
      steps.push(
        {
          order: NorthApplicationSteps.WELCOME,
          type: MerchantApplicationStepType.WELCOME,
          title: 'Welcome',
          icon: 'heroicons_solid:face-smile',
          complete: {
            complete: true,
            missingFields: [],
          },
        },
        {
          order: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
          type: MerchantApplicationStepType.VERIFY_YOUR_BUSINESS,
          title: 'Verify Your Business',
          icon: 'format_align_left',
          complete: this.isVerifyYourBusinessStepComplete(application),
          subSteps: [
            NorthApplicationSteps.BUSINESS_TYPE_SUB_STEP,
            NorthApplicationSteps.BUSINESS_DETAILS_SUB_STEP,
            NorthApplicationSteps.CONTACT_INFORMATION_SUB_STEP,
            NorthApplicationSteps.APPLICATION_SIGNER_SUB_STEP,
            NorthApplicationSteps.BUSINESS_OWNERS_SUB_STEP,
            NorthApplicationSteps.PRODUCTS_AND_SERVICES_SUB_STEP,
            NorthApplicationSteps.PAYMENT_ACCEPTANCE_SUB_STEP,
            NorthApplicationSteps.PROCESSING_VOLUMES_SUB_STEP,
          ],
        },
        // Verify your business subSteps
        {
          order: NorthApplicationSteps.BUSINESS_TYPE_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_TYPE_SUB_STEP,
          title: 'Business type',
          icon: 'circle',
          complete: this.isBusinessTypeSubStepComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: NorthApplicationSteps.BUSINESS_DETAILS_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_DETAILS_SUB_STEP,
          title: 'Business details',
          icon: 'circle',
          complete: this.isBusinessDetailsSubStepComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: NorthApplicationSteps.CONTACT_INFORMATION_SUB_STEP,
          type: MerchantApplicationStepType.CONTACT_INFORMATION_SUB_STEP,
          title: 'Contact information',
          icon: 'circle',
          complete: this.isBusinessContactInformationComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: NorthApplicationSteps.APPLICATION_SIGNER_SUB_STEP,
          type: MerchantApplicationStepType.APPLICATION_SIGNER_SUB_STEP,
          title: 'Application signer',
          icon: 'badge',
          complete: this.isApplicationSignersComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: NorthApplicationSteps.PRODUCTS_AND_SERVICES_SUB_STEP,
          type: MerchantApplicationStepType.PRODUCTS_AND_SERVICES_SUB_STEP,
          title: 'Products and services',
          icon: 'circle',
          complete: this.isProductsAndServicesComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: NorthApplicationSteps.PAYMENT_ACCEPTANCE_SUB_STEP,
          type: MerchantApplicationStepType.PAYMENT_ACCEPTANCE_SUB_STEP,
          title: 'Payment acceptance',
          icon: 'circle',
          complete: this.isPaymentAcceptanceComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: NorthApplicationSteps.PROCESSING_VOLUMES_SUB_STEP,
          type: MerchantApplicationStepType.PROCESSING_VOLUMES_SUB_STEP,
          title: 'Processing volumes',
          icon: 'credit_card',
          complete: this.isPaymentProcessingVolumeComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        },
        {
          order: NorthApplicationSteps.BANK_ACCOUNT_STEP,
          type: MerchantApplicationStepType.BANK_ACCOUNT,
          title: 'Add Bank Account',
          icon: 'account_balance',
          complete: this.isBankingInformationComplete(application),
        },
        {
          order: NorthApplicationSteps.SUBMIT_STEP,
          type: MerchantApplicationStepType.SUBMIT,
          title: 'Submit Application',
          icon: 'check',
          complete: this.isReviewPricingAndTermsComplete(application),
        },
      );
      if (this._multipleOwners$.getValue()) {
        steps.push({
          order: NorthApplicationSteps.BUSINESS_OWNERS_SUB_STEP,
          type: MerchantApplicationStepType.BUSINESS_OWNERS_SUB_STEP,
          title: 'Business owners',
          icon: 'circle',
          complete: this.isBusinessOwnersComplete(application),
          isSubStep: true,
          parentStep: NorthApplicationSteps.VERIFY_YOUR_BUSINESS,
        });
        steps.sort((a, b) => a.order - b.order);
      }
    }

    this.stepsSubject.next(steps);
    let currentStep = steps[0];

    if (this._router.url.includes('onboarding/application')) {
      this._router.routerState.root.queryParams.subscribe((params) => {
        if (params && params.step) {
          const step = steps.find((s) => s.type === params.step);
          if (step) {
            currentStep = step;
          } else {
            this._router.navigate([], {
              queryParams: { step: currentStep.type },
              queryParamsHandling: 'merge',
            });
          }
        }
      });
    }

    if (currentStep.subSteps?.length > 0) {
      currentStep = steps.filter((step) => step.order === currentStep.subSteps[0])[0];
    }

    this.currentStepSubject.next(currentStep);
  }

  private mapResponseToParams(response: OnboardingApplication): OnboardingApplicationCreateParams {
    const params: OnboardingApplicationCreateParams = {
      tos_acceptance: response.tos_acceptance,
      canada_visa_mc_processing: response.canada_visa_mc_processing,
      legal_entity: response.legal_entity,
    };

    return params;
  }

  private updateBankDocsRequired(application: OnboardingApplication, internalMetadata?: AccountInternalMetadata): void {
    if (internalMetadata) {
      this.bankVerificationMethods = internalMetadata?.bank_verification_methods;
      this.hasBankVerificationEnabled =
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.PLAID) ||
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.TILLED_MANUAL)
          ? true
          : false;
    }
    if (this.hasBankVerificationEnabled) {
      const accNum = application.legal_entity?.bank_account?.account_number;
      if (
        (accNum == null || accNum.trim().length === 0) &&
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.PLAID)
      ) {
        this.areBankDocsRequired = false;
      } else {
        if (application.bank_verification?.verification_status === BankVerification.VerificationStatusEnum.VERIFIED) {
          this.areBankDocsRequired = false;
        } else {
          this.areBankDocsRequired = true;
        }
      }
    } else {
      this.areBankDocsRequired = false;
    }
  }

  public getMccDescriptions(accountId: string): Observable<any[]> {
    return this._merchantApplicationService.getMccDescriptions({ tilledAccount: accountId });
  }

  public updateProviderData(): void {
    let hasTsys = false;
    let hasNorth = false;
    let hasPaysafe = false;
    combineLatest([
      this._authService.account$,
      this._accountService.connectedAccount$.pipe(
        takeUntil(this.unsubscribe$),
        catchError(() => EMPTY),
      ),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([account, connectedAccount]) => connectedAccount || account),
        filter((account) => !!account),
        map((account: InternalAccount) => {
          for (const capability of account.capabilities ?? []) {
            if (
              capability.provider_type === InternalAccountCapability.ProviderTypeEnum.TSYS ||
              capability.provider_type === InternalAccountCapability.ProviderTypeEnum.VALOR ||
              capability.provider_type === InternalAccountCapability.ProviderTypeEnum.MULTI_PASS
            ) {
              hasTsys = true;
            }
            if (capability.provider_type === InternalAccountCapability.ProviderTypeEnum.NORTH) {
              hasNorth = true;
            }
            if (capability.provider_type === InternalAccountCapability.ProviderTypeEnum.PAYSAFE) {
              hasPaysafe = true;
            }
          }
          return [hasTsys, hasNorth, hasPaysafe];
        }),
      )
      .subscribe(([hasTsys, hasNorth, hasPaysafe]) => {
        this._hasTsysProvider$.next(hasTsys);
        this._hasNorthProvider$.next(hasNorth);
        this._hasPaysafeProvider$.next(hasPaysafe);
      });
  }

  public toggleDrawer(): void {
    this._drawerOpen.next(!this._drawerOpen.getValue());
  }
  public getPartnerOnboardingDefaults(accountId: string) {
    this._internalService
      .internalGetPartnerOnboardingDefaultSettings({
        tilledAccount: accountId,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          if (result) {
            this._partnerOnboardingDefaults$.next(result);
          }
          return;
        },
      });
  }

  public setPartnerOnboardingDefaults(
    accountId: string,
    request: InternalUpdatePartnerOnboardingDefaultSettingsParams,
  ) {
    this._internalService
      .internalUpdatePartnerOnboardingDefaultSettings({
        tilledAccount: accountId,
        internalUpdatePartnerOnboardingDefaultSettingsParams: request,
      })
      .subscribe({
        next: (result) => {
          this.getPartnerOnboardingDefaults(accountId);
        },
      });
  }

  public getMiddeskBusiness(accountId: string): void {
    this._middeskAppService.getMiddeskBusiness(accountId);
    this._middeskAppService.middeskBusiness$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (business) => {
        this._middeskBusiness$.next(business);
      },
    });
  }

  public async createMiddeskBusiness(accountId: string, application: OnboardingApplication): Promise<boolean> {
    const params: CreateMiddeskBusinessRequestParams = {
      tilledAccount: accountId,
      middeskBusinessCreateParams: {
        address: {
          city: application?.legal_entity?.address?.city,
          country: application?.legal_entity?.address?.country,
          state: application?.legal_entity?.address?.state as MiddeskBusinessAddressCreateParams.StateEnum,
          street: application?.legal_entity?.address?.street,
          street2: application?.legal_entity?.address?.street2,
          zip: application?.legal_entity?.address?.postal_code,
        },
        dba_name: application?.legal_entity.dba_name,
        legal_name: application?.legal_entity.legal_name,
        tax_identification_number: application?.legal_entity.tax_id_number,
      },
    };

    if (this._middeskBusiness$.getValue()) {
      return Promise.resolve(true);
    }

    try {
      const business = await this._middeskAppService.createMiddeskBusiness(params);
      if (business) {
        this._middeskBusiness$.next(business);
      }
    } catch (error) {
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  }

  public updateMiddeskBusiness(accountId: string, obj: MiddeskBusinessUpdateParams): void {
    const params: UpdateMiddeskBusinessRequestParams = {
      tilledAccount: accountId,
      middeskBusinessUpdateParams: obj,
    };
    this._middeskAppService.updateMiddeskBusiness(params);
  }

  public resendEsignatureEmail(signerId: string, documentId: string, accountId?: string): Observable<any> {
    return this._merchantApplicationService.resendEsignatureDocumentEmail({
      tilledAccount: accountId ?? AuthService.getCurrentAccountId(),
      id: signerId,
      documentId: documentId,
    });
  }
}
