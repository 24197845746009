import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import {
  BusinessRepresentative,
  DownloadPartnerApplicationRequestParams,
  GetPartnerApplicationRequestParams,
  PartnerApplicationParams,
  PartnerApplicationResponse,
  PartnerApplicationsService,
  SubmitPartnerApplicationRequestParams,
  UpdatePartnerApplicationRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { ApplicationStep } from '../models/application-step';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerAppService {
  private stepsSubject = new BehaviorSubject<ApplicationStep[]>(null);
  private currentStepSubject = new BehaviorSubject<number>(0);
  private applicationResponse = new BehaviorSubject<PartnerApplicationResponse>(null);
  private submittedApplicationResponse = new BehaviorSubject<PartnerApplicationResponse>(null);
  public partnerAppSteps$: Observable<ApplicationStep[]> = this.stepsSubject.asObservable();
  public partnerApplicationResponse$: Observable<PartnerApplicationResponse> = this.applicationResponse.asObservable();
  public currentStep$: Observable<number> = this.currentStepSubject.asObservable();
  public submittedApplicationResponse$: Observable<PartnerApplicationResponse> =
    this.submittedApplicationResponse.asObservable();
  private isReviewed: boolean = false;
  private isDocUploaded: boolean = false;

  constructor(
    private _partnerApplicationService: PartnerApplicationsService,
    private _authService: AuthService,
    private _alertService: AlertService,
  ) {}

  public updateCurrentStep(step: number): void {
    this.currentStepSubject.next(step);
  }

  public loadApplication(accountId?: string): Observable<PartnerApplicationResponse> {
    const params: GetPartnerApplicationRequestParams = {
      tilledAccount: accountId || AuthService.getCurrentAccountId(),
    };
    return this._partnerApplicationService.getPartnerApplication(params).pipe(
      tap((application) => {
        this.loadSteps(application);
        this.applicationResponse.next(application);
      }),
      catchError((err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not load application, please try again or contact Support for assistance',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        return of(null);
      }),
    );
  }

  public updatePartnerApplication(updatedApp: PartnerApplicationResponse, nextStep?: number): void {
    const params: UpdatePartnerApplicationRequestParams = {
      tilledAccount: AuthService.getCurrentAccountId(),
      partnerApplicationParams: this.mapResponseToParams(updatedApp),
    };
    this._partnerApplicationService
      .updatePartnerApplication(params)
      .pipe(
        tap((application) => {
          this.loadSteps(application);
          if (nextStep) {
            this.currentStepSubject.next(nextStep);
          }
          this.reviewed(false);
        }),
        catchError((err) => {
          if (err.error) {
            if (err.error.statusCode === 400) {
              let errorMessage = '';
              for (const errMsg of err.error.message) {
                errorMessage += '\u2022 ' + errMsg + '\n';
              }
              const message: TilledAlert = {
                message: errorMessage,
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            } else {
              // generic catch all for error responses
              const message: TilledAlert = {
                message: 'Could not update application, please try again or contact Support for assistance',
                title: 'Server error',
                type: 'error',
              };
              this._alertService.showAlert(message);
            }
          }
          return of(null);
        }),
      )
      .subscribe((response) => this.applicationResponse.next(response));
  }

  public submitPartnerApplication(finishedApp: PartnerApplicationResponse): void {
    const params: SubmitPartnerApplicationRequestParams = {
      tilledAccount: AuthService.getCurrentAccountId(),
    };
    this._partnerApplicationService.submitPartnerApplication(params).subscribe({
      next: (response) => this.submittedApplicationResponse.next(response),
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: 'Could not submit application, please try again or contact Support for assistance',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  public reviewed(reviewed: boolean): void {
    this.isReviewed = reviewed;
  }

  public docUploaded(docUploaded: boolean): void {
    this.isDocUploaded = docUploaded;
  }

  public loadSteps(application: PartnerApplicationResponse): void {
    const steps = new Array<ApplicationStep>();
    steps.push(
      {
        order: 0,
        title: 'Business Structure',
        icon: 'account_tree',
        complete: this.isBusinessStructureStepComplete(application),
      },
      {
        order: 1,
        title: 'Business Details',
        icon: 'text_snippet',
        complete: this.isBusinessDetailsStepComplete(application),
      },
      {
        order: 2,
        title: 'Primary Contact',
        icon: 'badge',
        complete: this.isPrimaryContactStepComplete(application),
      },
      {
        order: 3,
        title: 'Migration & Onboarding',
        icon: 'settings',
        complete: this.isMigrationAndOnboardingComplete(application),
      },
      {
        order: 4,
        title: 'Fulfillment Details',
        icon: 'shop',
        complete: this.isFulfillmentDetailsComplete(application),
      },
      {
        order: 5,
        title: 'Billing & Checkout',
        icon: 'credit_card',
        complete: this.isBillingCheckoutComplete(application),
      },
      {
        order: 6,
        title: 'Processing Volumes',
        icon: 'bar_chart',
        complete: this.isProcessingVolumesComplete(application),
      },
      {
        order: 7,
        title: 'Business Representatives',
        icon: 'person',
        complete: this.isBusinessRepresentativesComplete(application),
      },
      {
        order: 8,
        title: 'Banking Information',
        icon: 'account_balance',
        complete: this.isBankingInformationComplete(application),
      },
      {
        order: 9,
        title: 'Upload Documents',
        icon: 'cloud_upload',
        complete: this.isDocUploaded,
      },
      {
        order: 10,
        title: 'Review',
        icon: 'done',
        complete: this.isReviewed,
      },
      {
        order: 11,
        title: 'Acknowledge & Submit',
        icon: 'border_color',
      },
    );
    this.stepsSubject.next(steps);
  }

  async downloadApplication(params: DownloadPartnerApplicationRequestParams): Promise<void> {
    const fileName = `${params.tilledAccount}_partner_application.pdf`;
    await this._partnerApplicationService.downloadPartnerApplication(params).subscribe({
      next: (response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const objectUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a') as HTMLAnchorElement;
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(objectUrl);
      },
      error: (err) => {
        const message: TilledAlert = {
          message: `${fileName} failed to download`,
          title: 'Download Failed',
          type: 'error',
          bottom: true,
        };
        this._alertService.showAlert(message);
      },
    });
  }

  private isBusinessStructureStepComplete(application: PartnerApplicationParams): boolean {
    return application.business_profile?.structure && application.business_profile?.partner_regions?.length > 0;
  }

  private isBusinessDetailsStepComplete(application: PartnerApplicationParams): boolean {
    if (
      application.business_profile?.legal_name &&
      application.business_profile?.tax_identification_number &&
      application.business_profile?.state_of_incorporation &&
      application.business_profile?.date_of_incorporation &&
      application.business_profile?.address?.street &&
      application.business_profile?.address?.city &&
      application.business_profile?.address?.state &&
      application.business_profile?.address?.postal_code &&
      application.business_profile?.website &&
      application.business_profile?.phone &&
      application.business_profile?.partner_categories?.length > 0 &&
      application.business_profile?.description
    ) {
      return true;
    }
    return false;
  }

  private isPrimaryContactStepComplete(application: PartnerApplicationParams): boolean {
    const existingPrimary = application.business_profile?.representatives?.find(
      (rep) => rep.type === BusinessRepresentative.TypeEnum.CONTACT,
    );

    if (
      existingPrimary &&
      existingPrimary.first_name &&
      existingPrimary.last_name &&
      existingPrimary.phone &&
      existingPrimary.email
    ) {
      return true;
    }
    return false;
  }

  private isMigrationAndOnboardingComplete(application: PartnerApplicationParams): boolean {
    if (
      application.processing_information?.migration_and_onboarding &&
      application.processing_information.migration_and_onboarding.previous_processing_history != null &&
      application.processing_information.migration_and_onboarding.total_merchant_portfolio_size >= 0 &&
      application.processing_information.migration_and_onboarding.expected_onboarded_30_days >= 0 &&
      application.processing_information.migration_and_onboarding.expected_onboarded_60_days >= 0 &&
      application.processing_information.migration_and_onboarding.expected_onboarded_90_days >= 0
    ) {
      if (
        application.processing_information.migration_and_onboarding.migrating_portfolio === false ||
        (application.processing_information.migration_and_onboarding.migrating_portfolio === true &&
          application.processing_information.migration_and_onboarding.migration_timeline)
      ) {
        return true;
      }
    }
    return false;
  }

  private isFulfillmentDetailsComplete(application: PartnerApplicationParams): boolean {
    if (application.processing_information?.fulfillment_details?.any_deposits_over_250k != null) {
      if (
        application.processing_information.fulfillment_details.collects_deposits_before_service === false ||
        (application.processing_information.fulfillment_details.collects_deposits_before_service === true &&
          application.processing_information.fulfillment_details.average_outstanding_deposit)
      ) {
        return true;
      }
    }
    return false;
  }

  private isBillingCheckoutComplete(application: PartnerApplicationParams): boolean {
    if (
      application.processing_information?.billing_and_checkout?.billing_model?.length > 0 &&
      application.processing_information.billing_and_checkout.checkout_methods?.length > 0
    ) {
      return true;
    }
    return false;
  }

  private isProcessingVolumesComplete(application: PartnerApplicationParams): boolean {
    if (
      application.processing_information?.processing_volumes?.avg_merchant_transaction_amount &&
      application.processing_information?.processing_volumes.avg_merchant_transactions_per_month &&
      application.processing_information?.processing_volumes.avg_merchant_volume_per_month &&
      application.processing_information?.processing_volumes.expected_monthly_volume_in_12_months &&
      application.processing_information?.processing_volumes.expected_number_of_merchants &&
      application.processing_information?.processing_volumes.highest_expected_transaction_amount
    ) {
      return true;
    }
    return false;
  }

  private isBusinessRepresentativesComplete(application: PartnerApplicationParams): boolean {
    const owners = application.business_profile?.representatives?.filter(
      (rep) => rep.type === BusinessRepresentative.TypeEnum.SHAREHOLDER,
    );
    if (!(owners?.length > 0)) {
      return false;
    }
    for (const owner of owners) {
      if (
        !(
          owner &&
          owner?.first_name &&
          owner?.last_name &&
          owner?.percentage_shareholding &&
          owner?.date_of_birth &&
          owner?.addresses &&
          owner?.addresses[0]?.street &&
          owner?.addresses[0]?.city &&
          owner?.addresses[0]?.state &&
          owner?.addresses[0]?.postal_code &&
          owner.bg_check_agreed_at &&
          owner.bg_check_legal_name
        )
      ) {
        return false;
      }
    }
    return true;
  }

  private isBankingInformationComplete(application: PartnerApplicationParams): boolean {
    if (
      application.bank_account?.account_holder_name &&
      application.bank_account?.account_number &&
      application.bank_account?.bank_name &&
      application.bank_account?.routing_number &&
      application.bank_account?.phone
    ) {
      return true;
    }
    return false;
  }

  private mapResponseToParams(response: PartnerApplicationResponse): PartnerApplicationParams {
    const params: PartnerApplicationParams = {
      account_name: response.account_name,
      business_profile: response.business_profile,
      bank_account: response.bank_account,
      processing_information: response.processing_information,
      signature: response.signature,
    };

    return params;
  }
}
