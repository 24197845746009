/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountCapabilityProcessingDetail } from './accountCapabilityProcessingDetail';
import { ProductCode } from './productCode';
import { AccountRequirementsError } from './accountRequirementsError';
import { PaymentProviderAttributes } from './paymentProviderAttributes';
import { AccountProcessingCapabilities } from './accountProcessingCapabilities';
import { PricingTemplate } from './pricingTemplate';


export interface InternalAccountCapability { 
    /**
     * Number of active merchants associated with the partner account.
     */
    active_merchants_count?: number;
    /**
     * The collection of optional or required attributes for onboarding and operation.
     */
    attributes?: PaymentProviderAttributes;
    billing_owner: InternalAccountCapability.BillingOwnerEnum;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The percentage of required fields completed in the onboarding application.
     */
    onboarding_application_progress: number;
    /**
     * The URL for the merchant\'s onboarding application.
     */
    onboarding_application_url?: string;
    /**
     * The status of the account\'s partner application. Only available for partner accounts.
     */
    partner_application_status?: InternalAccountCapability.PartnerApplicationStatusEnum;
    /**
     * The pricing applied to/associated with this account.
     */
    pricing_template: PricingTemplate;
    /**
     * A list of processing capabilities for this account. Only available for partner accounts.
     */
    processing_capabilities?: AccountProcessingCapabilities;
    processing_details?: AccountCapabilityProcessingDetail;
    /**
     * *Deprecated: See `pricing_template` for account details.*  The capability applied to/associated with this account.
     * @deprecated
     */
    product_code: ProductCode;
    provider_type: InternalAccountCapability.ProviderTypeEnum;
    /**
     * The fields that need to be collected again because validation failed for some reason.
     */
    requirements_errors: Array<AccountRequirementsError>;
    /**
     * Status of the associated capability as part of the onboarding process. Only the `active` status represents that payments can be handled by this account for this given capability.  `created` The capability has been created but no further action taken. Generally, this is the state of all capabilities when a merchant has been created but the onboarding process has not yet started.  `started` The onboarding process has been started but not yet submitted.  `submitted` The onboarding process for this capability has been submitted.  `active` This associated capability is active for payments on this account.  `disabled` This associated capability is not valid for payments on this account.
     */
    status: InternalAccountCapability.StatusEnum;
}
export namespace InternalAccountCapability {
    export type BillingOwnerEnum = 'provider' | 'tilled';
    export const BillingOwnerEnum = {
        PROVIDER: 'provider' as BillingOwnerEnum,
        TILLED: 'tilled' as BillingOwnerEnum
    };
    export type PartnerApplicationStatusEnum = 'created' | 'started' | 'submitted';
    export const PartnerApplicationStatusEnum = {
        CREATED: 'created' as PartnerApplicationStatusEnum,
        STARTED: 'started' as PartnerApplicationStatusEnum,
        SUBMITTED: 'submitted' as PartnerApplicationStatusEnum
    };
    export type ProviderTypeEnum = 'paysafe' | 'tsys' | 'mock' | 'valor' | 'handpoint' | 'north' | 'multi_pass';
    export const ProviderTypeEnum = {
        PAYSAFE: 'paysafe' as ProviderTypeEnum,
        TSYS: 'tsys' as ProviderTypeEnum,
        MOCK: 'mock' as ProviderTypeEnum,
        VALOR: 'valor' as ProviderTypeEnum,
        HANDPOINT: 'handpoint' as ProviderTypeEnum,
        NORTH: 'north' as ProviderTypeEnum,
        MULTI_PASS: 'multi_pass' as ProviderTypeEnum
    };
    export type StatusEnum = 'created' | 'started' | 'submitted' | 'active' | 'disabled' | 'in_review' | 'rejected' | 'withdrawn';
    export const StatusEnum = {
        CREATED: 'created' as StatusEnum,
        STARTED: 'started' as StatusEnum,
        SUBMITTED: 'submitted' as StatusEnum,
        ACTIVE: 'active' as StatusEnum,
        DISABLED: 'disabled' as StatusEnum,
        IN_REVIEW: 'in_review' as StatusEnum,
        REJECTED: 'rejected' as StatusEnum,
        WITHDRAWN: 'withdrawn' as StatusEnum
    };
}


