import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { PricingTemplateAppService } from 'app/core/services/pricing-template.app.service';
import { CardPresentPricingCardComponent } from 'app/shared/cards/card-present-pricing-card/card-present-pricing-card.component';
import { PartnerSelectorComponent } from 'app/shared/partner-selector/partner-selector.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { environment } from 'environments/environment';
import { isEqual, some, sortBy } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import {
  BillingConfig,
  CardChargeFeeTemplate,
  InternalAccount,
  InternalService,
  Markup,
  PricingTemplate,
} from '../../../../../projects/tilled-api-client/src';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { CardPricingCardComponent } from '../../cards/card-pricing-card/card-pricing-card.component';
import { DebitPricingCardComponent } from '../../cards/debit-pricing-card/debit-pricing-card.component';
import { FormCardComponent } from '../../cards/form-cards/form-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH6Component } from '../../tilled-text/tilled-heading/tilled-heading-h6.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'app-add-connected-account',
  templateUrl: './connected-account-dialog.component.html',
  styleUrls: ['./connected-account-dialog.component.scss'],
  standalone: true,
  imports: [
    FormCardComponent,
    TilledParagraphP3Component,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    FuseAlertComponent,
    MatIconModule,
    TilledHeadingH6Component,
    TilledLabelL1Component,
    TilledSelectComponent,
    MatSlideToggleModule,
    CardPricingCardComponent,
    CardPresentPricingCardComponent,
    DebitPricingCardComponent,
    PartnerSelectorComponent,
    CommonModule,
  ],
})
export class ConnectedAccountDialogComponent extends ComponentBase implements OnInit {
  @Output() addMerchantData;
  public connectedAccountForm: FormGroup;
  public hasCardCapability: boolean = false;
  public hasCardPresentCapability: boolean = false;
  public hasDebitCapability: boolean = false;
  public cardIsChecked: boolean = false;
  public cardPresentIsChecked: boolean = false;
  public debitIsChecked: boolean = false;
  public pricingTemplateSub: Subscription;
  public pricingTemplates$: Observable<PricingTemplate[]>;
  public cardPricingTemplates: PricingTemplate[];
  public cardPricingTemplateOptions: { label: string; value: string }[];
  public cardPresentPricingTemplates: PricingTemplate[];
  public cardPresentPricingTemplateOptions: { label: string; value: string }[];
  public debitPricingTemplates: PricingTemplate[];
  public debitPricingTemplateOptions: { label: string; value: string }[];
  public isLoading: boolean;
  public selectedCardTemplate: CardPricingTemplateViewModel;
  public selectedCardPresentTemplate: CardPresentPricingTemplateViewModel;
  public selectedDebitTemplate: DebitPricingTemplateViewModel;
  public isSandbox: boolean = false;
  public isReseller: boolean = false;
  public selectedPartnerAccountId: string;
  public cardPresentErrorMessage: string;
  public cardErrorMessage: string;
  public accountTypeToCreate: InternalAccount.TypeEnum;
  private pricingTemplates: PricingTemplate[];
  private accountId: string;
  private pricingTemplateId: string;
  private pricingPmType: PricingTemplate.PaymentMethodTypeEnum;
  private pricingCurrency: PricingTemplate.CurrencyEnum;
  private nickname: string;
  private billingConfigs: BillingConfig[];

  constructor(
    public dialogRef: MatDialogRef<ConnectedAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _pricingTemplateAppService: PricingTemplateAppService,
    private _internalService: InternalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.accountId = this._data?.accountId ?? null;
    this.accountTypeToCreate = this._data?.accountType ?? InternalAccount.TypeEnum.MERCHANT;
    this.pricingTemplateId = this._data?.pricingTemplate ?? null;
    this.pricingPmType = this._data?.pricingPmType ?? null;
    this.pricingCurrency = this._data?.pricingCurrency ?? null;
    this.cardIsChecked = this.pricingPmType === 'card';
    this.cardPresentIsChecked = this.pricingPmType === 'card_present';
    this.debitIsChecked = this.pricingPmType === 'ach_debit' || this.pricingPmType === 'eft_debit';
    this.isSandbox = !environment.production;
    this.cardPricingTemplateOptions = [];
    this.cardPresentPricingTemplateOptions = [];
    this.debitPricingTemplateOptions = [];
    this.cardPresentErrorMessage = 'Card-present pricing is required if selected';
    this.cardErrorMessage = 'Card pricing is required if selected';
    this.isReseller = this._data?.isReseller ?? false;

    if (this.accountTypeToCreate === InternalAccount.TypeEnum.PARTNER) {
      this.connectedAccountForm = this._formBuilder.group({
        name: new FormControl<string | null>(null, [Validators.required]),
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
      });
    } else {
      this.connectedAccountForm = this._formBuilder.group({
        ...(this.isReseller && {
          partner: new FormControl<string | null>(null, [Validators.required]),
        }),
        name: new FormControl<string | null>(null, [Validators.required]),
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
        region: new FormControl<string | null>(null, [Validators.required]),
        cardPricingTemplate: new FormControl<string | null>(null),
        cardPresentPricingTemplate: new FormControl<string | null>(null),
        debitPricingTemplate: new FormControl<string | null>(null),
      });

      this.getProcessingCapabilities();
    }
  }

  getProcessingCapabilities(): void {
    const accountIdToUse = this.selectedPartnerAccountId ?? this.accountId;
    this._internalService
      .internalGetAccountProcessingCapabilities({
        tilledAccount: accountIdToUse,
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        this.hasCardCapability = result.card.length > 0;
        this.hasDebitCapability = result.debit.length > 0;
        this.hasCardPresentCapability = result.card_present.length > 0;
      });

    this._internalService
      .internalGetBillingConfig({
        tilledAccount: accountIdToUse,
      })
      .subscribe({
        next: (billingConfigs) => {
          if (billingConfigs) {
            this.billingConfigs = billingConfigs;
          }
        },
      });

    this.pricingTemplateSub = this._pricingTemplateAppService
      .getPricingTemplates(accountIdToUse)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.pricingTemplates = res;
        this.isLoading = false;
        this.onboardNewMerchant();
      });
  }

  // Used when `Onboard New Merchant` button is clicked after creating a pricing template
  onboardNewMerchant(): void {
    this.connectedAccountForm.get('region').setValue(this.pricingCurrency);
    this.updatePricingTemplates({ value: this.pricingCurrency });
    this.cardIsChecked = this.pricingPmType === 'card';
    this.cardPresentIsChecked = false;
    this.debitIsChecked = false;
    if (this.cardIsChecked) {
      this.connectedAccountForm.get('cardPricingTemplate').setValue(this.pricingTemplateId);
      this.updateSelectedCard({ value: this.pricingTemplateId });
    }
    if (this.cardPresentIsChecked) {
      this.connectedAccountForm.get('cardPresentPricingTemplate').setValue(this.pricingTemplateId);
      this.updateSelectedCardPresent({ value: this.pricingTemplateId });
    }
    if (this.debitIsChecked) {
      this.connectedAccountForm.get('debitPricingTemplate').setValue(this.pricingTemplateId);
      this.updateSelectedDebit({ value: this.pricingTemplateId });
    }
  }

  cardToggled(event: any): void {
    if (event.checked === true) {
      this.connectedAccountForm.controls.cardPricingTemplate.setValidators([Validators.required]);
    } else {
      this.connectedAccountForm.controls.cardPricingTemplate.clearValidators();
    }
    this.connectedAccountForm.controls.cardPricingTemplate.updateValueAndValidity();
    this.connectedAccountForm.controls.cardPricingTemplate.setValue(null);
    this.selectedCardTemplate = null;
  }

  cardPresentToggled(event: any): void {
    if (event.checked === true) {
      this.connectedAccountForm.get('cardPresentPricingTemplate').setValidators([Validators.required]);
    } else {
      this.connectedAccountForm.get('cardPresentPricingTemplate').clearValidators();
    }
    this.connectedAccountForm.get('cardPresentPricingTemplate').updateValueAndValidity();
    this.connectedAccountForm.get('cardPresentPricingTemplate').setValue(null);
    this.selectedCardPresentTemplate = null;
  }

  debitToggled(event: any): void {
    if (event.checked === true) {
      this.connectedAccountForm.get('debitPricingTemplate').setValidators([Validators.required]);
    } else {
      this.connectedAccountForm.get('debitPricingTemplate').clearValidators();
    }
    this.connectedAccountForm.get('debitPricingTemplate').updateValueAndValidity();
    this.connectedAccountForm.get('debitPricingTemplate').setValue(null);
    this.selectedDebitTemplate = null;
  }

  updateSelectedCard(event: any): void {
    const cardPricing = this.pricingTemplates.find((p) => p.id == event.value);
    if (cardPricing) {
      this.selectedCardTemplate = new CardPricingTemplateViewModel(cardPricing);
      if (this.isProviderBillingForCardAndCardPresent(cardPricing)) {
        if (!this.matchingPricingTemplateExists(cardPricing, true)) {
          this.connectedAccountForm.get('cardPresentPricingTemplate').setErrors({
            ...this.connectedAccountForm.get('cardPresentPricingTemplate').errors,
            noMatchingCardPresentTemplate: true,
          });
          this.cardPresentPricingTemplateOptions = [];
          this.cardPresentErrorMessage =
            'Pricing must be the same for card-present and card payments. Please create a card-present pricing template which matches the currently selected card pricing template.';
          setTimeout(() => {
            this.connectedAccountForm.get('cardPresentPricingTemplate').markAsTouched();
            this.changeDetector.detectChanges();
          });
        }
      }
    }
  }

  updateSelectedCardPresent(event: any): void {
    const cardPresentPricing = this.pricingTemplates.find((p) => p.id == event.value);
    if (cardPresentPricing) {
      this.selectedCardPresentTemplate = new CardPresentPricingTemplateViewModel(cardPresentPricing);
      if (this.isProviderBillingForCardAndCardPresent(cardPresentPricing)) {
        if (!this.matchingPricingTemplateExists(cardPresentPricing, false)) {
          this.connectedAccountForm.get('cardPricingTemplate').setErrors({
            ...this.connectedAccountForm.get('cardPricingTemplate').errors,
            noMatchingCardTemplate: true,
          });
          this.cardPricingTemplateOptions = [];
          this.cardErrorMessage =
            'Pricing must be the same for card-present and card payments. Please create a card pricing template which matches the currently selected card-present pricing template.';

          setTimeout(() => {
            this.connectedAccountForm.get('cardPricingTemplate').markAsTouched();
            this.changeDetector.detectChanges();
          });
        }
      }
    }
  }

  updateSelectedDebit(event: any): void {
    const debitPricing = this.pricingTemplates.find((p) => p.id == event.value);
    if (debitPricing) {
      this.selectedDebitTemplate = new DebitPricingTemplateViewModel(debitPricing);
    }
  }

  updatePricingTemplates(event: any): void {
    const currFilter = event.value as PricingTemplate.CurrencyEnum;
    this.cardPricingTemplates = this.pricingTemplates.filter(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD && p.currency === currFilter,
    );
    this.cardPricingTemplateOptions = this.cardPricingTemplates.map((p) => ({
      label: p.name,
      value: p.id,
    }));

    this.cardPresentPricingTemplates = this.pricingTemplates.filter(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT && p.currency === currFilter,
    );
    this.cardPresentPricingTemplateOptions = this.cardPresentPricingTemplates.map((p) => ({
      label: p.name,
      value: p.id,
    }));

    this.debitPricingTemplates = this.pricingTemplates.filter(
      (p) =>
        (p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
          p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT) &&
        p.currency === currFilter,
    );
    this.debitPricingTemplateOptions = this.debitPricingTemplates.map((p) => ({
      label: p.name,
      value: p.id,
    }));
    this.connectedAccountForm.get('cardPricingTemplate').setValue(null);
    this.connectedAccountForm.get('cardPresentPricingTemplate').setValue(null);
    this.connectedAccountForm.get('debitPricingTemplate').setValue(null);
    this.selectedCardTemplate = null;
    this.selectedCardPresentTemplate = null;
    this.selectedDebitTemplate = null;
  }

  partnerAccountChanged(account: InternalAccount): void {
    if (!account?.id) {
      this.selectedPartnerAccountId = null;
      this.connectedAccountForm.get('partner').setValue(null);
      this.getProcessingCapabilities();
      return;
    }
    this.connectedAccountForm.get('partner').setValue(account.id);
    this.selectedPartnerAccountId = account.id;
    this.getProcessingCapabilities();
  }

  public addMerchantClicked(): void {
    this.connectedAccountForm.markAllAsTouched();
    if (!this.connectedAccountForm.invalid) {
      if (
        this.accountTypeToCreate !== InternalAccount.TypeEnum.PARTNER &&
        !this.connectedAccountForm.get('cardPricingTemplate').value &&
        !this.connectedAccountForm.get('cardPresentPricingTemplate').value &&
        !this.connectedAccountForm.get('debitPricingTemplate').value
      ) {
        return;
      }
      this.dialogRef.close(this.connectedAccountForm);
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private matchingPricingTemplateExists(template: PricingTemplate, cardSelected: boolean): boolean {
    if (cardSelected) {
      return some(
        this.cardPresentPricingTemplates,
        (t) =>
          t.card_present?.transaction_fee_type === template.card?.transaction_fee_type &&
          t.account_monthly_fee === template.account_monthly_fee &&
          t.account_monthly_minimum_fee === template.account_monthly_minimum_fee &&
          t.currency === template.currency &&
          t.card_present?.chargeback_fee === template.card?.chargeback_fee &&
          t.card_present?.retrieval_fee === template.card?.retrieval_fee &&
          t.card_present?.reversal_fee === template.card?.reversal_fee &&
          t.card_present?.transaction_fee === template.card?.transaction_fee &&
          isEqual(
            sortBy(t.card_present?.markups, 'card_type', 'rate'),
            sortBy(template.card?.markups, 'card_type', 'rate'),
          ),
      );
    } else {
      return some(
        this.cardPricingTemplates,
        (t) =>
          t.card?.transaction_fee_type === template.card_present?.transaction_fee_type &&
          t.account_monthly_fee === template.account_monthly_fee &&
          t.account_monthly_minimum_fee === template.account_monthly_minimum_fee &&
          t.currency === template.currency &&
          t.card?.chargeback_fee === template.card_present?.chargeback_fee &&
          t.card?.retrieval_fee === template.card_present?.retrieval_fee &&
          t.card?.reversal_fee === template.card_present?.reversal_fee &&
          t.card?.transaction_fee === template.card_present?.transaction_fee &&
          isEqual(
            sortBy(t.card?.markups, 'card_type', 'rate'),
            sortBy(template.card_present?.markups, 'card_type', 'rate'),
          ),
      );
    }
  }

  private isProviderBillingForCardAndCardPresent(template: PricingTemplate): boolean {
    return (
      some(
        this.billingConfigs,
        (b) =>
          template.currency === b.currency &&
          b.billing_owner === BillingConfig.BillingOwnerEnum.PROVIDER &&
          b.payment_method_type === BillingConfig.PaymentMethodTypeEnum.CARD,
      ) &&
      some(
        this.billingConfigs,
        (b) =>
          template.currency === b.currency &&
          b.billing_owner === BillingConfig.BillingOwnerEnum.PROVIDER &&
          b.payment_method_type === BillingConfig.PaymentMethodTypeEnum.CARD_PRESENT,
      )
    );
  }
}

export class CardPricingTemplateViewModel {
  public name: string;
  public currency: PricingTemplate.CurrencyEnum;
  public fee_type: CardChargeFeeTemplate.TransactionFeeTypeEnum;
  public visa_rate: number;
  public amex_rate: number;
  public transaction_fee: number;
  public chargeback_fee: number;
  public retrieval_fee: number;
  public reversal_fee: number;
  public account_monthly_fee: number;
  public account_monthly_minimum_fee: number;
  public pass_through_fees: boolean;

  public constructor(t: PricingTemplate) {
    const visaRate = t.card.markups.find((m) => m.card_type === Markup.CardTypeEnum.VISA).rate;
    const amexRate = t.card.markups.find((m) => m.card_type === Markup.CardTypeEnum.AMEX).rate;
    this.name = t.name;
    this.currency = t.currency;
    this.fee_type = t.card.transaction_fee_type;
    this.visa_rate = visaRate ? visaRate / 100 : 0;
    this.amex_rate = amexRate ? amexRate / 100 : 0;
    this.transaction_fee = t.card.transaction_fee;
    this.chargeback_fee = t.card.chargeback_fee;
    this.retrieval_fee = t.card.retrieval_fee;
    this.reversal_fee = t.card.reversal_fee;
    this.account_monthly_fee = t.account_monthly_fee;
    this.account_monthly_minimum_fee = t.account_monthly_minimum_fee;
    // pass_through_fees is undocumented but will be present when applicable
    /* @ts-ignore */
    this.pass_through_fees = t.card.pass_through_fees;
  }
}

export class CardPresentPricingTemplateViewModel {
  public name: string;
  public currency: PricingTemplate.CurrencyEnum;
  public fee_type: CardChargeFeeTemplate.TransactionFeeTypeEnum;
  public visa_rate: number;
  public amex_rate: number;
  public transaction_fee: number;
  public chargeback_fee: number;
  public retrieval_fee: number;
  public reversal_fee: number;
  public account_monthly_fee: number;
  public account_monthly_minimum_fee: number;
  public bank_account_change_fee: number;
  public monthly_terminal_fee: number;
  public pass_through_fees: boolean;

  public constructor(t: PricingTemplate) {
    const visaRate = t.card_present.markups.find((m) => m.card_type === Markup.CardTypeEnum.VISA).rate;
    const amexRate = t.card_present.markups.find((m) => m.card_type === Markup.CardTypeEnum.AMEX).rate;
    this.name = t.name;
    this.currency = t.currency;
    this.fee_type = t.card_present.transaction_fee_type;
    this.visa_rate = visaRate ? visaRate / 100 : 0;
    this.amex_rate = amexRate ? amexRate / 100 : 0;
    this.transaction_fee = t.card_present.transaction_fee;
    this.chargeback_fee = t.card_present.chargeback_fee;
    this.retrieval_fee = t.card_present.retrieval_fee;
    this.reversal_fee = t.card_present.reversal_fee;
    this.account_monthly_fee = t.account_monthly_fee;
    this.account_monthly_minimum_fee = t.account_monthly_minimum_fee;
    this.bank_account_change_fee = t.card_present.bank_account_change_fee;
    this.monthly_terminal_fee = t.card_present.monthly_terminal_fee;
    // pass_through_fees is undocumented but will be present when applicable
    /* @ts-ignore */
    this.pass_through_fees = t.card_present.pass_through_fees;
  }
}

export class DebitPricingTemplateViewModel {
  public name: string;
  public currency: PricingTemplate.CurrencyEnum;
  public fee_type: CardChargeFeeTemplate.TransactionFeeTypeEnum;
  public transaction_fee: number;
  public return_fee: number;
  public account_monthly_fee: number;
  public account_monthly_minimum_fee: number;

  public constructor(t: PricingTemplate) {
    const debitDetails =
      t.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ? t.ach_debit : t.eft_debit;

    this.name = t.name;
    this.currency = t.currency;
    this.fee_type = debitDetails.transaction_fee_type;
    this.transaction_fee = debitDetails.transaction_fee;
    this.return_fee = debitDetails.return_fee;
    this.account_monthly_fee = t.account_monthly_fee;
    this.account_monthly_minimum_fee = t.account_monthly_minimum_fee;
  }
}
