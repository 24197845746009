import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectorTypes } from 'app/core/data/selector-types';
import { TilledInputComponent } from 'app/shared/form-fields/tilled-input/tilled-input.component';

@Component({
  selector: 'tilled-phone',
  standalone: true,
  imports: [TilledInputComponent],
  template: `
    <tilled-input
      data-test-id="contact-phone-number"
      class="w-full"
      name="phone"
      [prefix]="shouldShowPrefix() ? selectedPhoneCode : ''"
      [placeholder]="placeholder"
      [mask]="shouldShowPrefix() ? '(000) 000-0000' : '+0 (000) 000-0000'"
      [label]="label"
      tilledLabel="true"
      [errors]="errors"
      [errorMessage]="errorMessage"
      [prefixMultiple]="false"
      [prefixOptions]="phoneCodeMap"
      [prefixPlaceholder]="prefixPlaceholder"
      [prefixControlName]="phoneCodeControl"
      (prefixChange)="onPhoneCodeChanged($event)"
      (valueChange)="onValueChange($event)"
      (blur)="onTouched()"
    ></tilled-input>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TilledPhoneComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TilledPhoneComponent implements ControlValueAccessor {
  @Input() placeholder: string = '(555) 000-0000';
  @Input() label: string = 'Customer support phone number';
  @Input() errorMessage: string = 'Must be a valid phone number.';
  @Input() errors: boolean = false;
  @Input() prefixPlaceholder: string = 'US';
  public phoneCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.CountryToPhoneCode).map(
    ([label, value]) => ({ label, value }),
  );
  public selectedPhoneCode: string = this.phoneCodeMap[0]?.value || '+1';
  public phoneCodeControl: FormControl = new FormControl(this.selectedPhoneCode);

  public onTouched = () => {};
  private onChange = (_: any) => {};
  private _value: string = '';

  writeValue(value: string): void {
    this._value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  shouldShowPrefix(): boolean {
    return !(this._value && this._value.startsWith('+'));
  }

  onValueChange(value: string): void {
    if (this.shouldShowPrefix()) {
      this._value = `${this.selectedPhoneCode}${value}`;
    } else {
      this._value = value;
    }
    this.onChange(this._value);
  }

  onPhoneCodeChanged(newCode: string): void {
    const previousCode = this.selectedPhoneCode;
    this.selectedPhoneCode = newCode;
    this.phoneCodeControl.setValue(newCode, { emitEvent: false });
    if (this.shouldShowPrefix()) {
      let phoneWithoutPrefix = this._value;
      if (phoneWithoutPrefix.startsWith(previousCode)) {
        phoneWithoutPrefix = phoneWithoutPrefix.substring(previousCode.length);
      }
      this._value = `${this.selectedPhoneCode}${phoneWithoutPrefix}`;
      this.onChange(this._value);
    }
  }
}
