import { Pipe, PipeTransform } from '@angular/core';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import { EsignatureSigner } from '../../../../projects/tilled-api-client/src';

@Pipe({
  name: 'esignatureSignerStatus',
  standalone: true,
})
export class EsignatureSignerStatusPipe implements PipeTransform {
  transform(status: EsignatureSigner.StatusEnum, opaque: boolean = true): TilledChipConfig {
    const chipConfig: TilledChipConfig = {
      color: ChipColorClass.OPAQUE_ORANGE,
      text: status.toUpperCase(),
      toolTip: '',
    };
    switch (status) {
      case EsignatureSigner.StatusEnum.SENT:
      case EsignatureSigner.StatusEnum.VIEWED:
        chipConfig.color = opaque ? ChipColorClass.OPAQUE_ORANGE : ChipColorClass.SOLID_ORANGE;
        break;
      case EsignatureSigner.StatusEnum.COMPLETED:
        chipConfig.color = opaque ? ChipColorClass.OPAQUE_GREEN : ChipColorClass.SOLID_GREEN;
        break;
      default:
        chipConfig.color = opaque ? ChipColorClass.OPAQUE_ACCENT : ChipColorClass.SOLID_ACCENT;
        break;
    }
    return chipConfig;
  }
}
