/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';


export interface InternalBusinessRepresentative { 
    /**
     * Registered *residential* address for this principal
     */
    addresses: Array<Address>;
    bg_check_agreed_at?: string;
    bg_check_ip_address?: string;
    bg_check_legal_name?: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Date of birth.
     */
    date_of_birth: string;
    /**
     * The email address of the representative.
     */
    email?: string;
    /**
     * The representative\'s first name.
     */
    first_name?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * If this associate is the one completing the application.
     */
    is_applicant: boolean;
    /**
     * If this associate has a controlling interest.
     */
    is_control_prong: boolean;
    /**
     * The representative\'s title at the company.
     */
    job_title: string;
    /**
     * The representative\'s last name.
     */
    last_name?: string;
    /**
     * The representative\'s middle name.
     */
    middle_name?: string;
    /**
     * Percentage of the company they own.
     */
    percentage_shareholding: number;
    /**
     * The phone number for the representative.
     */
    phone: string;
    /**
     * Type of representative. Ex. shareholder, director, merchant rep, contact
     */
    type: InternalBusinessRepresentative.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace InternalBusinessRepresentative {
    export type TypeEnum = 'director' | 'shareholder' | 'merchant_rep' | 'contact';
    export const TypeEnum = {
        DIRECTOR: 'director' as TypeEnum,
        SHAREHOLDER: 'shareholder' as TypeEnum,
        MERCHANT_REP: 'merchant_rep' as TypeEnum,
        CONTACT: 'contact' as TypeEnum
    };
}


