/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Refund } from './refund';
import { PlatformFee } from './platformFee';
import { BalanceTransaction } from './balanceTransaction';


export interface Charge { 
    /**
     * Amount (in the smallest currency unit) captured. Can be less than the `amount` on the PaymentIntent if a partial capture was made.
     */
    amount_captured: number;
    /**
     * Amount (in the smallest currency unit) refunded. Can be less than the `amount` on the PaymentIntent if a partial refund was made.
     */
    amount_refunded: number;
    /**
     * The balance transaction that describes the impact of this charge on your account balance (not including refunds or disputes). Note: Multiple balance transactions may be associated with this resource and you can fetch them all with `/balance-transactions?source_id={id}`.
     * @deprecated
     */
    balance_transaction?: BalanceTransaction;
    /**
     * If the charge was created without capturing, this Boolean represents whether it is still uncaptured or has since been captured.
     */
    captured: boolean;
    /**
     * Time at which the charge was captured.
     */
    captured_at?: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * AVS and CVS checks and other payment details.
     */
    details?: object;
    /**
     * Time at which the charge failed.
     */
    failed_at?: string;
    /**
     * Error code explaining reason for charge failure.
     */
    failure_code?: Charge.FailureCodeEnum;
    /**
     * Message to user further explaining the reason for the charge failure.
     */
    failure_message?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Message to user explaining how to remedy a failure.
     */
    next_steps?: string;
    /**
     * The ID of the PaymentIntent associated with this charge.
     */
    payment_intent_id: string;
    /**
     * The id of the PaymentMethod at the time of the transaction.
     */
    payment_method_id: string;
    /**
     * The platform fee (if any) for the charge.
     */
    platform_fee?: PlatformFee;
    /**
     * Whether the charge has been fully refunded. If the charge is only partially refunded, this attribute will be false.
     */
    refunded: boolean;
    /**
     * A list of refunds that have been applied to the charge.
     */
    refunds: Array<Refund>;
    /**
     * Indicates whether the transaction was external.
     */
    source_type?: Charge.SourceTypeEnum;
    /**
     * The status of the payment.
     */
    status: Charge.StatusEnum;
    /**
     * Time at which the object was updated.
     */
    updated_at: string;
}
export namespace Charge {
    export type FailureCodeEnum = 'partial_authorization' | 'account_closed' | 'account_frozen' | 'account_number_invalid' | 'authorization_revoked' | 'avs_check_failed' | 'bank_account_blocked' | 'call_issuer' | 'card_not_supported' | 'expired_card' | 'fraudulent' | 'generic_decline' | 'incorrect_cvc' | 'insufficient_funds' | 'invalid_expiry' | 'invalid_field' | 'invalid_number' | 'limit_exceeded' | 'no_account' | 'not_authorized' | 'not_permitted' | 'processing_error' | 'not_recognized' | 'pickup_card' | 'restricted_card' | 'risk_blocked' | 'service_not_allowed' | 'stop_payment' | 'try_again_later' | 'authentication_required' | 'do_not_honor' | 'duplicate_transaction' | 'format_error' | 'incorrect_pin' | 'invalid_account' | 'invalid_address' | 'invalid_amount' | 'invalid_credentials' | 'invalid_merchant_account' | 'invalid_request' | 'invalid_token' | 'invalid_transaction' | 'invalid_zip' | 'issuer_not_available' | 'lost_card' | 'no_action_taken' | 'no_card' | 'pin_try_exceeded' | 'stolen_card' | 'terminal_authentication_required' | 'R01' | 'R02' | 'R03' | 'R04' | 'R05' | 'R06' | 'R07' | 'R08' | 'R09' | 'R10' | 'R11' | 'R12' | 'R13' | 'R14' | 'R15' | 'R16' | 'R17' | 'R18' | 'R19' | 'R20' | 'R21' | 'R22' | 'R23' | 'R24' | 'R25' | 'R26' | 'R27' | 'R28' | 'R29' | 'R30' | 'R31' | 'R32' | 'R33' | 'R34' | 'R35' | 'R36' | 'R37' | 'R38' | 'R39' | 'R40' | 'R41' | 'R42' | 'R43' | 'R44' | 'R45' | 'R46' | 'R47' | 'R50' | 'R51' | 'R52' | 'R53' | 'R61' | 'R62' | 'R63' | 'R64' | 'R65' | 'R66' | 'R67' | 'R68' | 'R69' | 'R70' | 'R71' | 'R72' | 'R73' | 'R74' | 'R75' | 'R76' | 'R77' | 'R80' | 'R81' | 'R82' | 'R83' | 'R84' | 'R85' | 'internal_error' | 'card_removed' | 'canceled' | 'device_is_busy';
    export const FailureCodeEnum = {
        PARTIAL_AUTHORIZATION: 'partial_authorization' as FailureCodeEnum,
        ACCOUNT_CLOSED: 'account_closed' as FailureCodeEnum,
        ACCOUNT_FROZEN: 'account_frozen' as FailureCodeEnum,
        ACCOUNT_NUMBER_INVALID: 'account_number_invalid' as FailureCodeEnum,
        AUTHORIZATION_REVOKED: 'authorization_revoked' as FailureCodeEnum,
        AVS_CHECK_FAILED: 'avs_check_failed' as FailureCodeEnum,
        BANK_ACCOUNT_BLOCKED: 'bank_account_blocked' as FailureCodeEnum,
        CALL_ISSUER: 'call_issuer' as FailureCodeEnum,
        CARD_NOT_SUPPORTED: 'card_not_supported' as FailureCodeEnum,
        EXPIRED_CARD: 'expired_card' as FailureCodeEnum,
        FRAUDULENT: 'fraudulent' as FailureCodeEnum,
        GENERIC_DECLINE: 'generic_decline' as FailureCodeEnum,
        INCORRECT_CVC: 'incorrect_cvc' as FailureCodeEnum,
        INSUFFICIENT_FUNDS: 'insufficient_funds' as FailureCodeEnum,
        INVALID_EXPIRY: 'invalid_expiry' as FailureCodeEnum,
        INVALID_FIELD: 'invalid_field' as FailureCodeEnum,
        INVALID_NUMBER: 'invalid_number' as FailureCodeEnum,
        LIMIT_EXCEEDED: 'limit_exceeded' as FailureCodeEnum,
        NO_ACCOUNT: 'no_account' as FailureCodeEnum,
        NOT_AUTHORIZED: 'not_authorized' as FailureCodeEnum,
        NOT_PERMITTED: 'not_permitted' as FailureCodeEnum,
        PROCESSING_ERROR: 'processing_error' as FailureCodeEnum,
        NOT_RECOGNIZED: 'not_recognized' as FailureCodeEnum,
        PICKUP_CARD: 'pickup_card' as FailureCodeEnum,
        RESTRICTED_CARD: 'restricted_card' as FailureCodeEnum,
        RISK_BLOCKED: 'risk_blocked' as FailureCodeEnum,
        SERVICE_NOT_ALLOWED: 'service_not_allowed' as FailureCodeEnum,
        STOP_PAYMENT: 'stop_payment' as FailureCodeEnum,
        TRY_AGAIN_LATER: 'try_again_later' as FailureCodeEnum,
        AUTHENTICATION_REQUIRED: 'authentication_required' as FailureCodeEnum,
        DO_NOT_HONOR: 'do_not_honor' as FailureCodeEnum,
        DUPLICATE_TRANSACTION: 'duplicate_transaction' as FailureCodeEnum,
        FORMAT_ERROR: 'format_error' as FailureCodeEnum,
        INCORRECT_PIN: 'incorrect_pin' as FailureCodeEnum,
        INVALID_ACCOUNT: 'invalid_account' as FailureCodeEnum,
        INVALID_ADDRESS: 'invalid_address' as FailureCodeEnum,
        INVALID_AMOUNT: 'invalid_amount' as FailureCodeEnum,
        INVALID_CREDENTIALS: 'invalid_credentials' as FailureCodeEnum,
        INVALID_MERCHANT_ACCOUNT: 'invalid_merchant_account' as FailureCodeEnum,
        INVALID_REQUEST: 'invalid_request' as FailureCodeEnum,
        INVALID_TOKEN: 'invalid_token' as FailureCodeEnum,
        INVALID_TRANSACTION: 'invalid_transaction' as FailureCodeEnum,
        INVALID_ZIP: 'invalid_zip' as FailureCodeEnum,
        ISSUER_NOT_AVAILABLE: 'issuer_not_available' as FailureCodeEnum,
        LOST_CARD: 'lost_card' as FailureCodeEnum,
        NO_ACTION_TAKEN: 'no_action_taken' as FailureCodeEnum,
        NO_CARD: 'no_card' as FailureCodeEnum,
        PIN_TRY_EXCEEDED: 'pin_try_exceeded' as FailureCodeEnum,
        STOLEN_CARD: 'stolen_card' as FailureCodeEnum,
        TERMINAL_AUTHENTICATION_REQUIRED: 'terminal_authentication_required' as FailureCodeEnum,
        R01: 'R01' as FailureCodeEnum,
        R02: 'R02' as FailureCodeEnum,
        R03: 'R03' as FailureCodeEnum,
        R04: 'R04' as FailureCodeEnum,
        R05: 'R05' as FailureCodeEnum,
        R06: 'R06' as FailureCodeEnum,
        R07: 'R07' as FailureCodeEnum,
        R08: 'R08' as FailureCodeEnum,
        R09: 'R09' as FailureCodeEnum,
        R10: 'R10' as FailureCodeEnum,
        R11: 'R11' as FailureCodeEnum,
        R12: 'R12' as FailureCodeEnum,
        R13: 'R13' as FailureCodeEnum,
        R14: 'R14' as FailureCodeEnum,
        R15: 'R15' as FailureCodeEnum,
        R16: 'R16' as FailureCodeEnum,
        R17: 'R17' as FailureCodeEnum,
        R18: 'R18' as FailureCodeEnum,
        R19: 'R19' as FailureCodeEnum,
        R20: 'R20' as FailureCodeEnum,
        R21: 'R21' as FailureCodeEnum,
        R22: 'R22' as FailureCodeEnum,
        R23: 'R23' as FailureCodeEnum,
        R24: 'R24' as FailureCodeEnum,
        R25: 'R25' as FailureCodeEnum,
        R26: 'R26' as FailureCodeEnum,
        R27: 'R27' as FailureCodeEnum,
        R28: 'R28' as FailureCodeEnum,
        R29: 'R29' as FailureCodeEnum,
        R30: 'R30' as FailureCodeEnum,
        R31: 'R31' as FailureCodeEnum,
        R32: 'R32' as FailureCodeEnum,
        R33: 'R33' as FailureCodeEnum,
        R34: 'R34' as FailureCodeEnum,
        R35: 'R35' as FailureCodeEnum,
        R36: 'R36' as FailureCodeEnum,
        R37: 'R37' as FailureCodeEnum,
        R38: 'R38' as FailureCodeEnum,
        R39: 'R39' as FailureCodeEnum,
        R40: 'R40' as FailureCodeEnum,
        R41: 'R41' as FailureCodeEnum,
        R42: 'R42' as FailureCodeEnum,
        R43: 'R43' as FailureCodeEnum,
        R44: 'R44' as FailureCodeEnum,
        R45: 'R45' as FailureCodeEnum,
        R46: 'R46' as FailureCodeEnum,
        R47: 'R47' as FailureCodeEnum,
        R50: 'R50' as FailureCodeEnum,
        R51: 'R51' as FailureCodeEnum,
        R52: 'R52' as FailureCodeEnum,
        R53: 'R53' as FailureCodeEnum,
        R61: 'R61' as FailureCodeEnum,
        R62: 'R62' as FailureCodeEnum,
        R63: 'R63' as FailureCodeEnum,
        R64: 'R64' as FailureCodeEnum,
        R65: 'R65' as FailureCodeEnum,
        R66: 'R66' as FailureCodeEnum,
        R67: 'R67' as FailureCodeEnum,
        R68: 'R68' as FailureCodeEnum,
        R69: 'R69' as FailureCodeEnum,
        R70: 'R70' as FailureCodeEnum,
        R71: 'R71' as FailureCodeEnum,
        R72: 'R72' as FailureCodeEnum,
        R73: 'R73' as FailureCodeEnum,
        R74: 'R74' as FailureCodeEnum,
        R75: 'R75' as FailureCodeEnum,
        R76: 'R76' as FailureCodeEnum,
        R77: 'R77' as FailureCodeEnum,
        R80: 'R80' as FailureCodeEnum,
        R81: 'R81' as FailureCodeEnum,
        R82: 'R82' as FailureCodeEnum,
        R83: 'R83' as FailureCodeEnum,
        R84: 'R84' as FailureCodeEnum,
        R85: 'R85' as FailureCodeEnum,
        INTERNAL_ERROR: 'internal_error' as FailureCodeEnum,
        CARD_REMOVED: 'card_removed' as FailureCodeEnum,
        CANCELED: 'canceled' as FailureCodeEnum,
        DEVICE_IS_BUSY: 'device_is_busy' as FailureCodeEnum
    };
    export type SourceTypeEnum = 'external';
    export const SourceTypeEnum = {
        EXTERNAL: 'external' as SourceTypeEnum
    };
    export type StatusEnum = 'succeeded' | 'pending' | 'failed';
    export const StatusEnum = {
        SUCCEEDED: 'succeeded' as StatusEnum,
        PENDING: 'pending' as StatusEnum,
        FAILED: 'failed' as StatusEnum
    };
}


