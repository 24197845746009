import { environment } from 'environments/environment';

export const AUTH_LOGIN_ROUTE = 'sign-in';
export const HOMEPAGE_ROUTE = 'dashboard';
export const MERCHANTS_ROUTE = 'merchants';
export const SOFTWARE_PLATFORMS_ROUTE = 'software-platforms';
export const PAYMENTS_ROUTE = 'payments';
export const TERMINALS_ROUTE = 'terminals';
export const PAYOUTS_ROUTE = 'payouts';
export const OUTBOUND_TRANSFERS_ROUTE = 'outbound-transfers';
export const DEFAULT_PAGE_LIMIT = 25;
export const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 100];
export const USE_PAYSAFE_SANDBOX = environment.env === 'prod' ? false : true;
export const CARD_TERMS_LINK_NAME = 'Card Terms and Conditions';
export const PORTAL_TERMS_LINK_NAME = 'Portal Terms and Conditions';
export const ACH_DEBIT_TERMS_LINK_NAME = 'ACH Debit Terms and Conditions';
export const PRIVACY_POLICY_LINK_NAME = 'Privacy Policy';
export const TILLED_APP_BOX_LINK =
  environment.env === 'prod' ? 'https://tilled.app.box.com/f/5d654157d43d4d879bb2c5cf52f173c5' : '';
export const ULD_TABLE_NAME = 'user_local_data';
export const RECENT_MERCHANTS = 'recent_merchants';
export const USER_LOCAL_DATA = 'user_local_data';
