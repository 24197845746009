/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EsignatureSigner { 
    /**
     * The email of the Esignature Signer.
     */
    email: string;
    /**
     * Whether or not the Esignature Signer is signing an embedded document (other Esignature Signers will sign via email).
     */
    embedded: boolean;
    /**
     * Unique identifier for corresponding Esignature Document of the Esignature Signer.
     */
    esignature_document_id: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The name of the Esignature Signer.
     */
    name: string;
    /**
     * The status of the Esignature Signer.
     */
    status: EsignatureSigner.StatusEnum;
}
export namespace EsignatureSigner {
    export type StatusEnum = 'sent' | 'viewed' | 'completed';
    export const StatusEnum = {
        SENT: 'sent' as StatusEnum,
        VIEWED: 'viewed' as StatusEnum,
        COMPLETED: 'completed' as StatusEnum
    };
}


