<mat-form-field
  class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-64 md:min-w-56"
  [style]="styles"
  [floatLabel]="'always'"
>
  <ng-container *ngIf="icon"><mat-icon matPrefix [svgIcon]="'mat_outline:search'"></mat-icon></ng-container>
  <span *ngIf="textPrefix" class="text-tilled-secondary-400">{{ textPrefix }}&nbsp;</span>
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="controlName"
    class="mat-tooltip-trigger text-secondary text-p3"
    [matTooltip]="toolTip"
    mask="mask"
    thousandSeparator="thousandSeparator"
  />
  <button *ngIf="displayClear && showClear" mat-icon-button aria-label="Clear" (click)="clearSearchValue()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
