import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteUserInvitationRequestParams,
  InternalAccount,
  ListUserInvitationsRequestParams,
  ListUsersRequestParams,
  ResendUserInvitationRequestParams,
  UpdateUserRequestParams,
  User,
  UserInvitation,
} from '@tilled-api-client';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import { ChipColorClass, TilledChipConfig } from 'app/shared/tilled-chip/tilled-chip.component';
import {
  IRevokeAccessDialogComponentData,
  IRevokeAccessDialogComponentResponse,
  RevokeAccessDialogComponent,
} from 'app/shared/user/revoke-access-dialog/revoke-access-dialog.component';
import moment from 'moment';
import { Observable, Subject, Subscription, combineLatest, concat, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UserRoles } from '../../core/data/user-roles';
import { ActionListItem } from '../action-list/action-list.model';
import { Column } from '../tilled-table/decorators/column';
import { TilledTableComponent } from '../tilled-table/tilled-table.component';
import { UserInviteDialogComponent } from './user-dialog/user-invite-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, CommonModule],
})
export class UserListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() account: InternalAccount;

  public users$: Observable<User[]>;
  public usersViewModel$: Observable<UserViewModel[]>;
  public usersCount$: Observable<number>;
  public userInvitations$: Observable<UserInvitation[]>;
  public userInvitesViewModel$: Observable<UserViewModel[]>;
  public userInvitationsCount$: Observable<number>;
  public usersAndInvitesViewModel$: Observable<UserViewModel[]>;

  public isLoading = true;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public sortInfo = null;
  public isMerchantUser = false;
  public isResellerUser = false;
  public userScopes: {
    user_invitations: {
      read: boolean;
      write: boolean;
    };
    users: {
      read: boolean;
      write: boolean;
    };
  };

  private subscriptions: Subscription[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _usersAppService: UsersAppService,
    private _matDialog: MatDialog,
    private _dateFormatPipe: DateFormatPipe,
    private _authService: AuthService,
    private _clipboard: Clipboard,
    private _alertService: AlertService,
  ) {
    this.checkScopes();
  }

  async ngOnInit(): Promise<void> {
    this.users$ = this._usersAppService.users$;
    this.usersCount$ = this._usersAppService.usersCount$;
    this.userInvitations$ = this._usersAppService.userInvitations$;
    this.userInvitationsCount$ = this._usersAppService.userInvitationsCount$;

    this.checkScopes();
    this.usersViewModel$ = this.users$.pipe(map((users) => this.getViewModelsFromUsers(users)));
    this.userInvitesViewModel$ = this.userInvitations$.pipe(
      map((invites) => this.getViewModelsFromUserInvitations(invites)),
    );

    if (this.userScopes.user_invitations.read) {
      this.subscriptions.push(
        combineLatest([this.usersViewModel$, this.userInvitesViewModel$]).subscribe(([users, userInvites]) => {
          const params = {
            users: users,
            userInvites: userInvites,
          };
          this.usersAndInvitesViewModel$ = of(this.combineViewModels(params.users, params.userInvites));
        }),
      );
    } else {
      this.usersAndInvitesViewModel$ = this.usersViewModel$;
    }

    this.getUsersAndInvites(this.pageSize, this.pageIndex);

    this.isMerchantUser = this._authService.isMerchantUser();
    this.isResellerUser = this._authService.isResellerUser(this.account.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkScopes();
    if (changes.account && !changes.account.firstChange) {
      this.getUsersAndInvites(this.pageSize, this.pageIndex);
    }
  }

  checkScopes(): void {
    this.userScopes = {
      user_invitations: {
        read: this._authService.isScopeAble('user_invitations:read'),
        write: this._authService.isScopeAble('user_invitations:write'),
      },
      users: {
        read: this._authService.isScopeAble('users:read'),
        write: this._authService.isScopeAble('users:write'),
      },
    };
  }

  ngOnDestroy(): void {
    this._usersAppService.unsubscribeFromUserService();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getUsersAndInvites = (size: number, index: number): void => {
    this.getUsers(size, index);
    this.getUserInvitations(size, index);
  };

  getUsers = (size: number, index: number): void => {
    const usersParams: ListUsersRequestParams = {
      tilledAccount: this.account.id,
      offset: size * index,
      limit: size,
      includeConnectedAccounts: false,
    };
    this._usersAppService.getAllUsers(usersParams);
  };

  getUserInvitations = (size: number, index: number): void => {
    const usersParams: ListUserInvitationsRequestParams = {
      tilledAccount: this.account.id,
      offset: size * index,
      limit: size,
    };
    if (this.userScopes.user_invitations.read) {
      this._usersAppService.getAllUserInvitations(usersParams);
    } else {
      const viewModels: UserViewModel[] = [];
      this.userInvitesViewModel$ = of(viewModels);
      this.userInvitationsCount$ = of(0);
    }
  };

  getViewModelsFromUsers(users: User[]): UserViewModel[] {
    const viewModels: UserViewModel[] = [];
    if (!users || users.length === 0) {
      const temp: UserViewModel = new UserViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    let active: boolean = true;
    for (const user of users) {
      let lastLogin = 'Never';
      if (user.last_login_at) {
        lastLogin = this._dateFormatPipe.transform(user.last_login_at);
      }

      if ((user as any)?.deleted_at) {
        active = false;
      }

      const temp: UserViewModel = new UserViewModel();
      temp.created_at = user.created_at;
      temp.name = user?.name;
      temp.email = user?.email;
      temp.last_login = lastLogin;
      temp.status_chip_config = this.tilledChipConfig(
        active ? 'ACTIVE' : 'ACCESS_REVOKED',
        active ? user.created_at : (user as any)?.deleted_at,
      );

      const role = user.account_roles?.find((au) => au.account_id === this.account.id)?.role || user.role;
      const merchantRoles = [User.RoleEnum.MERCHANT_ADMIN, User.RoleEnum.MERCHANT_OWNER];
      temp.role = UserRoles.DisplayText.get(role);
      temp.actionList = [];
      let editDisabled = false;
      if (
        role === User.RoleEnum.OWNER ||
        role === User.RoleEnum.RESELLER_OWNER ||
        merchantRoles.includes(role) ||
        !this._authService.isScopeAble('users:write')
      ) {
        editDisabled = true;
      }

      let deleteDisabled = false;
      if (role === User.RoleEnum.OWNER || role === User.RoleEnum.RESELLER_OWNER || !this.userScopes.users.write) {
        deleteDisabled = true;
      }

      // we are no longer showing roles for merchant users, so there is nothing to edit
      if (!this.isMerchantUser)
        temp.actionList.push(
          new ActionListItem({
            name: 'Edit role',
            callback: (): void => {
              this.editUser(user);
            },
            disabled: editDisabled,
          }),
        );
      temp.actionList.push(
        new ActionListItem({
          name: 'Revoke access',
          callback: (): void => {
            this.deleteUser(user);
          },
          disabled: deleteDisabled,
        }),
      );

      viewModels.push(temp);
    }
    return viewModels;
  }

  getViewModelsFromUserInvitations(userInvitations: UserInvitation[]): UserViewModel[] {
    const viewModels: UserViewModel[] = [];
    if (!userInvitations || userInvitations.length === 0) {
      let temp: UserViewModel = new UserViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    let expired: boolean = false;
    const currentTimeUTC = new Date().toISOString();

    for (const userInv of userInvitations) {
      if (userInv.expires_at < currentTimeUTC) {
        expired = true;
      }

      let temp: UserViewModel = new UserViewModel();
      temp.created_at = userInv.created_at;
      temp.name = '-';
      temp.email = userInv.email;
      temp.role = UserRoles.DisplayText.get(userInv.role);
      temp.last_login = 'Never';
      temp.status_chip_config = this.tilledChipConfig(
        expired ? 'INVITE_EXPIRED' : 'INVITE_PENDING',
        userInv.expires_at,
      );

      temp.actionList = [];
      let disabled = false;
      if (
        userInv.role === UserInvitation.RoleEnum.OWNER ||
        userInv.role === UserInvitation.RoleEnum.RESELLER_OWNER ||
        !this.userScopes.user_invitations.write
      ) {
        disabled = true;
      }
      temp.actionList.push(
        new ActionListItem({
          name: 'Resend Invitation',
          callback: (): void => {
            this.resendUserInvite(userInv);
          },
          disabled: disabled,
        }),
      );
      temp.actionList.push(
        new ActionListItem({
          name: 'Copy Invitation URL',
          callback: (): void => {
            this._clipboard.copy(userInv.invitation_url);
            const message: TilledAlert = {
              message: `User invitation for ${userInv.email} was copied`,
              title: 'User invitation link copied',
              type: 'success',
              timer: 8000,
            };
            this._alertService.showAlert(message);
          },
          disabled: disabled || expired,
        }),
      );
      temp.actionList.push(
        new ActionListItem({
          name: 'Delete Invitation',
          callback: (): void => {
            this.deleteUserInvite(userInv);
          },
          disabled: disabled,
        }),
      );

      viewModels.push(temp);
    }
    return viewModels;
  }

  combineViewModels = (users: UserViewModel[], userInvites: UserViewModel[]): UserViewModel[] => {
    const viewModels: UserViewModel[] = [];
    viewModels.push(...users?.filter((u) => u?.created_at));
    viewModels.push(...userInvites?.filter((ui) => ui?.created_at));
    viewModels.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    if (viewModels.length === 0) {
      viewModels.push(new UserViewModel());
    }
    return viewModels;
  };

  deleteUser = (user: User): void => {
    const data: IRevokeAccessDialogComponentData = {
      user: user,
      accountId: this.account.id,
      accountName: this.account.name,
    };

    const dialogRef = this._matDialog.open(RevokeAccessDialogComponent, {
      data,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: IRevokeAccessDialogComponentResponse) => {
        if (response?.revokeAccountIds.length > 0) {
          const observables: Observable<void>[] = [];
          for (const accountId of response.revokeAccountIds) {
            // TODO MCD-504 Maybe send one alert instead of multiple alerts.
            observables.push(
              this._usersAppService.deleteUser({
                tilledAccount: accountId,
                id: user.id,
              }),
            );
          }
          console.log(`Trying to execute ${observables.length} delete commands.`);
          // Ensure that they run sequentially for now.
          // And not all at once - since we don't have locking on the user at this time.
          concat(...observables).subscribe();
        }
      });
  };

  editUser = (userData: User): void => {
    const merchantRoles = [User.RoleEnum.MERCHANT_ADMIN, User.RoleEnum.MERCHANT_OWNER];
    const dialogRef = this._matDialog.open(UserInviteDialogComponent, {
      panelClass: 'user-invite-dialog',
      data: {
        action: 'edit',
        user: this.getViewModelsFromUsers([userData])[0],
        isMerchantUser: merchantRoles.includes(userData.account_roles[0].role) || this.isMerchantUser,
        isResellerUser: this.isResellerUser,
      },
    });

    dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      const params: UpdateUserRequestParams = {
        tilledAccount: this.account.id,
        id: userData.id,
        userUpdateParams: {
          role: response.getRawValue().role,
        },
      };

      this._usersAppService.updateUser(params);
    });
  };

  deleteUserInvite = (userData: UserInvitation): void => {
    const params: DeleteUserInvitationRequestParams = {
      tilledAccount: this.account.id,
      id: userData.id,
    };
    this._usersAppService.deleteUserInvitation(params);
  };

  resendUserInvite = (userData: UserInvitation): void => {
    const params: ResendUserInvitationRequestParams = {
      tilledAccount: this.account.id,
      id: userData.id,
    };
    this._usersAppService.resendUserInvitation(params);
  };

  tilledChipConfig(
    status: 'ACTIVE' | 'ACCESS_REVOKED' | 'INVITE_EXPIRED' | 'INVITE_PENDING',
    date: string,
  ): TilledChipConfig {
    let chip: TilledChipConfig = {
      text: '',
      toolTip: '',
      color: ChipColorClass.OPAQUE_NEUTRAL,
    };

    const tz = moment(date).tz(moment.tz.guess());
    const formattedDate = tz.format('MMM DD, YYYY hh:mm A z');

    switch (status) {
      case 'ACTIVE':
        chip.text = 'Active';
        chip.color = ChipColorClass.OPAQUE_GREEN;
        chip.toolTip = `User active since:\n${formattedDate}`;
        break;
      case 'ACCESS_REVOKED':
        break;
      case 'INVITE_EXPIRED':
        chip.text = 'Invite Expired';
        chip.color = ChipColorClass.OPAQUE_RED;
        chip.toolTip = `Invite expired on:\n${formattedDate}`;
        break;
      case 'INVITE_PENDING':
        chip.text = 'Invite Pending';
        chip.color = ChipColorClass.OPAQUE_YELLOW;
        chip.toolTip = `Invite will expire on:\n${formattedDate}`;
        break;
    }
    return chip;
  }
}
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
export class UserViewModel {
  created_at: string;

  @Column({
    order: 0,
    name: 'Name',
    styling: 'max-width: 110px !important;',
  })
  name: string;

  @Column({
    order: 1,
    name: 'Email',
  })
  email: string;

  @Column({
    order: 2,
    name: 'Role',
  })
  role: string;

  @Column({
    order: 3,
    name: 'Last Login',
    dateTooltip: true,
  })
  last_login: string;

  @Column({
    order: 4,
    name: 'Status',
    isChip: true,
    chipConfig: 'status_chip_config',
  })
  status: string;
  status_chip_config: TilledChipConfig;

  @Column({
    order: 5,
    isActionList: true,
    styling: 'padding-right: 42px;',
  })
  actionList: ActionListItem[];
}
