<div class="w-full pb-3 pt-6" *ngIf="labelName">
  <tilled-label-l1 secondary="true">{{ labelName }}</tilled-label-l1>
</div>
<form [formGroup]="searchForm">
  <mat-form-field
    [class]="
      additionalClasses === 'w-full'
        ? 'fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded' + ' ' + additionalClasses
        : 'fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded sm:min-w-60' + ' ' + additionalClasses
    "
    [floatLabel]="'always'"
    [appearance]="inputAppearance"
  >
    <mat-icon
      [ngClass]="{
        'mr-2 icon-size-4': inputAppearance === 'outline'
      }"
      [svgIcon]="'mat_outline:search'"
    ></mat-icon>

    <input
      data-test-id="partner-selector"
      [placeholder]="'Search for account'"
      #partnerSelectorInput
      matInput
      [formControlName]="'searchInput'"
      [matAutocomplete]="auto"
      (focus)="onFocus()"
      class="text-secondary text-p3"
    />

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="partnerSelected($event)">
      <div
        *ngIf="partnerSelectorInput.value.length > 2"
        class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-56"
      >
        <mat-option class="multiline-mat-option" *ngFor="let partner of filteredPartners$ | async" [value]="partner">
          <div
            class="flex flex-row justify-between py-1"
            matTooltipPosition="right"
            [matTooltip]="partnerTooltip(partner.id, partner.business_profile?.representatives[0]?.first_name)"
          >
            <div class="flex flex-row items-center">
              <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:search'"></mat-icon>
              <tilled-paragraph-p3
                [innerHTML]="partner.name | matchingStringToBold: partnerSelectorInput.value | truncateString: 20"
              ></tilled-paragraph-p3>
            </div>
            <tilled-paragraph-p3 italics="true">{{
              partner.business_profile?.address?.street | truncateString: 20
            }}</tilled-paragraph-p3>
          </div>
        </mat-option>
      </div>

      <div *ngIf="showRecentPartners" class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded min-w-56">
        <mat-option
          class="multiline-mat-option"
          *ngFor="let partner of recentlyViewedPartners$ | async"
          [value]="partner"
        >
          <div
            class="flex flex-row content-center justify-between py-1"
            matTooltipPosition="right"
            [matTooltip]="partnerTooltip(partner.account_id, partner.owner || {})"
          >
            <div class="flex flex-row content-center justify-start">
              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:clock'"></mat-icon>
              <tilled-paragraph-p3>{{ partner.name | truncateString: 20 }}</tilled-paragraph-p3>
            </div>
            <div class="flex flex-row content-center justify-end">
              <tilled-paragraph-p3 italics="true">{{ partner.street | truncateString: 20 }}</tilled-paragraph-p3>
              <button
                class="focus:shadow-outline ml-2 inline-flex h-6 w-6 items-center justify-center rounded-full pb-1"
                (click)="$event.stopImmediatePropagation(); deleteRecentPartner(partner.account_id)"
              >
                <mat-icon class="ml-4 mt-1 icon-size-5" [svgIcon]="'mat_outline:close'"></mat-icon>
              </button>
            </div>
          </div>
        </mat-option>
      </div>
    </mat-autocomplete>

    <button
      data-test-id="partner-selector-clear-Btn"
      *ngIf="showClear"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSearchValue(); clearSearchText()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>
