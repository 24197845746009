import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { AccountStatusPipe } from 'app/core/pipes/account-status.pipe';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  AccountCapability,
  InternalAccount,
  InternalListConnectedAccountsRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { AccountAppService } from '../../core/services/account.app.service';
import { ChipColorClass, TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';
import { TilledTableComponent } from '../tilled-table/tilled-table.component';

@Component({
  selector: 'app-connected-account-list',
  templateUrl: './connected-account-list.component.html',
  styleUrls: ['./connected-account-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, AsyncPipe],
})
export class ConnectedAccountListComponent extends ComponentBase implements OnInit {
  @Input() accountId: string;
  @Input() query: string = null;
  @Input() capabilityStatuses$: Observable<any>;
  @Input() accountStatuses$: Observable<any>;
  @Input() accountDetailsPage: boolean = false;
  @Input() isReseller: boolean = false;

  public connectedAccounts$: Observable<InternalAccount[]>;
  public connectedAccountsCount$: Observable<number>;
  public viewModels$: Observable<MerchantAccountViewModel[]>;
  public hideColumnKeys: MerchantListViewModel[] = [];
  public loading$ = new BehaviorSubject<boolean>(true);
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public secondaryReasonText = 'Add a new merchant to see merchant data.';
  public capabilityStatuses: AccountCapability.StatusEnum[] | null;
  public accountStatuses: InternalAccount.StatusEnum[] | null;

  constructor(
    private _accountService: AccountAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _statusPipe: AccountStatusPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.accountDetailsPage) {
      this.hideColumnKeys = ['application_status', 'app_status_chip_config', 'partner_name'];
    } else {
      this.hideColumnKeys = ['account_status', 'acct_status_chip_config'];
      if (!this.isReseller) {
        this.hideColumnKeys.push('partner_name');
      }
    }
    this.connectedAccounts$ = this._accountService.connectedAccountsPaginated$;
    // Ensure that we are not displaying partner accounts during the initial load (only applicable to resellers)
    this.connectedAccounts$.pipe(takeUntil(this._unsubscribeAll)).subscribe((accounts) => {
      if (accounts) {
        accounts.find((account) => account.type === InternalAccount.TypeEnum.MERCHANT)
          ? this.loading$.next(false)
          : this.loading$.next(true);
      }
    });
    this.connectedAccountsCount$ = this._accountService.connectedAccountsCount$;
    this.capabilityStatuses$?.pipe(takeUntil(this._unsubscribeAll)).subscribe((statuses) => {
      this.capabilityStatuses = statuses;
    });
    this.accountStatuses$?.pipe(takeUntil(this._unsubscribeAll)).subscribe((statuses) => {
      this.accountStatuses = statuses;
    });

    this.viewModels$ = this.connectedAccounts$.pipe(
      map((accounts) => this.getViewModelsFromConnectedAccounts(accounts)),
    );
    this.connectedAccountsCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      if (res === 0) {
        if (this.query) {
          this.secondaryReasonText = 'Update the merchant filter to see results.';
        } else {
          this.secondaryReasonText = 'Add a new merchant to see merchant data.';
        }
      }
    });

    this.getConnectedAccounts(this.pageIndex, this.pageSize);
  }

  getConnectedAccounts = (size: number, index: number, sort?: string): void => {
    const params: InternalListConnectedAccountsRequestParams = {
      tilledAccount: this.accountId,
      offset: index * size,
      limit: size,
      sort: sort,
      type: InternalAccount.TypeEnum.MERCHANT,
      q: this.query,
      capabilityStatus: this.capabilityStatuses,
      status: this.accountStatuses,
    };
    this._accountService.loadConnectedAccounts(params);
  };

  getViewModelsFromConnectedAccounts(accounts: InternalAccount[]): MerchantAccountViewModel[] {
    const viewModels: MerchantAccountViewModel[] = [];
    if (!accounts || accounts.length === 0) {
      const temp: MerchantAccountViewModel = new MerchantAccountViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const account of accounts) {
      const temp: MerchantAccountViewModel = new MerchantAccountViewModel();
      temp.id = account.id;
      temp.name = account.name;
      temp.partner_name = account?.partner_account_name;

      if (this.accountDetailsPage) {
        temp.account_status = this._statusPipe.getAccountStatusChipConfig(account, false).text;
        temp.acct_status_chip_config = this._statusPipe.getAccountStatusChipConfig(account, false);
      }
      if (account.capabilities?.length > 0) {
        temp.application_status = this._statusPipe.getUnifiedStatus(account.capabilities);
        temp.app_status_chip_config = this._statusPipe.transform(account, false);
        temp.progress =
          temp.application_status === AccountCapability.StatusEnum.ACTIVE
            ? 100
            : account.capabilities[0].onboarding_application_progress;
        if (
          temp.application_status === AccountCapability.StatusEnum.CREATED ||
          temp.application_status === AccountCapability.StatusEnum.STARTED
        ) {
          temp.accountIdForInvitation = account.id;
        }
      } else {
        temp.app_status_chip_config = {
          color: ChipColorClass.OPAQUE_RED,
          text: 'REQUIRES PRICING',
          toolTip: '',
          icon: 'heroicons_outline:no-symbol',
        };
        temp.progress = 0;
        temp.accountIdForInvitation = '';
      }
      temp.created_at = this._dateFormatPipe.transform(account.created_at);

      viewModels.push(temp);
    }
    this.loading$.next(false);
    return viewModels;
  }

  rowClickedCallback = (data: MerchantAccountViewModel): void => {
    if (data && data.id) {
      let path = '';
      path += `/merchants/${data.id}`;
      this._router.navigate([path]);
    }
  };
}
type MerchantListViewModel = keyof MerchantAccountViewModel;
export class MerchantAccountViewModel {
  id: string;

  @Column({
    order: 0,
    name: 'Created Date',
    canSort: true,
    dateTooltip: true,
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Software Platform',
    styling: 'padding-right: 2ch;',
  })
  partner_name: string;

  @Column({
    order: 2,
    name: 'Name',
    canSort: true,
    styling: 'padding-right: 2ch; width: 20ch; max-width: 20ch;',
  })
  name: string;

  @Column({
    order: 3,
    name: 'Application Status',
    isChip: true,
    chipConfig: 'app_status_chip_config',
  })
  application_status: string;
  app_status_chip_config: TilledChipConfig;

  // Only used for `accountDetailsPage` view (see hideColumnKeys)
  @Column({
    order: 3, // Keep as 3, since it replaces the `Application Status` column
    name: 'Status',
    isChip: true,
    chipConfig: 'acct_status_chip_config',
  })
  account_status: string;
  acct_status_chip_config: TilledChipConfig;

  @Column({
    order: 4,
    name: 'Application Progress',
    isProgress: true,
  })
  progress: number;

  @Column({
    order: 5,
    isShareLink: true,
  })
  accountIdForInvitation: string;

  @Column({
    order: 6,
    isAction: true,
  })
  action: string;
}
