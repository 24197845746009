<div class="top-of-form"></div>
<merchant-app-card
  [title]="title || null"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
  [bypassHalfWidth]="true"
  [backButton]="false"
>
  <div class="w-full pb-4" [ngClass]="{ '-mt-5': !forConsole }">
    <tilled-paragraph-p3 [secondary]="true" *ngIf="!busOwnerStep">
      The application signer should be an owner, executive, senior manager or someone who otherwise has significant
      responsibility for the control and management of your business.
      {{
        hasTsysProvider || hasNorthProvider
          ? 'The designated application signer will be responsible for digitally signing the application on the last step. '
          : ' '
      }}
      If that's not you, please
      <span class="font-semibold"
        >invite the right person to complete this form{{
          hasTsysProvider || hasNorthProvider ? ' and digitally sign the application.' : '.'
        }}
      </span>
    </tilled-paragraph-p3>
    <ng-container *ngIf="busOwnerStep && secondaryText">
      <tilled-paragraph-p3 [secondary]="true" [styles]="'flex flex-col'">
        {{ secondaryText }}
        <span *ngIf="!hasNorthProvider" class="mt-6 font-semibold text-tilled-primary-500">
          Please add any individual who owns 25% or more of
          {{ merchantApp?.legal_entity?.legal_name || 'your business' }}
        </span>
        <div *ngIf="hasNorthProvider" class="mt-4">
          <span class="font-semibold text-tilled-primary-500">
            Add any individuals with significant ownership of
            {{ merchantApp?.legal_entity?.legal_name || 'your business' }}
            to reach at least 75% total ownership
          </span>
          <div class="mt-4">
            <tilled-paragraph-p3
              >Owners with more than 25% ownership are required to digitally sign the merchant application.<mat-icon
                class="ml-2 pt-0.5 text-tilled-primary icon-size-4"
                [svgIcon]="'mat_outline:help_outline'"
                matTooltip="Once the application is ready to be submitted, all owners with more than 25% ownership will receive an email from Docusign to digitally sign the application. The application is not considered fully submitted until these owners have signed."
              ></mat-icon
            ></tilled-paragraph-p3>
          </div>
        </div>
        <div class="flex pt-4" *ngIf="hasNorthProvider && totalOwnership <= 75">
          <mat-icon class="mr-1 mt-0.5 text-warn icon-size-4" [svgIcon]="'mat_solid:error'"></mat-icon>
          <tilled-paragraph-p3 [noColor]="true" class="text-warn" [styles]="'flex flex-wrap text-md'">
            Total ownership must be above 75%
          </tilled-paragraph-p3>
        </div>
      </tilled-paragraph-p3>
    </ng-container>
  </div>
  <!-- used for hidden element to define button width -->
  <form [formGroup]="hiddenForm" class="invisible -mb-13 w-full opacity-0">
    <tilled-input name="hidden" class="pointer-events-none flex"> </tilled-input>
  </form>
  <div data-test-id="representatives-step" class="w-full" [ngClass]="{ '-mb-5': !busOwnerStep, 'mb-2': busOwnerStep }">
    <div *ngIf="!businessEntityType" class="pb-6">
      <merchant-app-alert
        data-test-id="businessTypeAlert"
        alertName="businessTypeWarnAlertBox"
        title="Business type not set"
        description="A business type must be set on the Business Details page before adding
                representatives"
        (editClick)="onEditClicked($event)"
      ></merchant-app-alert>
    </div>
    <div *ngIf="representativesFormArray?.invalid && representativesFormArray?.touched" class="pb-6">
      <merchant-app-alert
        data-test-id="NoRepresentativesAlert"
        alertName="noRepresentativesAlertBox"
        title="No application signer"
        description="At least one application signer is required."
        (editClick)="onEditClicked($event)"
      ></merchant-app-alert>
    </div>
    <div *ngFor="let group of representativesFormArray.controls; let i = index" [formGroup]="group" class="my-3">
      <div
        *ngIf="
          (this.busOwnerStep &&
            group?.get('id')?.value &&
            (group?.get('percentageShareholding')?.value > 0 || !group?.get('isApplicant')?.value)) ||
          (!this.busOwnerStep && group?.get('isApplicant')?.value)
        "
        class="edit-rep-wrapper flex items-center self-center rounded-md"
        [ngClass]="{
          'tilled-gradient': !(isWhiteLabel$ | async)
        }"
      >
        <div
          class="m-[0.5px] flex w-full rounded-md bg-white p-3"
          [ngClass]="{
            'border-[1.5px] border-black': (isWhiteLabel$ | async)
          }"
        >
          <div class="flex w-full flex-col space-y-0.5">
            <tilled-paragraph-p3 class="mr-1.5 whitespace-nowrap font-semibold text-tilled-primary">
              {{ group?.get('firstName')?.value }} {{ group?.get('middleName')?.value }}
              {{ group?.get('lastName')?.value }}
            </tilled-paragraph-p3>
            <tilled-paragraph-p3 class="mr-1.5 whitespace-nowrap text-tilled-primary">
              <ng-container *ngIf="group?.get('jobTitle')?.value || group?.get('email')?.value">
                {{ group?.get('jobTitle')?.value | snakeCaseSplit | titlecase
                }}{{ group?.get('email')?.value ? ', ' + group?.get('email')?.value : '' }}
              </ng-container>
              <ng-container *ngIf="busOwnerStep">
                <div class="pt-0.5">{{ group?.get('percentageShareholding')?.value || '0' }}% ownership</div>
              </ng-container>
            </tilled-paragraph-p3>
            <ng-container *ngIf="group?.get('displayMissingFields')?.value">
              <div class="flex pt-4">
                <mat-icon class="mr-1 mt-0.5 text-warn icon-size-4" [svgIcon]="'mat_solid:error'"></mat-icon>
                <tilled-paragraph-p3 [noColor]="true" class="text-warn" [styles]="'flex flex-wrap text-md'">
                  Missing fields:
                  <ng-container *ngFor="let field of missingFields; trackBy: trackByFn">
                    <ng-container *ngIf="group?.get('id')?.value === field.id && (field.error || field.warning)">
                      {{ field.name }}{{ !field.last ? ', ' : '' }}
                    </ng-container>
                  </ng-container>
                </tilled-paragraph-p3>
              </div>
            </ng-container>
          </div>
          <div
            data-test-id="representative-step-edit-representative"
            class="edit-rep-btn z-50 flex cursor-pointer flex-row items-center justify-end rounded-lg bg-white"
            [ngClass]="{
              'm-[0.5px]': !(isWhiteLabel$ | async)
            }"
            (click)="addApplicant(group?.get('id')?.value)"
          >
            <mat-icon class="text-tilled-primary icon-size-5" [svgIcon]="'heroicons_solid:pencil'"> </mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="this.busOwnerStep || (!this.busOwnerStep && (applicantIndex$ | async) < 0)"
      data-test-id="representative-step-add-representative"
      class="add-rep-wrapper mb-3 flex items-center self-center rounded-full"
      [ngClass]="{
        'tilled-gradient': !(isWhiteLabel$ | async) && businessEntityType,
        'border-[1.5px] border-black': (isWhiteLabel$ | async) && businessEntityType
      }"
    >
      <div
        class="add-rep-btn z-50 m-px my-px flex max-h-[39px] w-full flex-row items-center justify-center rounded-full px-12 py-2"
        [ngClass]="{
          'm-px': !(isWhiteLabel$ | async),
          'cursor-pointer bg-white': businessEntityType,
          'border-[1.5px] border-tilled-neutral-300 bg-tilled-neutral-200 italic': !businessEntityType
        }"
        (click)="businessEntityType ? addApplicant() : null"
      >
        <mat-icon class="text-tilled-primary" [svgIcon]="'mat_outline:add'"> </mat-icon>
        <tilled-paragraph-p3 [bold]="true" class="mr-1.5 whitespace-nowrap text-tilled-primary">
          {{ busOwnerStep ? 'Add owner' : 'Add application signer' }}
        </tilled-paragraph-p3>
      </div>
    </div>
    <a
      *ngIf="busOwnerStep"
      class="-mb-3.5 ml-1 mt-4 flex w-fit"
      href="https://paymentsonline.zendesk.com/hc/en-us/articles/35397632977172-Account-Requirements"
      target="_blank"
    >
      <tilled-paragraph-p3 [bold]="true" [styles]="'underline'">
        My business is owned by another business, how do I fill this out?
      </tilled-paragraph-p3>
      <mat-icon
        class="ml-2 text-tilled-primary icon-size-5"
        [svgIcon]="'heroicons_outline:arrow-top-right-on-square'"
      ></mat-icon>
    </a>
  </div>
</merchant-app-card>
