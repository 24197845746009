<tilled-table
  [data]="(loading$ | async) ? null : (viewModels$ | async)"
  [getPageCallback]="getConnectedAccounts"
  [dataLength]="connectedAccountsCount$ | async"
  [noDataMainText]="'No merchants found'"
  [noDataSecondaryText]="secondaryReasonText"
  [queryString]="query"
  [filterArray]="capabilityStatuses || accountStatuses"
  [getClickCallback]="rowClickedCallback"
  [hideColumnKeys]="hideColumnKeys"
  data-test-id="merchants-table"
></tilled-table>
