<div cdkDropListGroup class="w-full" (click)="$event.stopPropagation()">
  <div class="flex w-full flex-col gap-4 pt-2">
    <ng-container>
      <div class="flex flex-row items-center">
        <mat-divider class="w-full"></mat-divider>
        <p
          class="self-center whitespace-nowrap px-2 font-openSans font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
        >
          Active Columns
        </p>
        <mat-divider class="w-full"></mat-divider>
      </div>
      <div
        cdkDropList
        [cdkDropListData]="active"
        id="activeColumns"
        class="custom-column-list"
        (cdkDropListDropped)="drop($event)"
      >
        @for (item of active; track item) {
          <div [hidden]="item.hidden" cdkDrag>
            <div class="custom-column-box">
              <div>
                <mat-checkbox [checked]="true" bgColor="tilled-primary" (change)="move('activeColumns', $index)">{{
                  item.columnDescription
                }}</mat-checkbox>
              </div>
              <mat-icon>drag_indicator</mat-icon>
            </div>
          </div>
        }
      </div>
    </ng-container>
    <ng-container>
      <div class="flex flex-row items-center">
        <mat-divider class="w-full"></mat-divider>
        <p
          class="self-center whitespace-nowrap px-2 font-openSans font-[600] not-italic leading-normal tracking-normal text-tilled-primary"
        >
          Available Columns
        </p>
        <mat-divider class="w-full"></mat-divider>
      </div>
      <div
        cdkDropList
        [cdkDropListData]="available"
        id="availableColumns"
        class="custom-column-list"
        (cdkDropListDropped)="drop($event)"
      >
        @for (item of available; track item) {
          <div [hidden]="item.hidden" cdkDrag>
            <div class="custom-column-box">
              <div>
                <mat-checkbox bgColor="tilled-primary" [checked]="false" (change)="move('availableColumns', $index)">{{
                  item.columnDescription
                }}</mat-checkbox>
              </div>
            </div>
          </div>
        }
      </div>
    </ng-container>
  </div>
</div>
