import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PartnerAppService } from 'app/core/services/partner-app.service';
import { environment } from 'environments/environment';
import { Observable, filter, map, take } from 'rxjs';
import {
  Account,
  AccountCapability,
  AccountDocument,
  AccountInternalMetadata,
  EsignatureDocument,
  InternalAccountCapability,
  PartnerApplicationResponse,
} from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusGuard {
  private partnerApp: Observable<PartnerApplicationResponse>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private partnerAppService: PartnerAppService,
  ) {}

  public canActivatePartner() {
    // We currently only want to restrict partner access in production and staging for testing.
    // Sandbox should remain unrestricted.
    if (!environment.production && environment.env !== 'staging') {
      return true;
    }

    const account = this.authService.currentAccount;

    // Allow partners access to production if their account status is active OR we've set their status manually in admin.
    if (
      account.status === Account.StatusEnum.ACTIVE ||
      account.internal_metadata.status === AccountInternalMetadata.StatusEnum.CERTIFIED ||
      account.internal_metadata.status === AccountInternalMetadata.StatusEnum.LIVE ||
      account.internal_metadata.status === AccountInternalMetadata.StatusEnum.ACTIVE
    ) {
      return true;
    } else {
      this.partnerAppService
        .loadApplication()
        .pipe(take(1))
        .subscribe((application) => {
          if (
            application.status === PartnerApplicationResponse.StatusEnum.CREATED ||
            application.status === PartnerApplicationResponse.StatusEnum.STARTED
          ) {
            this.router.navigate(['/enroll']);
          } else {
            this.router.navigate(['/enroll/success']);
          }
        });
      return false;
    }
  }

  public canActivateMerchant(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.authService.currentAccount;
    if (
      account.capabilities.find((cap) =>
        [AccountCapability.StatusEnum.ACTIVE, AccountCapability.StatusEnum.DISABLED].includes(cap.status),
      )
    ) {
      return true;
    } else if (
      account.capabilities.find(
        (cap) =>
          (cap.provider_type === InternalAccountCapability.ProviderTypeEnum.TSYS ||
            cap.provider_type === InternalAccountCapability.ProviderTypeEnum.VALOR ||
            cap.provider_type === InternalAccountCapability.ProviderTypeEnum.MULTI_PASS ||
            cap.provider_type === InternalAccountCapability.ProviderTypeEnum.NORTH) &&
          cap.status === InternalAccountCapability.StatusEnum.STARTED &&
          cap.attributes?.esignature?.document?.status === EsignatureDocument.StatusEnum.SENT,
      )
    ) {
      this.router.navigate(['/onboarding/sign']);
    } else if (
      account.capabilities.find((cap) =>
        [AccountCapability.StatusEnum.SUBMITTED, AccountCapability.StatusEnum.IN_REVIEW].includes(cap.status),
      ) &&
      account.document_requests?.length > 0 &&
      account.document_requests.find((doc) => doc.status === AccountDocument.StatusEnum.REQUESTED)
    ) {
      this.router.navigate(['/onboarding/documents']);
    } else if (
      account.capabilities.every((cap) =>
        [
          AccountCapability.StatusEnum.SUBMITTED,
          AccountCapability.StatusEnum.REJECTED,
          AccountCapability.StatusEnum.WITHDRAWN,
          AccountCapability.StatusEnum.IN_REVIEW,
        ].includes(cap.status),
      )
    ) {
      // redirect to submitted page.
      const queryParams = route.queryParams;
      this.router.navigate(['/onboarding/submitted'], { queryParams });
    } else {
      // Authorized user that has not yet completed a merchant application
      // Redirect to merch app.
      this.router.navigate(['/onboarding/application']);
    }

    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.account$.pipe(
      filter((account) => account != null),
      take(1),
      map(() => {
        if (this.authService.isPartnerUser()) {
          return this.canActivatePartner();
        } else if (this.authService.isResellerUser()) {
          // currently resellers don't have a default status, so default to true for now
          return true;
        } else {
          return this.canActivateMerchant(route, state);
        }
      }),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.account$.pipe(
      filter((account) => account != null),
      take(1),
      map(() => {
        if (this.authService.isPartnerUser()) {
          return this.canActivatePartner();
        } else if (this.authService.isResellerUser()) {
          // currently resellers don't have a default status, so default to true for now
          return true;
        } else {
          return this.canActivateMerchant(route, state);
        }
      }),
    );
  }
}
