<div class="top-of-form"></div>
<div
  *ngIf="(submittingApp$ | async) && (hasTsysProvider || hasNorthProvider)"
  class="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-90"
>
  <docusign-loading class="z-50"></docusign-loading>
</div>
<merchant-app-card
  *ngIf="!canSubmitApplication"
  [title]="!forConsole ? 'Submit' : null"
  [description]="
    !forConsole
      ? 'Review pricing and terms before submitting your application. If there is any missing information we’ll let you know before submitting.'
      : null
  "
  [backButton]="false"
  [continueButton]="false"
  [continueButtonText]="hasTsysProvider || hasNorthProvider ? 'Sign and Submit' : 'Submit Application'"
  [continueButtonIcon]="!forConsole ? 'mat_solid:east' : ''"
  [bypassHalfWidth]="true"
>
  <tilled-heading-h4 class="topOfPage">Review</tilled-heading-h4>
  <!-- Business Type Alert-->
  <div *ngIf="!businessTypeComplete.complete" class="pt-6">
    <tilled-heading-h4>Business Type</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessDetailsError"
      alertName="businessDetailsWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessTypeComplete.missingFields"
      (editClick)="goToStep(businessTypeSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Business Details Alert-->
  <div *ngIf="!businessDetailsComplete.complete" class="pt-6">
    <tilled-heading-h4>Business Details</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessDetailsError"
      alertName="businessDetailsWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessDetailsComplete.missingFields"
      (editClick)="goToStep(businessDetailsSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Contact Info Alert-->
  <div *ngIf="!businessContactComplete.complete" class="pt-6">
    <tilled-heading-h4>Contact Info</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessContactError"
      alertName="contactInfoWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessContactComplete.missingFields"
      (editClick)="goToStep(businessContactSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Representatives Alert-->
  <div *ngIf="!applicationSignersComplete.complete" class="pt-6">
    <tilled-heading-h4>Application signer</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessRepsError"
      alertName="representativesWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="applicationSignersComplete.missingFields"
      (editClick)="goToStep(applicationSignersSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Business Owners Alert-->
  <div *ngIf="!businessOwnersComplete.complete" class="pt-6">
    <tilled-heading-h4>Business Owners</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessOwnersError"
      alertName="businessOwnersWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessOwnersComplete.missingFields"
      (editClick)="goToStep(businessOwnersSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Products and Services Alert-->
  <div *ngIf="!productsAndServicesComplete.complete" class="pt-6">
    <tilled-heading-h4>Products and Services</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="productsAndServicesError"
      alertName="productsAndServicesWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="productsAndServicesComplete.missingFields"
      (editClick)="goToStep(productsAndServicesSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Payment Acceptance Alert-->
  <div *ngIf="!paymentAcceptanceComplete.complete" class="pt-6">
    <tilled-heading-h4>Payment Acceptance</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="paymentAcceptanceError"
      alertName="paymentAcceptanceWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="paymentAcceptanceComplete.missingFields"
      (editClick)="goToStep(paymentAcceptanceSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Processing Volumes Alert-->
  <div *ngIf="!processingVolumeComplete.complete" class="pt-6">
    <tilled-heading-h4>Processing Volumes</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="processingVolumeError"
      alertName="processingVolumesWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="processingVolumeComplete.missingFields"
      (editClick)="goToStep(processingVolumesSubStep)"
    ></merchant-app-alert>
  </div>

  <!-- Bank Account Alert-->
  <div *ngIf="!bankAccountComplete.complete" class="pt-6">
    <tilled-heading-h4>Bank Account</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="bankAccountError"
      alertName="bankAccountWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="bankAccountComplete.missingFields"
      (editClick)="goToStep(bankAccountStep)"
    ></merchant-app-alert>
  </div>

  <!-- Business Details Alert-->
  <div *ngIf="!businessDocumentsComplete.complete && hasTsysProvider" class="pt-6">
    <tilled-heading-h4>Business Documents</tilled-heading-h4>
    <merchant-app-alert
      data-test-id="businessDocumentsError"
      alertName="businessDocumentsWarnAlertBox"
      title="Missing required information"
      description="This section has missing data that must be completed before submitting your application:"
      [subDescriptionArray]="businessDocumentsComplete.missingFields"
      (editClick)="goToStep(businessDocumentsStep)"
    ></merchant-app-alert>
  </div>

  <!-- Outstanding Middesk Verification Suggestions -->
  <ng-container *ngIf="verificationAlerts">
    <div
      *ngFor="let alert of verificationAlerts"
      class="mt-6 flex flex-row space-x-4 rounded-xl border border-[#F8D562] bg-[#FFF6D7] px-4 py-3"
    >
      <div class="flex w-full flex-col space-y-4">
        <tilled-paragraph-p3 bold="true">{{ alert.title }}</tilled-paragraph-p3>
        <div class="whitespace-pre-wrap" [innerHTML]="alert.message"></div>
        <div class="btnGroup flex flex-nowrap justify-evenly space-x-2">
          <tilled-button
            class="w-full"
            *ngIf="alert?.ignoreText"
            styles="flex-1 w-full outline-tilled-neutral-400 {{
              (isWhiteLabel$ | async) ? ' *:*:*:text-tilled-secondary ' : ''
            }}"
            (buttonClick)="handleVerificationAction(alert, false)"
            [buttonText]="alert?.ignoreText"
            [secondary]="true"
            [forceWhite]="false"
            [whiteText]="false"
            bgColor="white"
            outlineColor="tilled-neutral-400"
            [attr.data-test-id]="'ignore-suggestion-btn'"
          ></tilled-button>
          <tilled-button
            class="w-full"
            [styles]="'flex-1 w-full'"
            (buttonClick)="handleVerificationAction(alert, alert?.updateText || alert?.reviewText ? true : false)"
            [buttonText]="alert?.updateText || alert?.reviewText"
            textColor="white"
            [forceWhite]="true"
            [whiteText]="true"
            [bgColor]="(isWhiteLabel$ | async) ? 'black' : 'tilled-primary'"
            rounded="true"
            [attr.data-test-id]="'review-update-btn'"
          ></tilled-button>
        </div>
      </div>
    </div>
  </ng-container>
</merchant-app-card>

<div [ngClass]="{ '-mt-8': !canSubmitApplication }">
  <merchant-app-card
    [title]="canSubmitApplication && !forConsole ? 'Submit Application' : null"
    [description]="
      canSubmitApplication && !forConsole
        ? 'Review pricing and terms before submitting your application. If there is any missing information we’ll let you know before submitting.'
        : null
    "
    [backButton]="false"
    (continueClick)="onContinueClicked($event)"
    [continueButtonText]="hasTsysProvider || hasNorthProvider ? 'Sign and Submit Application' : 'Submit Application'"
    [continueButtonIcon]="!forConsole ? 'mat_solid:east' : ''"
    [disableContinueButton]="
      ((hasTsysProvider || hasNorthProvider) && !isPrimaryApplicant) ||
      !(canSubmitApplication && termsAgreed) ||
      disableButton ||
      (submittingApp$ | async)
    "
    [bypassHalfWidth]="true"
  >
    <!-- Outstanding Middesk Verification Suggestions -->
    <ng-container *ngIf="verificationAlerts">
      <div
        *ngFor="let alert of verificationAlerts"
        class="mb-6 flex flex-row space-x-4 rounded-xl border border-[#F8D562] bg-[#FFF6D7] px-4 py-3"
      >
        <div class="flex w-full flex-col space-y-4">
          <tilled-paragraph-p3 bold="true">{{ alert.title }}</tilled-paragraph-p3>
          <div class="whitespace-pre-wrap" [innerHTML]="alert.message"></div>
          <div class="btnGroup flex flex-nowrap justify-evenly space-x-2">
            <tilled-button
              class="w-full"
              *ngIf="alert?.ignoreText"
              styles="flex-1 w-full outline-tilled-neutral-400 {{
                (isWhiteLabel$ | async) ? ' *:*:*:text-tilled-secondary ' : ''
              }}"
              (buttonClick)="handleVerificationAction(alert, false)"
              [buttonText]="alert?.ignoreText"
              [secondary]="true"
              [forceWhite]="false"
              [whiteText]="false"
              bgColor="white"
              outlineColor="tilled-neutral-400"
              [attr.data-test-id]="'ignore-suggestion-btn'"
            ></tilled-button>
            <tilled-button
              class="w-full"
              [styles]="'flex-1 w-full'"
              (buttonClick)="handleVerificationAction(alert, alert?.updateText || alert?.reviewText ? true : false)"
              [buttonText]="alert?.updateText || alert?.reviewText"
              textColor="white"
              [forceWhite]="true"
              [whiteText]="true"
              [bgColor]="(isWhiteLabel$ | async) ? 'black' : 'tilled-primary'"
              rounded="true"
              [attr.data-test-id]="'review-update-btn'"
            ></tilled-button>
          </div>
        </div>
      </div>
    </ng-container>
    <tilled-heading-h4 class="pb-6">Pricing</tilled-heading-h4>
    <div
      class="w-full pb-8"
      [ngClass]="{
        'grid grid-cols-1 gap-y-4': hasMultiple
      }"
    >
      <div *ngIf="cardPricingTemplate$ | async as cardPricingTemplate" class="rounded-2xl border bg-white p-6">
        <card-pricing
          data-test-id="card-pricing-details"
          [showCard]="cardPricingTemplate"
          label="Card-not-present"
          [currency]="cardPricingTemplate.currency"
          [feeType]="cardPricingTemplate.fee_type"
          [visaRate]="cardPricingTemplate.visa_rate"
          [amexRate]="cardPricingTemplate.amex_rate"
          [transactionFee]="cardPricingTemplate.transaction_fee"
          [chargebackFee]="cardPricingTemplate.chargeback_fee"
          [retrievalFee]="cardPricingTemplate.retrieval_fee"
          [reversalFee]="cardPricingTemplate.reversal_fee"
          [accountMonthlyFee]="cardPricingTemplate.account_monthly_fee"
          [accountMonthlyMinimumFee]="cardPricingTemplate.account_monthly_minimum_fee"
          [passThroughFees]="cardPricingTemplate.pass_through_fees"
        ></card-pricing>
      </div>
      <div
        *ngIf="cardPresentPricingTemplate$ | async as cardPresentPricingTemplate"
        class="rounded-2xl border bg-white p-6"
      >
        <card-present-pricing
          data-test-id="cardPresent-pricing-details"
          [showCard]="cardPresentPricingTemplate"
          label="Card-present"
          [currency]="cardPresentPricingTemplate.currency"
          [feeType]="cardPresentPricingTemplate.fee_type"
          [visaRate]="cardPresentPricingTemplate.visa_rate"
          [amexRate]="cardPresentPricingTemplate.amex_rate"
          [transactionFee]="cardPresentPricingTemplate.transaction_fee"
          [chargebackFee]="cardPresentPricingTemplate.chargeback_fee"
          [retrievalFee]="cardPresentPricingTemplate.retrieval_fee"
          [reversalFee]="cardPresentPricingTemplate.reversal_fee"
          [accountMonthlyFee]="cardPresentPricingTemplate.account_monthly_fee"
          [accountMonthlyMinimumFee]="cardPresentPricingTemplate.account_monthly_minimum_fee"
          [bankAccountChangeFee]="cardPresentPricingTemplate.bank_account_change_fee"
          [monthlyTerminalFee]="cardPresentPricingTemplate.monthly_terminal_fee"
          [passThroughFees]="cardPresentPricingTemplate.pass_through_fees"
        ></card-present-pricing>
      </div>
      <div *ngIf="debitPricingTemplate$ | async as debitPricingTemplate" class="rounded-2xl border bg-white p-6">
        <debit-pricing
          data-test-id="debit-pricing-details"
          [showCard]="debitPricingTemplate"
          label="Bank-to-bank"
          [currency]="debitPricingTemplate.currency"
          [feeType]="debitPricingTemplate.fee_type"
          [transactionFee]="debitPricingTemplate.transaction_fee"
          [returnFee]="debitPricingTemplate.return_fee"
          [accountMonthlyFee]="debitPricingTemplate.account_monthly_fee"
          [accountMonthlyMinimumFee]="debitPricingTemplate.account_monthly_minimum_fee"
        ></debit-pricing>
      </div>
    </div>
    <div *ngIf="signers?.length > 0" class="pb-8">
      <div class="pb-2">
        <tilled-heading-h4>Application signers</tilled-heading-h4>
      </div>
      <tilled-paragraph-p3
        >All signers listed here must sign the merchant agreement to submit this application. The first signer will sign
        directly in this flow, while all other signers will receive an email from Docusign to complete their signature.
        Once you click <span class="font-semibold">Sign and Submit</span> below, changes to signers will no longer be
        possible.</tilled-paragraph-p3
      >
      <div class="pb-2 pt-6">
        <tilled-paragraph-p3 [bold]="true"
          >Current application signers<mat-icon
            class="ml-2 pt-0.5 text-tilled-primary icon-size-4"
            [svgIcon]="'mat_outline:help_outline'"
            [matTooltip]="signersTooltipText"
            matTooltipClass="whitespace-pre-wrap"
          ></mat-icon
        ></tilled-paragraph-p3>
      </div>
      <div *ngFor="let signer of signers; let i = index" class="flex items-start">
        <span class="mx-2 text-tilled-primary-500">•</span>
        <tilled-paragraph-p3
          ><span class="font-semibold">{{ signer.name }}</span> - {{ signer.email
          }}<span *ngIf="signer.isPrimary"> - Application signer</span>, {{ signer.percentOwnership }}%
          ownership</tilled-paragraph-p3
        >
      </div>
      <div class="w-full pt-6">
        <tilled-button
          [styles]="'flex-1 w-full'"
          (buttonClick)="goToStep(businessOwnersSubStep)"
          buttonText="Update Application Signers"
          rounded="true"
          textColor="tilled-primary"
          bgColor="transparent"
          [whiteText]="false"
          [outlineColor]="(isWhiteLabel$ | async) ? 'black' : 'tilled-primary'"
        ></tilled-button>
      </div>
    </div>
    <tilled-heading-h4 class="">Agreements</tilled-heading-h4>
    <form
      data-test-id="submit-application-step"
      [ngClass]="{ 'w-1/2': forConsole }"
      [formGroup]="submitApplicationForm"
    >
      <div class="-ml-2.5 mt-3 grid auto-cols-auto grid-flow-col gap-6">
        <mat-checkbox
          *ngIf="hasPaysafeProvider && isCanadian"
          data-test-id="checkbox"
          class="-mt-3 self-start pt-1 text-p3"
          [color]="'primary'"
          [formControlName]="'onAcceptsCanadaDebit'"
          (change)="onAcceptsCanadaDebit($event)"
          ><tilled-paragraph-p3>I wish to apply to Visa and Mastercard debit processing. </tilled-paragraph-p3>
        </mat-checkbox>
      </div>
      <div class="-ml-2.5 mt-3 grid auto-cols-auto grid-flow-col gap-6">
        <mat-checkbox
          data-test-id="checkbox"
          class="-mt-3 self-start pt-1 text-p3"
          [color]="'primary'"
          [formControlName]="'acceptedTerms'"
          (change)="onAgreeTermsClicked($event)"
        >
          <tilled-paragraph-p3
            >I agree to the pricing,
            <a *ngIf="hasPaysafeProvider" class="text-primary underline" href="{{ merchantTermsLink }}" target="_blank"
              >Merchant Terms and Conditions</a
            ><span *ngIf="hasPaysafeProvider">, </span>
            <ng-container *ngIf="!isCanadian && debitPricingTemplate$ | async">
              <a class="text-primary underline" href="{{ achDebitTermsLink }}" target="_blank">ACH Terms</a>,
            </ng-container>
            <a class="text-primary underline" href="{{ portalTermsLink }}" target="_blank">Portal Terms of Use</a>, and
            <a class="text-primary underline" href="{{ portalTermsLink }}" target="_blank">Privacy Policy</a>.
            Additionally you confirm that the information you have provided is accurate and you are an authorized by
            your company to enter into this agreement.
          </tilled-paragraph-p3>
        </mat-checkbox>
      </div>
      <mat-error
        data-test-id="accept-terms-error"
        class="pt-4 text-md text-warn"
        *ngIf="!submitApplicationForm.get('acceptedTerms').value && submitApplicationForm.get('acceptedTerms').touched"
        >You must accept the Terms and Conditions</mat-error
      >
      <div
        *ngIf="(hasTsysProvider || hasNorthProvider) && !isPrimaryApplicant"
        class="mt-8 flex flex-row space-x-4 rounded-xl border border-[#F8D562] bg-[#FFF6D7] px-4 py-3"
      >
        <div *ngIf="loading$ | async; else loadedContent">
          <mat-icon class="text-primary icon-size-24" [svgIcon]="'heroicons_outline:arrow-path'"></mat-icon>
        </div>
        <ng-template #loadedContent>
          <!-- START WILL BE DOCUSIGN DIALOG OPEN -->
          <button mat-icon-button class="flex" (click)="openDocuSignModal()">
            <mat-icon [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
          </button>
          <!-- END WILL BE DOCUSIGN DIALOG OPEN -->
          <div class="flex w-full flex-col space-y-4">
            <tilled-paragraph-p3 bold="true">You are not authorized to sign for this application</tilled-paragraph-p3>
            <tilled-paragraph-p3>
              Your email address does not match the email address of the application signer,
              {{ applicant?.first_name }} {{ applicant?.last_name }}
              {{ applicant?.email ? '(' + applicant?.email + ')' : '' }}.
            </tilled-paragraph-p3>
            <div *ngIf="applicantExists">
              <span> The application signer already has access to this application </span>
              <span class="font-medium"
                >and will need to login to electronically sign the Merchant Agreement on the this step.</span
              >
            </div>
            <tilled-paragraph-p3 *ngIf="!applicantExists">
              The application signer does not have access to this application. Would you like to invite
              {{ applicant?.first_name }} {{ applicant?.last_name }} to access this application now? If not, you must
              change the application signer.</tilled-paragraph-p3
            >
            <tilled-button
              [styles]="'flex-1 w-full'"
              (buttonClick)="invitePrimaryApplicant()"
              [buttonText]="'Invite or Change Application signer'"
              textColor="white"
              [whiteText]="true"
              bgColor="tilled-primary"
              rounded="true"
              [attr.data-test-id]="'invite-or-change-primary-applicant'"
            ></tilled-button>
          </div>
        </ng-template>
      </div>
      <div class="py-4" *ngIf="canSubmitApplication && isCanadian && !canadianVisaMcProcessing">
        <fuse-alert
          [type]="'warning'"
          [appearance]="'outline'"
          [dismissible]="'true'"
          [dismissed]="false"
          [name]="'canadaVisaMcProcessingAlertBox'"
        >
          <span fuseAlertTitle>Warning</span>
          Are you sure you wish to continue without Visa and Mastercard debit processing?
        </fuse-alert>
      </div>
    </form>
  </merchant-app-card>
</div>
<div *ngIf="displayAlert$ | async" class="grid w-full grid-cols-1 justify-items-start px-8 pb-8">
  <fuse-alert
    [type]="'warn'"
    [appearance]="'outline'"
    [dismissible]="'true'"
    [dismissed]="false"
    [name]="'merchantAppAlertBox'"
  >
    <span fuseAlertTitle>Submitting Application Failed</span>
    {{ alertMessage }}
  </fuse-alert>
</div>
