import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, expand, map, Observable, ReplaySubject, shareReplay, tap } from 'rxjs';
import {
  Account,
  AccountsService,
  AccountUpdateParams,
  CreateConnectedAccountRequestParams,
  DeleteConnectedAccountRequestParams,
  GetAccountRequestParams,
  InternalAccount,
  InternalGetAccountRequestParams,
  InternalListConnectedAccountsRequestParams,
  InternalService,
  ListConnectedAccountsRequestParams,
  UpdateAccountRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { DEFAULT_PAGE_LIMIT } from '../constants';
import { TilledAlert } from '../models/tilled-alert';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AccountAppService {
  private _connectedAccountsPaginated = new ReplaySubject<InternalAccount[]>();
  private _connectedAccountsAll = new ReplaySubject<Account[]>();
  private _connectedAccount = new BehaviorSubject<InternalAccount>(null);
  private _connectedAccountsCount = new ReplaySubject<number>();
  private _connectedAccountApplicationProgress = new BehaviorSubject<number>(null);
  private accountListBuilder: Account[] = [];

  public connectedAccountsPaginated$: Observable<InternalAccount[]> = this._connectedAccountsPaginated.asObservable();
  public connectedAccountsAll$: Observable<Account[]> = this._connectedAccountsAll.asObservable();
  public connectedAccountsCount$: Observable<number> = this._connectedAccountsCount.asObservable();
  public connectedAccount$: Observable<InternalAccount> = this._connectedAccount.asObservable();
  public connectedAccountApplicationProgress$: Observable<number> =
    this._connectedAccountApplicationProgress.asObservable();

  constructor(
    private _authService: AuthService,
    private _accountsService: AccountsService,
    private _alertService: AlertService,
    private _internalService: InternalService,
  ) {
    this._authService.account$.subscribe((account) => {
      if (account?.type !== InternalAccount.TypeEnum.MERCHANT) {
        this.loadAllConnectedAccounts(account?.id);
      }
    });
  }

  public filterAccountsSelector(params: ListConnectedAccountsRequestParams): Observable<Account[]> {
    const requestParams: ListConnectedAccountsRequestParams = {
      tilledAccount: params.tilledAccount || AuthService.getCurrentAccountId(),
      metadata: params.metadata,
      q: params.q,
      type: params.type,
      sort: params.sort,
      offset: 0,
      limit: 50,
    };

    return this._accountsService.listConnectedAccounts(requestParams).pipe(
      map((result) => result.items),
      catchError((err) => {
        const message: TilledAlert = {
          message: 'Could not load accounts',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        if (err.status !== HttpStatusCode.NotFound) {
          throw new Error('Error loading accounts ' + JSON.stringify(err));
        }
        return EMPTY;
      }),
    );
  }

  public loadConnectedAccounts(params: InternalListConnectedAccountsRequestParams): void {
    const requestParams: InternalListConnectedAccountsRequestParams = {
      tilledAccount: params.tilledAccount || AuthService.getCurrentAccountId(),
      metadata: params.metadata,
      q: params.q,
      sort: params.sort,
      type: params?.type,
      offset: params.offset ? params.offset : 0,
      limit: params.limit ? params.limit : DEFAULT_PAGE_LIMIT,
      capabilityStatus: params.capabilityStatus,
      status: params.status,
      partnerApplicationStatus: params.partnerApplicationStatus,
      processingCapabilities: params.processingCapabilities,
    };

    this._internalService
      .internalListConnectedAccounts(requestParams)
      .pipe(
        tap((result) => this._connectedAccountsCount.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (result) => {
          this._connectedAccountsPaginated.next(result);
        },
        error: (err) => {
          const message: TilledAlert = {
            message: 'Could not load accounts',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
          if (err.status !== HttpStatusCode.NotFound) {
            throw new Error('Error loading accounts ' + JSON.stringify(err));
          }
        },
      });
  }

  public getConnectedAccountById(accountId: string): void {
    const accountParams: InternalGetAccountRequestParams = {
      tilledAccount: accountId,
    };
    this._connectedAccount.next(null);
    this._internalService.internalGetAccount(accountParams).subscribe({
      next: (account) => {
        if (account) {
          this._connectedAccount.next(account);

          if (account.capabilities && account.capabilities.length > 0) {
            this._connectedAccountApplicationProgress.next(
              account.capabilities[0].status === 'active'
                ? 100
                : account.capabilities[0].onboarding_application_progress,
            );
          } else {
            this._connectedAccountApplicationProgress.next(null);
          }
        }
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not load account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        if (err.status !== HttpStatusCode.NotFound) {
          throw new Error('Error loading account ' + JSON.stringify(err));
        }
      },
    });
  }

  public createConnectedAccount(params: CreateConnectedAccountRequestParams): void {
    this._accountsService.createConnectedAccount(params).subscribe({
      next: (result) => {
        const message: TilledAlert = {
          message:
            result.type === InternalAccount.TypeEnum.MERCHANT
              ? `Merchant application ${result.name} was created successfully`
              : `Software platform ${result.name} was created successfully`,
          title: 'Account created',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);

        // Refetch from internal endpoint to include all additional properties
        this.getConnectedAccountById(result.id);

        const requestParams: ListConnectedAccountsRequestParams = {
          tilledAccount: params.tilledAccount,
          type: result.type,
          offset: 0,
          limit: 25,
        };
        this.loadConnectedAccounts(requestParams);
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not create account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw new Error('Error creating account ' + JSON.stringify(err));
      },
    });
  }

  public updateConnectedAccountById(
    connectedAccountId: string,
    updateParams: AccountUpdateParams,
    tilledAlertSuccessMessage?: string,
  ): void {
    const accountParams: UpdateAccountRequestParams = {
      tilledAccount: connectedAccountId,
      accountUpdateParams: updateParams,
    };
    this._accountsService.updateAccount(accountParams).subscribe({
      next: (result) => {
        const message: TilledAlert = {
          message: tilledAlertSuccessMessage ?? 'Account was updated successfully',
          title: 'Account updated',
          type: 'success',
          timer: 8000,
        };
        this._alertService.showAlert(message);

        this.getConnectedAccountById(result.id);
        // Ensure that all cached accounts are updated properly since we just edited one
        this._authService.reloadAccounts();
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not update account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        throw new Error('Error updating account ' + JSON.stringify(err));
      },
    });
  }

  getAccountById(id: string): Observable<Account> {
    const getAccountParams: GetAccountRequestParams = {
      tilledAccount: id,
    };
    return this._accountsService.getAccount(getAccountParams).pipe(
      map((res) => res),
      catchError((err) => {
        const message: TilledAlert = {
          message: 'Could not load account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        if (err.status !== HttpStatusCode.NotFound) {
          throw new Error('Error getting account ' + JSON.stringify(err));
        }
        return EMPTY;
      }),
      shareReplay(),
    );
  }

  deleteConnectedAccount(params: DeleteConnectedAccountRequestParams): Observable<any> {
    return this._accountsService.deleteConnectedAccount(params).pipe(
      map((res) => res),
      catchError((err) => {
        const message: TilledAlert = {
          message: 'Could not delete account',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        if (err.status !== HttpStatusCode.NotFound) {
          throw new Error('Error deleting account' + JSON.stringify(err));
        }
        return EMPTY;
      }),
    );
  }

  // This will recursively call the list account endpoint until we have them all.
  private loadAllConnectedAccounts(accountId: string): void {
    const requestParams: InternalListConnectedAccountsRequestParams = {
      tilledAccount: accountId,
      metadata: null,
      q: null,
      sort: null,
      offset: 0,
      limit: 100,
    };

    const listConnectedAccounts$ = this._internalService.internalListConnectedAccounts(requestParams);
    listConnectedAccounts$
      .pipe(
        expand((result) => {
          const hasMore = result.has_more;
          requestParams.offset = result.offset + result.limit;
          if (hasMore) {
            this.accountListBuilder.push(...result.items);
            return this._accountsService.listConnectedAccounts(requestParams);
          }
          this.accountListBuilder.push(...result.items);
          this._connectedAccountsAll.next(this.accountListBuilder);
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
