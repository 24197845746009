/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { EsignatureSigner } from '../model/esignatureSigner';
// @ts-ignore
import { MccDescription } from '../model/mccDescription';
// @ts-ignore
import { MerchantApplication } from '../model/merchantApplication';
// @ts-ignore
import { MerchantApplicationCreateParams } from '../model/merchantApplicationCreateParams';
// @ts-ignore
import { OnboardingApplication } from '../model/onboardingApplication';
// @ts-ignore
import { OnboardingApplicationCreateParams } from '../model/onboardingApplicationCreateParams';
// @ts-ignore
import { RegenerateSigningLinksParams } from '../model/regenerateSigningLinksParams';
// @ts-ignore
import { SigningLink } from '../model/signingLink';
// @ts-ignore
import { SubmitApplicationParams } from '../model/submitApplicationParams';
// @ts-ignore
import { SubmitApplicationResponse } from '../model/submitApplicationResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetEsignatureSignerRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
    documentId: string;
}

export interface GetMccDescriptionsRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
}

export interface GetMerchantApplicationRequestParams {
    /** The id of the associated connected (i.e. merchant) account. */
    accountId: string;
}

export interface GetOnboardingApplicationRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
}

export interface RegenerateSigningLinksRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    regenerateSigningLinksParams: RegenerateSigningLinksParams;
}

export interface ResendEsignatureDocumentEmailRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    id: string;
    documentId: string;
}

export interface SubmitMerchantApplicationRequestParams {
    /** The id of the associated connected (i.e. merchant) account. */
    accountId: string;
}

export interface SubmitOnboardingApplicationRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    submitApplicationParams: SubmitApplicationParams;
}

export interface UpdateMerchantApplicationRequestParams {
    /** The id of the associated connected (i.e. merchant) account. */
    accountId: string;
    merchantApplicationCreateParams: MerchantApplicationCreateParams;
}

export interface UpdateOnboardingApplicationRequestParams {
    /** The id of the Tilled Account (usually starting with the prefix &#x60;acct_&#x60;) that the request is performed on behalf of. */
    tilledAccount: string;
    onboardingApplicationCreateParams: OnboardingApplicationCreateParams;
}


@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

    protected basePath = 'https://api.tilled.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get Esignature Signer
     * Retrieves an Esignature Signer.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsignatureSigner(requestParameters: GetEsignatureSignerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<EsignatureSigner>;
    public getEsignatureSigner(requestParameters: GetEsignatureSignerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<EsignatureSigner>>;
    public getEsignatureSigner(requestParameters: GetEsignatureSignerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<EsignatureSigner>>;
    public getEsignatureSigner(requestParameters: GetEsignatureSignerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters?.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling getEsignatureSigner.');
        }
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getEsignatureSigner.');
        }
        const documentId = requestParameters?.documentId;
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getEsignatureSigner.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/onboarding/esignature/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/signer/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<EsignatureSigner>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get MCC Descriptions
     * Retrieves descriptions for all valid merchant category codes (MCC).
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMccDescriptions(requestParameters: GetMccDescriptionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<MccDescription>>;
    public getMccDescriptions(requestParameters: GetMccDescriptionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<MccDescription>>>;
    public getMccDescriptions(requestParameters: GetMccDescriptionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<MccDescription>>>;
    public getMccDescriptions(requestParameters: GetMccDescriptionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters?.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling getMccDescriptions.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/onboarding/mcc`;
        return this.httpClient.request<Array<MccDescription>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Merchant Application
     * Retrieves the details of an existing merchant application. The application can only be accessed if its status is &#x60;created&#x60; or &#x60;started&#x60;. Once the application is &#x60;submitted&#x60; or &#x60;active&#x60;, it is no longer accessible.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public getMerchantApplication(requestParameters: GetMerchantApplicationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<MerchantApplication>;
    public getMerchantApplication(requestParameters: GetMerchantApplicationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<MerchantApplication>>;
    public getMerchantApplication(requestParameters: GetMerchantApplicationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<MerchantApplication>>;
    public getMerchantApplication(requestParameters: GetMerchantApplicationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const accountId = requestParameters?.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getMerchantApplication.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/applications/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<MerchantApplication>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an Onboarding Application
     * Retrieves an onboarding application.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOnboardingApplication(requestParameters: GetOnboardingApplicationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<OnboardingApplication>;
    public getOnboardingApplication(requestParameters: GetOnboardingApplicationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<OnboardingApplication>>;
    public getOnboardingApplication(requestParameters: GetOnboardingApplicationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<OnboardingApplication>>;
    public getOnboardingApplication(requestParameters: GetOnboardingApplicationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters?.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling getOnboardingApplication.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/onboarding`;
        return this.httpClient.request<OnboardingApplication>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Regenerate Signing Links
     * Returns an array of Signing Links if the merchant signature has not been acquired yet.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regenerateSigningLinks(requestParameters: RegenerateSigningLinksRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<Array<SigningLink>>;
    public regenerateSigningLinks(requestParameters: RegenerateSigningLinksRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<Array<SigningLink>>>;
    public regenerateSigningLinks(requestParameters: RegenerateSigningLinksRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<Array<SigningLink>>>;
    public regenerateSigningLinks(requestParameters: RegenerateSigningLinksRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters?.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling regenerateSigningLinks.');
        }
        const regenerateSigningLinksParams = requestParameters?.regenerateSigningLinksParams;
        if (regenerateSigningLinksParams === null || regenerateSigningLinksParams === undefined) {
            throw new Error('Required parameter regenerateSigningLinksParams was null or undefined when calling regenerateSigningLinks.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/onboarding/signing-links`;
        return this.httpClient.request<Array<SigningLink>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: regenerateSigningLinksParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Resend Esignature Document email
     * Resend an Esignature Document email to a specific Esignature Signer.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resendEsignatureDocumentEmail(requestParameters: ResendEsignatureDocumentEmailRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<any>;
    public resendEsignatureDocumentEmail(requestParameters: ResendEsignatureDocumentEmailRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpResponse<any>>;
    public resendEsignatureDocumentEmail(requestParameters: ResendEsignatureDocumentEmailRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined,}): Observable<HttpEvent<any>>;
    public resendEsignatureDocumentEmail(requestParameters: ResendEsignatureDocumentEmailRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined,}): Observable<any> {
        const tilledAccount = requestParameters?.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling resendEsignatureDocumentEmail.');
        }
        const id = requestParameters?.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling resendEsignatureDocumentEmail.');
        }
        const documentId = requestParameters?.documentId;
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling resendEsignatureDocumentEmail.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/onboarding/esignature/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/signer/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/resend`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submit a Merchant Application
     * Submits a merchant application for processing. If there are any validation errors, they must be corrected before re-submitting. Once successfully submitted, the application is no longer accessible.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public submitMerchantApplication(requestParameters: SubmitMerchantApplicationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<object>;
    public submitMerchantApplication(requestParameters: SubmitMerchantApplicationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<object>>;
    public submitMerchantApplication(requestParameters: SubmitMerchantApplicationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<object>>;
    public submitMerchantApplication(requestParameters: SubmitMerchantApplicationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const accountId = requestParameters?.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling submitMerchantApplication.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/applications/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/submit`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Submit an Onboarding Application
     * Submits an onboarding application to be processed. If any validation errors exist, they must be corrected before re-submitting. Returns an array of Signing Links if a merchant signature is required.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitOnboardingApplication(requestParameters: SubmitOnboardingApplicationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<SubmitApplicationResponse>;
    public submitOnboardingApplication(requestParameters: SubmitOnboardingApplicationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<SubmitApplicationResponse>>;
    public submitOnboardingApplication(requestParameters: SubmitOnboardingApplicationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<SubmitApplicationResponse>>;
    public submitOnboardingApplication(requestParameters: SubmitOnboardingApplicationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters?.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling submitOnboardingApplication.');
        }
        const submitApplicationParams = requestParameters?.submitApplicationParams;
        if (submitApplicationParams === null || submitApplicationParams === undefined) {
            throw new Error('Required parameter submitApplicationParams was null or undefined when calling submitOnboardingApplication.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/onboarding/submit`;
        return this.httpClient.request<SubmitApplicationResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: submitApplicationParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Merchant Application
     * Updates a merchant application by overwriting all properties.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public updateMerchantApplication(requestParameters: UpdateMerchantApplicationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<MerchantApplication>;
    public updateMerchantApplication(requestParameters: UpdateMerchantApplicationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<MerchantApplication>>;
    public updateMerchantApplication(requestParameters: UpdateMerchantApplicationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<MerchantApplication>>;
    public updateMerchantApplication(requestParameters: UpdateMerchantApplicationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const accountId = requestParameters?.accountId;
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling updateMerchantApplication.');
        }
        const merchantApplicationCreateParams = requestParameters?.merchantApplicationCreateParams;
        if (merchantApplicationCreateParams === null || merchantApplicationCreateParams === undefined) {
            throw new Error('Required parameter merchantApplicationCreateParams was null or undefined when calling updateMerchantApplication.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/applications/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<MerchantApplication>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                body: merchantApplicationCreateParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an Onboarding Application
     * Updates an onboarding application by overwriting all properties. You must pass the entire payload each time.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateOnboardingApplication(requestParameters: UpdateOnboardingApplicationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<OnboardingApplication>;
    public updateOnboardingApplication(requestParameters: UpdateOnboardingApplicationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpResponse<OnboardingApplication>>;
    public updateOnboardingApplication(requestParameters: UpdateOnboardingApplicationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json',}): Observable<HttpEvent<OnboardingApplication>>;
    public updateOnboardingApplication(requestParameters: UpdateOnboardingApplicationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json',}): Observable<any> {
        const tilledAccount = requestParameters?.tilledAccount;
        if (tilledAccount === null || tilledAccount === undefined) {
            throw new Error('Required parameter tilledAccount was null or undefined when calling updateOnboardingApplication.');
        }
        const onboardingApplicationCreateParams = requestParameters?.onboardingApplicationCreateParams;
        if (onboardingApplicationCreateParams === null || onboardingApplicationCreateParams === undefined) {
            throw new Error('Required parameter onboardingApplicationCreateParams was null or undefined when calling updateOnboardingApplication.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (tilledAccount !== undefined && tilledAccount !== null) {
            localVarHeaders = localVarHeaders.set('tilled-account', String(tilledAccount));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        // authentication (TilledApiKey) required
        localVarCredential = this.configuration.lookupCredential('TilledApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('tilled-api-key', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/onboarding`;
        return this.httpClient.request<OnboardingApplication>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                body: onboardingApplicationCreateParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
