import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentBase } from 'app/core/componentBase';
import { RecentAccountData, UserLocalDataService } from 'app/core/db/services/user_local_data.service';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { MatchingStringToBoldPipe } from 'app/core/pipes/matching-string-to-bold.pipe';
import { TruncateStringPipe } from 'app/core/pipes/truncate-string.pipe';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AlertService } from 'app/core/services/alert.service';
import { FilterService } from 'app/shared/tilled-filter/filter-service.component';
import { BehaviorSubject, EMPTY, Observable, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs';
import { Account, ListConnectedAccountsRequestParams } from '../../../../projects/tilled-api-client/src';
import { AuthService } from '../../core/services/auth.service';
import { TilledLabelL1Component } from '../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'app-merchant-selector',
  templateUrl: './merchant-selector.component.html',
  styleUrls: ['./merchant-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TilledLabelL1Component,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatTooltipModule,
    TilledParagraphP3Component,
    MatButtonModule,
    CommonModule,
    TruncateStringPipe,
    MatchingStringToBoldPipe,
  ],
})
export class MerchantSelectorComponent extends ComponentBase implements OnInit {
  @Input() filterName: string;
  @Input() additionalClasses: string;
  @Input() labelName: string;
  @Input() inputAppearance?: 'fill' | 'outline' = 'fill';
  @Input() initialAccountId: string = null;
  @Input() parentAccountId?: string;
  @Output() setMerchantAccount: EventEmitter<Account> = new EventEmitter();

  @ViewChild('merchantSelectorInput')
  public merchantSelectorInput: ElementRef;
  private filteredMerchants = new BehaviorSubject<Account[]>([]);
  public filteredMerchants$ = this.filteredMerchants.asObservable();
  public showClear: boolean;
  private recentlyViewedMerchants = new BehaviorSubject<RecentAccountData[]>([]);
  public recentlyViewedMerchants$ = this.recentlyViewedMerchants.asObservable();
  public showRecentMerchants: boolean;
  public searchForm: FormGroup;
  private selectedAccount: Account;

  constructor(
    private _authService: AuthService,
    private _alertService: AlertService,
    private _accountsService: AccountAppService,
    private _formBuilder: FormBuilder,
    private _userLocalDataService: UserLocalDataService,
    private filterService: FilterService,
  ) {
    super();
  }

  ngOnInit(): void {
    // Initialize the form
    this.searchForm = this._formBuilder.group({
      searchInput: new FormControl<string | null>(null),
    });

    if (this.initialAccountId != null) {
      this.initializeInput();
    }

    this.searchForm
      .get('searchInput')
      .valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((q) => {
          if (q && typeof q === 'string' && q.length > 2) {
            this.showRecentMerchants = false;
            this.showClear = true;
            return this.getFilteredMerchants(q);
          } else {
            this.showRecentMerchants = true;
            return EMPTY;
          }
        }),
      )
      .subscribe((result) => {
        if (result) {
          this.showRecentMerchants = false;
          this.filteredMerchants.next(result);
        } else {
          this.showRecentMerchants = true;
          this.filteredMerchants.next([]);
        }
      });
  }

  private initializeInput() {
    this._accountsService.getAccountById(this.initialAccountId).subscribe((result) => {
      if (result && result.id === this.initialAccountId) {
        this.searchForm.controls['searchInput'].setValue(result);
        this.merchantSelected(null, result);
      }
    });
  }

  public onFocus(): void {
    const searchValue: string = this.merchantSelectorInput.nativeElement.value;
    if (searchValue && searchValue.trim().length > 0) {
      this.showRecentMerchants = false;
    } else {
      this.getRecentMerchantAccounts();
    }
  }

  public deleteRecentMerchant(id: string): void {
    let filteredList = this.recentlyViewedMerchants.value.filter((a) => a.account_id != id);
    this._userLocalDataService.updateRecentAccounts(filteredList, this._authService.user.id).subscribe((result) => {
      this.recentlyViewedMerchants.next(result.recent_accounts);
      this.showRecentMerchants = true;
    });

    this.merchantSelectorInput.nativeElement.focus();
  }

  public displayFn(account: Account) {
    return account?.name;
  }

  private getFilteredMerchants(q: string): Observable<Account[]> {
    if (!q) {
      // just emit null so the search clears and sets back to
      // the partner account id.
      this.setMerchantAccount.emit(null);
      return;
    }

    const listConnectedAccountsParams: ListConnectedAccountsRequestParams = {
      tilledAccount: this.parentAccountId || AuthService.getCurrentAccountId(),
      q: q,
      type: Account.TypeEnum.MERCHANT,
      metadata: { name: 'asc' },
      offset: 0,
      limit: 50,
    };

    return this._accountsService.filterAccountsSelector(listConnectedAccountsParams);
  }

  public disableMerchantSelected(): void {}
  public merchantSelected(event: MatAutocompleteSelectedEvent, initialAccount?: Account): void {
    this.showClear = true;

    // If this is a locally stored account we will need to retrieve it from the
    // api before emitting the event
    if (event?.option?.value?.account_id && typeof event.option.value.account_id === 'string') {
      this._accountsService.getAccountById(event.option?.value?.account_id).subscribe({
        next: (result) => {
          this.setMerchantAccount.emit(result);
        },
        error: (err) => {
          // An account id from recent history may not exist anymore or may have changed.
          this.clearSearchText();
          const message: TilledAlert = {
            message: 'Account Id not found',
            title: '',
            type: 'error',
            timer: 8000,
          };
          this._alertService.showAlert(message);
          return;
        },
      });
    } else {
      if (initialAccount?.id) {
        this.selectedAccount = initialAccount;
      } else {
        this.selectedAccount = event.option.value;
      }
      this.setMerchantAccount.emit(this.selectedAccount);
      this.addRecentlyViewedMerchants(this.selectedAccount);
    }

    (this.merchantSelectorInput.nativeElement as HTMLElement).blur();
  }

  public clearSearchValue(): void {
    this.filterService.clearFilterButton(this.filterName);
    this.showRecentMerchants = false;
  }

  public clearSearchText(): void {
    this.selectedAccount = null;
    this.showClear = false;
    this.searchForm.patchValue({ searchInput: null });
    this.setMerchantAccount.emit(null);
  }

  private getRecentMerchantAccounts(): void {
    this._userLocalDataService.getRecentAccounts(this._authService.user.id).subscribe((result) => {
      if (result && result.recent_accounts?.length > 0) {
        result.recent_accounts = result.recent_accounts.filter((m) => {
          return m?.type === Account.TypeEnum.MERCHANT;
        });
        result.recent_accounts.sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));
        this.recentlyViewedMerchants.next(result.recent_accounts);
      }
    });
  }

  public merchantTooltip(id: string, owner: string): string {
    let tooltip = '';
    if (id && id.length > 0) {
      tooltip = 'Id: ' + id + `\n`;
    }
    if (owner && owner.length > 0) {
      tooltip += 'Owner: ' + owner;
    }
    return tooltip;
  }

  // Set recent merchants to storage
  private addRecentlyViewedMerchants(account: Account): void {
    const ownerName =
      account.business_profile?.representatives[0]?.first_name +
      ' ' +
      account.business_profile?.representatives[0]?.last_name;
    if (this.recentlyViewedMerchants && this.recentlyViewedMerchants.value.length >= 10) {
      this.removeOldestMerchant();
    }

    if (
      account &&
      account.id &&
      this.recentlyViewedMerchants.value.length >= 0 &&
      this.recentlyViewedMerchants.value.filter((m) => {
        return m.account_id === account.id;
      }).length === 0
    ) {
      let updatedList = this.recentlyViewedMerchants.value;
      updatedList.push({
        account_id: account.id,
        type: account.type,
        street: account.business_profile?.address?.street,
        name: account.name,
        owner_name: ownerName,
      });

      this._userLocalDataService.updateRecentAccounts(updatedList, this._authService.user.id).subscribe((result) => {
        this.recentlyViewedMerchants.next(result.recent_accounts);
      });
    }
  }

  private removeOldestMerchant(): void {
    this.recentlyViewedMerchants.value.slice(1);
  }
}
