/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BLEBankAccount { 
    /**
     * The name of the person or business that owns the bank account.
     */
    account_holder_name?: string;
    /**
     * This is the bank account number
     */
    account_number: string;
    /**
     * Name of the financial institution.
     */
    bank_name?: string;
    /**
     * The routing number, sort code, or other country-appropriate institution number for the bank account. For US bank accounts, this is required and should be the 9-digit ACH routing number, not the wire routing number. For CA bank accounts, combine the transit and institution numbers to create a full routing number (for example,  if `transit = 12345` and `institution = 678`, then `routing_number = 12345678`).
     */
    routing_number: string;
    /**
     * The bank account type
     */
    type?: BLEBankAccount.TypeEnum;
}
export namespace BLEBankAccount {
    export type TypeEnum = 'checking' | 'savings';
    export const TypeEnum = {
        CHECKING: 'checking' as TypeEnum,
        SAVINGS: 'savings' as TypeEnum
    };
}


