import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule, TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { NorthApplicationSteps, PaysafeApplicationSteps, TsysApplicationSteps } from 'app/core/data/onboarding-types';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { SnakeCaseSplitPipe } from 'app/core/pipes/snake-case-split.pipe';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { AddRepresentativeDialogComponent } from 'app/shared/merchant-app-steps/representatives/add-representative-dialog/add-representative-dialog.component';
import { TilledParagraphP4Component } from 'app/shared/tilled-text';
import { checkSsnRequired } from 'app/shared/utils/onboarding-utils';
import { isEmail } from 'app/shared/validators/email.validator';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, Subscription, takeUntil } from 'rxjs';
import {
  OnboardingApplication,
  PricingTemplate,
  PrincipalCreateParams,
  ProcessingCapability,
} from '../../../../../projects/tilled-api-client/src';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { MerchantAppAlertComponent } from '../../cards/merchant-application/merchant-app-alert/merchant-app-alert.component';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { RepresentativeRequirements } from './representative-requirements';

@Component({
  selector: 'representatives-step',
  templateUrl: './representatives-step.component.html',
  styleUrls: ['./representatives-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    MerchantAppAlertComponent,
    FuseAlertComponent,
    MatIconModule,
    TilledParagraphP3Component,
    TilledInputComponent,
    MatIconModule,
    AsyncPipe,
    TilledParagraphP4Component,
    CommonModule,
    SnakeCaseSplitPipe,
    TitleCasePipe,
    MatTooltipModule,
  ],
})
export class RepresentativesStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() inviteApplicant: EventEmitter<{ applicantInvite: boolean; emailExists: boolean; applicant: any }> =
    new EventEmitter<{
      applicantInvite: boolean;
      emailExists: boolean;
      applicant: any;
    }>();

  public representativesFormArray: FormArray = new FormArray([]);
  public hiddenForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public businessEntityType: any;
  public title: string;
  public secondaryText: string;
  public busOwnerStep: boolean = false;
  public missingFields: any[] = [];

  private subscriptions: Subscription[] = [];
  public isCanadian: boolean;
  public requirements: string[];
  public principals: PrincipalCreateParams[];
  private representativeRequirements: RepresentativeRequirements = new RepresentativeRequirements();
  private _controlProngIndex$ = new BehaviorSubject<number>(-1);
  public controlProngIndex$ = this._controlProngIndex$.asObservable();
  private _applicantIndex$ = new BehaviorSubject<number>(-1);
  public applicantIndex$ = this._applicantIndex$.asObservable();
  public hasTsysProvider: boolean = false;
  public hasNorthProvider: boolean = false;
  public multipleOwners: boolean = false;
  public dialogRef: any;
  public isWhiteLabel$: Observable<boolean>;
  public totalOwnership: number = 0;

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _alertService: AlertService,
    private _brandingService: BrandingService,
    private _matDialog: MatDialog,
  ) {
    super();
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
  }

  ngOnInit(): void {
    // used for hidden element to define button width
    this.hiddenForm = this._formBuilder.group({
      hidden: new FormControl<boolean>(true),
    });
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });
    this.businessEntityType = this.representativeRequirements.requirements.find(
      (entityType) => entityType.type === this.merchantApp.legal_entity?.structure,
    );

    if (this.businessEntityType) {
      this.requirements =
        this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.USD
          ? this.businessEntityType.usReq
          : this.businessEntityType.caReq;
    }

    this._merchantAppService.hasTsysProvider$.subscribe((hasTsys) => {
      this.hasTsysProvider = hasTsys;
    });
    this._merchantAppService.hasNorthProvider$.subscribe((hasNorth) => {
      this.hasNorthProvider = hasNorth;
    });
    this._merchantAppService.multipleOwners$.subscribe((multipleOwners) => {
      this.multipleOwners = multipleOwners;
    });
    this._merchantAppService.updateProviderData();
    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(false);
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }
  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  addApplicant(applicant?: string): void {
    const accountId = this.forConsole ? this._route.snapshot.params.id : AuthService.getCurrentAccountId();
    let principal: PrincipalCreateParams;
    if (applicant) {
      principal = this.principals.find((p) => p.id === applicant);
    }
    this.dialogRef = this._matDialog.open(AddRepresentativeDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        accountId: accountId,
        forConsole: this.forConsole,
        stepNumber: this.stepNumber,
        disabled$: this.disabled$,
        saveApp$: this.saveApp$,
        checkUnsavedApp$: this.checkUnsavedApp$,
        resetApp$: this.resetApp$,
        currentPrincipal: principal || null,
      },
    });
    this.dialogRef.afterClosed().subscribe((result: OnboardingApplication) => {
      if (result) {
        this.merchantApp = result;
        this.missingFields = [];
        this._changeDetectorRef.markForCheck();
      }
    });
  }

  getPrincipalFormGroup(data: PrincipalCreateParams, index: number): FormGroup {
    data = data || ({} as PrincipalCreateParams);
    let showMissingFields = false;
    const provider = this.hasNorthProvider
      ? ProcessingCapability.ProviderEnum.NORTH
      : this.hasTsysProvider
        ? ProcessingCapability.ProviderEnum.TSYS
        : ProcessingCapability.ProviderEnum.PAYSAFE;

    if (data.is_applicant) {
      this._applicantIndex$.next(index);
    }
    if (data.is_control_prong) {
      this._controlProngIndex$.next(index);
    }

    this.missingFields.push(
      {
        id: data.id || null,
        name: 'Job Title',
        error: !data.job_title ? true : false,
        last: false,
      },
      {
        id: data.id || null,
        name: 'Date of Birth',
        error: !data.date_of_birth ? true : false,
        last: false,
      },
      {
        id: data.id || null,
        name: 'Social Security Number',
        error: !data.id_number && checkSsnRequired(provider, this.merchantApp?.legal_entity?.structure) ? true : false,
        last: false,
      },
      {
        id: data.id || null,
        name: 'Email',
        error: !data.email ? true : false,
        last: false,
      },
      {
        id: data.id || null,
        name: 'Phone Number',
        error: !data.phone ? true : false,
        last: false,
      },
      {
        id: data.id || null,
        name: 'Ownership Percentage',
        error: !(data?.percent_ownership >= 0) && !data.is_applicant ? true : false,
        last: false,
      },
      {
        id: data.id || null,
        name: 'Address',
        error: !data.address?.street ? true : false,
        last: false,
      },
    );
    this.missingFields = this.missingFields.filter((field) => field.error === true);

    // filter duplicate missing fields
    this.missingFields = this.missingFields.filter(
      (field, index, self) => index === self.findIndex((t) => t.id === field.id && t.name === field.name),
    );

    if (this.missingFields.length >= 1) {
      this.missingFields[this.missingFields.length - 1].last = true;
      if (this.missingFields.find((field) => field.id === data.id)) {
        showMissingFields = true;
      }
    }

    return new FormGroup({
      id: new FormControl<string | null>(data.id || null),
      isApplicant: new FormControl<boolean>(data.is_applicant || false),
      isControlProng: new FormControl<boolean>(data.is_control_prong || false),
      firstName: new FormControl<string | null>(data.first_name || null, [Validators.required]),
      lastName: new FormControl<string | null>(data.last_name || null, [Validators.required]),
      jobTitle: new FormControl<string | null>(data.job_title || null),
      email: new FormControl<string | null>(data.email || null, [isEmail()]),
      percentageShareholding: new FormControl<number | null>(data.percent_ownership || null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      displayMissingFields: new FormControl<boolean>(showMissingFields),
    });
  }

  onEditClicked(event: string): void {
    if (this.hasTsysProvider) {
      this._merchantAppService.updateCurrentStep(TsysApplicationSteps.BUSINESS_TYPE_SUB_STEP);
    } else if (this.hasNorthProvider) {
      this._merchantAppService.updateCurrentStep(NorthApplicationSteps.BUSINESS_TYPE_SUB_STEP);
    } else {
      this._merchantAppService.updateCurrentStep(PaysafeApplicationSteps.BUSINESS_TYPE_SUB_STEP);
    }
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(this.stepNumber - 1);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const tilledAlert = document.querySelector('merchant-app-alert');
    if (!tilledAlert) {
      return;
    }
    this.scrollTo(tilledAlert);
  }

  onContinueClicked(accountId?: string): void {
    // Needed to increment by 0.1 to avoid issues with the step number being a float.
    let subIncrement = (this.stepNumber + 0.1).toFixed(1);

    // This skips the business owners step when business owners are not required.
    if (!this.busOwnerStep && !this.multipleOwners) {
      subIncrement = (this.stepNumber + 0.2).toFixed(1); //
    }

    let totalOwnership = 0;
    for (const principal of this.representativesFormArray.value) {
      totalOwnership += parseInt(principal.percentageShareholding, 10);
    }

    if (totalOwnership > 100) {
      const message: TilledAlert = {
        message: 'The total ownership percent of all application signers must not exceed 100%',
        title: 'Application signers',
        type: 'error',
      };
      this._alertService.showAlert(message);
      this.representativesFormArray.controls[0].get('percentageShareholding').setErrors({ exists: true });
    }

    this.representativesFormArray.markAllAsTouched();
    if (this.representativesFormArray.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }
    this._merchantAppService.updateMerchantApplication(this.merchantApp, parseFloat(subIncrement), accountId);
  }

  private resetApplication(): void {
    this.representativesFormArray = new FormArray([]);

    this.businessEntityType = this.representativeRequirements.requirements.find(
      (entityType) => entityType.type === this.merchantApp.legal_entity?.structure,
    );

    if (this.businessEntityType) {
      this.requirements =
        this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.USD
          ? this.businessEntityType.usReq
          : this.businessEntityType.caReq;
    }

    this.principals = this.merchantApp.legal_entity?.principals;
    this.principals?.forEach((principal, index) =>
      this.representativesFormArray.push(this.getPrincipalFormGroup(principal, index)),
    );

    if (!this.principals || this.principals.length === 0) {
      this.representativesFormArray.push(this.getPrincipalFormGroup(null, 0));
    }
    this.isCanadian = this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.CAD;
    this.stepConfig(this.stepNumber);

    this.totalOwnership = 0;
    for (const principal of this.representativesFormArray.value) {
      this.totalOwnership += parseInt(principal.percentageShareholding, 10);
    }

    this._changeDetectorRef.markForCheck();
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
  public stepConfig(step: number): void {
    switch (step) {
      case PaysafeApplicationSteps.APPLICATION_SIGNER_SUB_STEP:
      case TsysApplicationSteps.APPLICATION_SIGNER_SUB_STEP:
      case NorthApplicationSteps.APPLICATION_SIGNER_SUB_STEP:
        this.title = 'Application signer';
        this.busOwnerStep = false;
        break;
      case PaysafeApplicationSteps.BUSINESS_OWNERS_SUB_STEP:
      case TsysApplicationSteps.BUSINESS_OWNERS_SUB_STEP:
      case NorthApplicationSteps.BUSINESS_OWNERS_SUB_STEP:
        this.title = 'Business owners';
        this.secondaryText =
          'To comply with US regulations around anti-money laundering (AML), ' +
          'we are required to collect the personal information of anyone with significant ownership in this business.';
        this.busOwnerStep = true;
        break;
    }
  }
}
