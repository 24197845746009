import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, interval, lastValueFrom, of } from 'rxjs';
import { catchError, defaultIfEmpty, filter, switchMap, take, tap } from 'rxjs/operators';
import {
  CreateMiddeskBusinessRequestParams,
  InternalService,
  MiddeskBusiness,
  UpdateMiddeskBusinessRequestParams,
} from '../../../../projects/tilled-api-client/src';

@Injectable({
  providedIn: 'root',
})
export class MiddeskAppService {
  private _middeskBusinessSubject = new BehaviorSubject<MiddeskBusiness | null>(null);
  public middeskBusiness$: Observable<MiddeskBusiness | null> = this._middeskBusinessSubject.asObservable();

  constructor(private _internalService: InternalService) {}

  public getMiddeskBusiness(accountId: string): void {
    this._internalService
      .getMiddeskBusiness({ tilledAccount: accountId })
      .pipe(
        take(1),
        tap((business) => this._middeskBusinessSubject.next(business)),
        catchError(() => {
          this._middeskBusinessSubject.next(null);
          return of(null);
        }),
      )
      .subscribe();
  }

  public async createMiddeskBusiness(params: CreateMiddeskBusinessRequestParams): Promise<MiddeskBusiness | null> {
    const response = (await lastValueFrom(
      this._internalService.createMiddeskBusiness(params),
    )) as MiddeskBusinessWithVerification;

    if (
      response?.verification?.legalNameStatus &&
      response?.verification?.tinStatus &&
      response?.verification?.addressStatus
    ) {
      return response;
    }

    // Poll for verification status, try 3 times with a 500ms interval
    return firstValueFrom(
      interval(500).pipe(
        take(3),
        switchMap(() => this._internalService.getMiddeskBusiness({ tilledAccount: params.tilledAccount })),
        filter((business: MiddeskBusinessWithVerification) => {
          return (
            business?.verification?.legalNameStatus !== undefined &&
            business?.verification?.tinStatus !== undefined &&
            business?.verification?.addressStatus !== undefined
          );
        }),
        defaultIfEmpty(response),
        catchError(() => {
          return of(null);
        }),
      ),
    );
  }

  public async updateMiddeskBusiness(params: UpdateMiddeskBusinessRequestParams): Promise<MiddeskBusiness | null> {
    return await firstValueFrom(
      this._internalService.updateMiddeskBusiness(params).pipe(
        tap((business) => this._middeskBusinessSubject.next(business)),
        catchError(() => {
          this._middeskBusinessSubject.next(null);
          return of(null);
        }),
      ),
    );
  }
}

export interface MiddeskBusinessWithVerification extends MiddeskBusiness {
  verification: MiddeskBusinessVerification;
}

export interface MiddeskBusinessVerification {
  legalNameVerified: boolean;
  legalNameStatus?: LegalNameVerificationStatus;
  tinVerified: boolean;
  tinStatus?: TinVerificationStatus;
  addressVerified: boolean;
  addressStatus?: AddressVerificationStatus;
}

export enum LegalNameVerificationStatus {
  VERIFIED = 'Verified',
  SIMILAR_MATCH = 'Similar Match',
  ALTERNATE_NAME = 'Alternate Name',
  UNVERIFIED = 'Unverified',
}

export enum TinVerificationStatus {
  FOUND = 'Found',
  WARNING = 'Warning',
  MISMATCH = 'Mismatch',
  NOT_FOUND = 'Not Found',
  ERROR = 'Error',
}

export enum AddressVerificationStatus {
  VERIFIED = 'Verified',
  APPROXIMATE_MATCH = 'Approximate Match',
  SIMILAR_MATCH = 'Similar Match',
  INCOMPLETE_MATCH = 'Incomplete Match',
  UNVERIFIED = 'Unverified',
}
