/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EsignatureDocument { 
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The status of the Esignature Document.
     */
    status: EsignatureDocument.StatusEnum;
}
export namespace EsignatureDocument {
    export type StatusEnum = 'sent' | 'completed';
    export const StatusEnum = {
        SENT: 'sent' as StatusEnum,
        COMPLETED: 'completed' as StatusEnum
    };
}


