import { AsyncPipe, CommonModule, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT, PAYMENTS_ROUTE } from 'app/core/constants';
import { CustomColumn } from 'app/core/models/custom-column';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { PaymentStatusPipe } from 'app/core/pipes/payment-status.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { PaymentAppService } from 'app/core/services/payments.app.service';
import { RefundsAppService } from 'app/core/services/refunds.app.service';
import { RefundFormDialogComponent } from 'app/modules/payments/refund-form-dialog/refund-form-dialog.component';
import { CustomColumnComponent } from 'app/shared/custom-column/custom-column.component';
import { CancelPaymentDialogComponent } from 'app/shared/payments/cancel-payment-dialog/cancel-payment-dialog.component';
import { CapturePaymentDialogComponent } from 'app/shared/payments/capture-payment-dialog/capture-payment-dialog.component';
import { Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  CardDetails,
  GetPaymentIntentRequestParams,
  InternalListPaymentIntentsRequestParams,
  PaymentMethod,
  Refund,
  TerminalReader,
} from '../../../../projects/tilled-api-client/src';
import { InternalPaymentIntent } from '../../../../projects/tilled-api-client/src/model/internalPaymentIntent';
import { ActionListItem } from '../action-list/action-list.model';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';
import { TilledTableComponent } from '../tilled-table/tilled-table.component';

const cancellableStatuses = [
  InternalPaymentIntent.StatusEnum.REQUIRES_PAYMENT_METHOD,
  InternalPaymentIntent.StatusEnum.REQUIRES_CAPTURE,
  InternalPaymentIntent.StatusEnum.REQUIRES_CONFIRMATION,
  InternalPaymentIntent.StatusEnum.REQUIRES_ACTION,
];

export const CURRENT_PAYMENT_CUSTOM_COLUMN_KEY = 'tilled-payments-custom-column';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TilledTableComponent, CustomColumnComponent, MatIcon, MatMenuModule, MatTooltip, AsyncPipe, CommonModule],
})
export class PaymentListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() partnerAccountId: string = null;
  @Input() isMerchant: boolean;
  @Input() queryMerchantId: string = null;
  @Input() queryStatus: string = null; // using chipConfig text instead of actual status
  @Input() queryAmount: string = null;
  @Input() queryCardholder: string = null;
  @Input() queryPaymentMethod: string = null;
  @Input() queryPaymentMethodType: PaymentMethod.TypeEnum[] = null;
  @Input() queryCustomer: string = null;
  @Input() initialStartDate: string = null;
  @Input() initialEndDate: string = null;
  @Input() startDate: string = null;
  @Input() terminalReaderId: string = null;
  @Input() endDate: string = null;
  @Input() customerId: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_LIMIT;
  @Input() merchantDetails: boolean = false;
  @Input() updateQueryCallback: (
    startDate?: string,
    endDate?: string,
    queryMerchantId?: string,
    queryStatus?: string,
    page?: number,
    size?: number,
    queryAmount?: string,
    queryCardholder?: string,
    queryPaymentMethod?: string,
    queryCustomer?: string,
    queryPaymentMethodType?: PaymentMethod.TypeEnum[],
  ) => void;

  public secondaryReasonText = 'Process a payment to see payment data.';
  public dialogRef: any;
  public updatedPayment$: Observable<InternalPaymentIntent>;
  public paymentsViewModel$: Observable<PaymentViewModel[]>;
  public currentlyRefundedPayment: InternalPaymentIntent;
  public refund$: Observable<Refund>;
  public refundCreated$: Observable<boolean>;
  public paymentsCount$: Observable<number>;
  public selectedPayment: InternalPaymentIntent;
  public selectedActionList: ActionListItem[];
  public hideColumnKeys: PaymentListViewModel[] = [];
  public sortColumnKeys: PaymentListViewModel[] = [];
  public sortInfo = null;
  public noData = false;
  public customColumns: CustomColumn[] = [];
  updateTableCallback = function (columns: CustomColumn[]): void {
    this.customColumns = columns;
    this.updateTable();
  }.bind(this);

  constructor(
    private _paymentAppService: PaymentAppService,
    private _paymentStatusPipe: PaymentStatusPipe,
    private _dateFormatPipe: DateFormatPipe,
    private _refundsAppService: RefundsAppService,
    private _router: Router,
    private _matDialog: MatDialog,
    private _authService: AuthService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.getPaymentIntents(this.pageSize, this.pageIndex);
    this.setTableType();
    if (this.isMerchant) {
      this.hideColumnKeys = ['merchant_name', 'currency'];
    }
    this.hideColumnKeys.push('transaction_id');
    this._paymentAppService.payments$
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((result) => {
          this.updatePaymentsViewModel(result);
        }),
      )
      .subscribe();
    this.paymentsCount$ = this._paymentAppService.paymentsCount$;

    this.updatedPayment$ = this._paymentAppService.payment$;
    this.updatedPayment$.pipe(takeUntil(this._unsubscribeAll)).subscribe((payment) => {
      this.selectedPayment = payment;
      this.updateSelectedPayment(payment);
    });

    this.refund$ = this._refundsAppService.refund$;
    this.refundCreated$ = this._refundsAppService.refundCreated$;

    this.refundCreated$
      .pipe(
        tap((bool) => {
          this.updatePaymentIntentFromRefund(bool);
        }),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();

    this.buildInputColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasRelevantChanges(changes)) return;

    this.getPaymentIntents(this.pageSize, 0);
  }

  updatePaymentIntentFromRefund = (bool: boolean): void => {
    if (bool) {
      const params: GetPaymentIntentRequestParams = {
        tilledAccount: this.currentlyRefundedPayment.account_id,
        id: this.currentlyRefundedPayment.id,
      };
      this._paymentAppService.getPaymentIntent(params);
    }
  };

  getPaymentIntents = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    const params: InternalListPaymentIntentsRequestParams = {
      tilledAccount: this.queryMerchantId ?? this.accountId,
      createdAtGte: this.startDate ? new Date(this.startDate).toString() : null,
      createdAtLte: this.endDate ? new Date(this.endDate).toString() : null,
      amount: this.queryAmount || null,
      paymentMethodName: this.queryCardholder || null,
      customerName: this.queryCustomer || null,
      includeConnectedAccounts: !this.isMerchant,
      offset: size * index || 0,
      limit: size || DEFAULT_PAGE_LIMIT,
      customerId: this.customerId,
      terminalReaderId: this.terminalReaderId,
    };

    //Check to see if paymentMethod is last2, last4, or paymentMethodId
    if (this.queryPaymentMethod) {
      if (this.queryPaymentMethod.length === 2 && !isNaN(Number(this.queryPaymentMethod))) {
        params.paymentMethodLast2 = this.queryPaymentMethod;
      } else if (this.queryPaymentMethod.length === 4 && !isNaN(Number(this.queryPaymentMethod))) {
        params.paymentMethodLast4 = this.queryPaymentMethod;
      } else {
        params.paymentMethodId = this.queryPaymentMethod;
      }
    }

    //Bank-to-bank convers all Debit, ensure EFT is included on the api call
    params.paymentMethodType = this.queryPaymentMethodType;
    if (
      params.paymentMethodType?.includes(PaymentMethod.TypeEnum.ACH_DEBIT) &&
      !params.paymentMethodType?.includes(PaymentMethod.TypeEnum.EFT_DEBIT)
    ) {
      params.paymentMethodType.push(PaymentMethod.TypeEnum.EFT_DEBIT);
    }

    this._paymentAppService.getAllPaymentIntents(params);

    if (
      this.queryMerchantId ||
      this.startDate ||
      this.endDate ||
      this.queryAmount ||
      this.queryCardholder ||
      this.queryStatus ||
      this.queryPaymentMethodType
    ) {
      this.secondaryReasonText = 'Update the filters to see results.';
    } else {
      this.secondaryReasonText = 'Process a payment to see payment data.';
    }
  };

  setTableType(): void {
    if (this.merchantDetails) {
      this.hideColumnKeys = ['merchant_name'];
    }
  }

  convertPaymentToViewModel(payment: InternalPaymentIntent, isMerchant: boolean): PaymentViewModel {
    const succeededCharge = payment.charges.find((c) => c.status === 'succeeded');
    const pendingCharges = payment.charges.find((c) => c.status === 'pending');
    const amountRefunded =
      succeededCharge?.amount_refunded || pendingCharges?.amount_refunded || payment.charges?.[0]?.amount_refunded || 0;
    const temp: PaymentViewModel = new PaymentViewModel();
    temp.payment = payment;
    temp.created_at = this._dateFormatPipe.transform(payment.created_at);
    temp.id = payment.id;
    temp.merchant_id = payment.account_id;
    temp.merchant_name = payment.account_name;
    temp.cardholder = this.getCardHolderName(payment);
    if (payment.payment_method?.ach_debit) {
      temp.last_4 = '**' + payment.payment_method.ach_debit.last2;
    } else if (payment.payment_method?.card) {
      temp.last_4 = payment.payment_method.card.last4;
    } else if (payment.payment_method?.card_present) {
      temp.last_4 = payment.payment_method.card_present.last4;
    }
    temp.transaction_id = payment.id;
    temp.currency = new UpperCasePipe().transform(payment.currency);
    temp.amount = payment.amount;
    temp.status = payment.status;
    temp.status_chip_config = this._paymentStatusPipe.transform(payment, false);
    temp.action =
      !payment.charges.find((c) => c.status === 'succeeded') ||
      !this._authService.isScopeAble('refunds:write') ||
      (temp.status_chip_config.text !== 'SUCCEEDED' && temp.status_chip_config.text !== 'PARTIAL REFUND')
        ? []
        : [
            {
              name: 'Initiate refund',
              callback: (): void => {
                this.createRefund(payment);
              },
              disabled: false,
            },
          ];
    if (payment.status === 'requires_capture') {
      temp.action = temp.action.concat([
        {
          name: 'Capture',
          callback: (): void => {
            this.capturePayment(payment);
          },
          disabled: false,
        },
      ]);
    }
    if (cancellableStatuses.includes(payment.status)) {
      temp.action = temp.action.concat([
        {
          name: 'Cancel',
          callback: (): void => {
            this.cancelPayment(payment);
          },
          disabled: false,
        },
      ]);
    }
    temp.action = temp.action.concat([
      {
        name: 'View payment details',
        callback: (): void => {
          this._router.navigate([`/${payment.account_id}/${PAYMENTS_ROUTE}/${payment.id}`]);
        },
        disabled: false,
      },
    ]);
    if (!isMerchant) {
      temp.action = temp.action.concat([
        {
          name: 'View merchant',
          callback: (): void => {
            this._router.navigate([`/merchants/${payment.account_id}`]);
          },
          disabled: false,
        },
      ]);
    }
    switch (payment.payment_method?.type) {
      case PaymentMethod.TypeEnum.CARD:
        switch (payment.payment_method?.card?.brand) {
          case CardDetails.BrandEnum.AMEX:
            temp.transaction_type = 'assets/images/payment-methods/amex.svg';
            break;
          case CardDetails.BrandEnum.DINERS:
            temp.transaction_type = 'assets/images/payment-methods/diners.svg';
            break;
          case CardDetails.BrandEnum.DISCOVER:
            temp.transaction_type = 'assets/images/payment-methods/discover.svg';
            break;
          case CardDetails.BrandEnum.JCB:
            temp.transaction_type = 'assets/images/payment-methods/jcb.svg';
            break;
          case CardDetails.BrandEnum.MAESTRO:
            temp.transaction_type = 'assets/images/payment-methods/maestro.svg';
            break;
          case CardDetails.BrandEnum.MASTERCARD:
            temp.transaction_type = 'assets/images/payment-methods/mastercard.svg';
            break;
          case CardDetails.BrandEnum.SOLO:
            temp.transaction_type = 'assets/images/payment-methods/solo.svg';
            break;
          case CardDetails.BrandEnum.VISA || CardDetails.BrandEnum.VISA_DEBIT || CardDetails.BrandEnum.VISA_ELECTRON:
            temp.transaction_type = 'assets/images/payment-methods/visa.svg';
            break;
          default:
            temp.transaction_type = 'assets/images/payment-methods/default-card.svg';
        }
        break;
      case PaymentMethod.TypeEnum.CARD_PRESENT:
        switch (payment.payment_method?.card_present?.brand) {
          case CardDetails.BrandEnum.AMEX:
            temp.transaction_type = 'assets/images/payment-methods/amex.svg';
            break;
          case CardDetails.BrandEnum.DINERS:
            temp.transaction_type = 'assets/images/payment-methods/diners.svg';
            break;
          case CardDetails.BrandEnum.DISCOVER:
            temp.transaction_type = 'assets/images/payment-methods/discover.svg';
            break;
          case CardDetails.BrandEnum.JCB:
            temp.transaction_type = 'assets/images/payment-methods/jcb.svg';
            break;
          case CardDetails.BrandEnum.MAESTRO:
            temp.transaction_type = 'assets/images/payment-methods/maestro.svg';
            break;
          case CardDetails.BrandEnum.MASTERCARD:
            temp.transaction_type = 'assets/images/payment-methods/mastercard.svg';
            break;
          case CardDetails.BrandEnum.SOLO:
            temp.transaction_type = 'assets/images/payment-methods/solo.svg';
            break;
          case CardDetails.BrandEnum.VISA || CardDetails.BrandEnum.VISA_DEBIT || CardDetails.BrandEnum.VISA_ELECTRON:
            temp.transaction_type = 'assets/images/payment-methods/visa.svg';
            break;
          default:
            temp.transaction_type = 'assets/images/payment-methods/default-card.svg';
        }
        break;
      case PaymentMethod.TypeEnum.ACH_DEBIT:
        temp.transaction_type = 'assets/images/payment-methods/ach-eft.svg';
        break;
      case PaymentMethod.TypeEnum.EFT_DEBIT:
        temp.transaction_type = 'assets/images/payment-methods/ach-eft.svg';
        break;
    }
    return temp;
  }

  getViewModelsFromPayments(payments: InternalPaymentIntent[]): PaymentViewModel[] {
    const viewModels: PaymentViewModel[] = [];
    if (!payments || payments.length === 0) {
      const temp: PaymentViewModel = new PaymentViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const payment of payments) {
      let viewModel = this.convertPaymentToViewModel(payment, this.isMerchant);

      viewModels.push(viewModel);
    }
    if (!this.queryStatus) return viewModels;
    let filteredViewModels: PaymentViewModel[] = [...viewModels];

    if (this.queryStatus) {
      filteredViewModels = filteredViewModels.filter(
        (item) => item?.chipConfig?.text === this.queryStatus.toUpperCase(),
      );
    }

    if (filteredViewModels.length === 0) {
      this.noData = true;
      filteredViewModels = [new PaymentViewModel()];
    }

    return filteredViewModels;
  }

  rowClickedCallback = (data: PaymentViewModel, event?: MouseEvent): void => {
    const url = `${data.merchant_id}/${PAYMENTS_ROUTE}/${data.id}`;

    // Open a new window (Shift) or tab (Ctrl/Option)
    if (event?.shiftKey || event?.ctrlKey || event?.metaKey) {
      window.open(url, event?.shiftKey ? '_blank' : undefined);
    } else {
      this._router.navigate([url]);
    }
  };

  public getCardHolderName(payment: InternalPaymentIntent): string {
    if (payment.payment_method?.type === PaymentMethod.TypeEnum.CARD) {
      return payment.payment_method?.card
        ? payment.payment_method?.card?.holder_name
          ? payment.payment_method?.card?.holder_name
          : '-'
        : payment.payment_method?.billing_details?.name
          ? payment.payment_method?.billing_details?.name
          : '-';
    } else if (payment.payment_method?.type === PaymentMethod.TypeEnum.CARD_PRESENT) {
      return payment.payment_method?.card_present
        ? payment.payment_method?.card_present?.holder_name
          ? payment.payment_method?.card_present?.holder_name
          : '-'
        : payment.payment_method?.billing_details?.name
          ? payment.payment_method?.billing_details?.name
          : '-';
    }
    return payment.payment_method?.billing_details?.name ? payment.payment_method?.billing_details?.name : '-';
  }

  public createRefund = (payment: InternalPaymentIntent): void => {
    this.currentlyRefundedPayment = payment;
    this.dialogRef = this._matDialog.open(RefundFormDialogComponent, {
      panelClass: 'refund-dialog',
      disableClose: true,
      width: '550px',
      data: {
        action: 'new',
        id: payment.id,
        accountId: payment.account_id,
        payment: payment,
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {});
  };

  public capturePayment = (payment: InternalPaymentIntent): void => {
    this.dialogRef = this._matDialog.open(CapturePaymentDialogComponent, {
      panelClass: 'capture-dialog',
      disableClose: false,
      width: '550px',
      data: {
        action: 'capture',
        id: payment.id,
        accountId: payment.account_id,
        payment: payment,
      },
    });
  };

  public cancelPayment = (payment: InternalPaymentIntent): void => {
    this.dialogRef = this._matDialog.open(CancelPaymentDialogComponent, {
      panelClass: 'capture-dialog',
      disableClose: false,
      width: '550px',
      data: {
        action: 'cancel',
        id: payment.id,
        accountId: payment.account_id,
        payment: payment,
      },
    });
  };

  updateTable(): void {
    this.sortColumnKeys = this.customColumns
      .filter((c) => c.active && !c.hidden)
      .map((c) => c.columnKey as keyof PaymentViewModel);
    this.hideColumnKeys = this.customColumns
      .filter((c) => !c.active || c.hidden)
      .map((c) => c.columnKey as keyof PaymentViewModel);
    localStorage.setItem(CURRENT_PAYMENT_CUSTOM_COLUMN_KEY, JSON.stringify(this.customColumns));
  }

  buildInputColumns(): void {
    try {
      const existingColumns = JSON.parse(localStorage.getItem(CURRENT_PAYMENT_CUSTOM_COLUMN_KEY));
      if (existingColumns) {
        this.customColumns = existingColumns;
      }
    } catch (err) {
      //just start fresh if local storage is corrupted
    }

    const defaultCustomColumns = [
      {
        columnKey: 'created_at',
        columnDescription: 'Transaction date',
        editable: true,
        hidden: false,
        active: true,
      },
      {
        columnKey: 'merchant_name',
        columnDescription: 'Merchant',
        editable: true,
        hidden: false,
        active: true,
      },
      {
        columnKey: 'transaction_type',
        columnDescription: 'Payment method',
        editable: true,
        hidden: false,
        active: true,
      },
      {
        columnKey: 'cardholder',
        columnDescription: 'Cardholder',
        editable: true,
        hidden: false,
        active: true,
      },
      {
        columnKey: 'last_4',
        columnDescription: 'Number',
        editable: true,
        hidden: false,
        active: true,
      },
      {
        columnKey: 'transaction_id',
        columnDescription: 'Payment intent ID',
        editable: true,
        hidden: false,
        active: false,
      },
      {
        columnKey: 'currency',
        columnDescription: 'Currency',
        editable: true,
        hidden: false,
        active: false,
      },
      {
        columnKey: 'status',
        columnDescription: 'Status',
        editable: true,
        hidden: false,
        active: true,
      },
      {
        columnKey: 'amount',
        columnDescription: 'Amount',
        editable: true,
        hidden: false,
        active: true,
      },
    ];
    // want to ensure any new columns are included
    // note: right now this function does not remove deprecated columns
    const newColumns = defaultCustomColumns.filter(
      (dCC) => !this.customColumns?.find((cC) => cC.columnKey === dCC.columnKey),
    );

    for (const customColumn of this.customColumns) {
      const defaultColumn = defaultCustomColumns.find((dCC) => dCC.columnKey === customColumn.columnKey);
      if (defaultColumn) {
        customColumn.columnDescription = defaultColumn.columnDescription;
      }
    }

    this.customColumns.push(...newColumns);
    if (this.isMerchant || this.merchantDetails) {
      this.customColumns.find((cC) => cC.columnKey === 'merchant_name').hidden = true;
      this.customColumns.find((cC) => cC.columnKey === 'currency').hidden = true;
    } else {
      this.customColumns.find((cC) => cC.columnKey === 'merchant_name').hidden = false;
      this.customColumns.find((cC) => cC.columnKey === 'currency').hidden = false;
    }

    this.updateTable();
  }

  private hasRelevantChanges(changes: SimpleChanges): boolean {
    const relevantProps = [
      'queryMerchantId',
      'startDate',
      'endDate',
      'accountId',
      'queryAmount',
      'queryCardholder',
      'queryPaymentMethod',
      'queryPaymentMethodType',
      'queryCustomer',
      'queryStatus',
    ];
    return relevantProps.some((prop) => changes[prop] && !changes[prop].firstChange);
  }

  private updatePaymentsViewModel(payments: InternalPaymentIntent[]): void {
    this.paymentsViewModel$ = of(this.getViewModelsFromPayments(payments));
    if (this.updateQueryCallback) {
      this.updateQueryCallback(
        this.startDate,
        this.endDate,
        this.queryMerchantId,
        this.queryStatus,
        this.pageIndex || 0,
        this.pageSize || DEFAULT_PAGE_LIMIT,
        this.queryAmount,
        this.queryCustomer,
        this.queryPaymentMethod,
        this.queryCardholder,
        this.queryPaymentMethodType,
      );
      if (!this.queryStatus) this.noData = false;
    }
  }

  private updateSelectedPayment(payment: InternalPaymentIntent): void {
    this.paymentsViewModel$ = this.paymentsViewModel$.pipe(
      map((paymentViewModels) =>
        paymentViewModels.map((paymentViewModel) =>
          paymentViewModel.id !== payment.id
            ? paymentViewModel
            : this.convertPaymentToViewModel(payment, this.isMerchant),
        ),
      ),
    );
  }

  private areTipsEnabled(terminals: TerminalReader[]): boolean {
    if (!this.partnerAccountId && !this.isMerchant) {
      let terminalsThatTip = terminals?.filter((t) => t.settings?.tipping_enabled === true);
      return terminalsThatTip?.length > 0;
    }
    return false;
  }
}

type PaymentListViewModel = keyof PaymentViewModel;
export class PaymentViewModel {
  payment: InternalPaymentIntent;

  id: string;

  merchant_id: string;

  chipConfig: TilledChipConfig;

  @Column({
    order: 0,
    name: 'Transaction Date',
    styling: 'min-width:190px;',
    dateTooltip: true,
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Merchant',
    styling: 'padding-right: 2ch; min-width: 20ch;',
  })
  merchant_name: string;

  @Column({
    order: 2,
    name: 'Payment Method',
    styling: 'min-width:80px;',
    imageStyling: 'max-width: 40px; min-width: 40px; max-height: 40px; min-height: 40px',
    isImage: true,
  })
  transaction_type: string;

  @Column({
    order: 3,
    name: 'Cardholder',
    styling: 'min-width:120px;',
    headerTooltip: 'If the payment method type is bank-to-bank, the billing name is shown.',
  })
  cardholder: string;

  @Column({
    order: 4,
    name: 'Number',
    styling: 'min-width:80px;',
    headerTooltip:
      'The number reflects the last 4 digits of a card number or the last 2 digits of a bank account number.',
  })
  last_4: string;

  @Column({
    order: 5,
    name: 'Payment Intent id',
    styling: 'min-width:225px',
  })
  transaction_id: string;

  // Only shown to Partners if a CAD pricing template exists (not shown to Merchants)
  @Column({
    order: 6,
    name: 'Currency',
    styling: 'min-width:120px;',
  })
  currency: string;

  @Column({
    order: 7,
    name: 'Status',
    isChip: true,
    chipConfig: 'status_chip_config',
    styling: 'min-width:140px',
  })
  status: string;
  status_chip_config: TilledChipConfig;

  @Column({
    order: 8,
    name: 'Amount',
    isCurrency: true,
    styling: 'min-width:100px;',
    headerTooltip: 'The amount reflects the original charge amount.',
  })
  amount: number;

  @Column({
    order: 9,
    isActionList: true,
    styling: 'width: 0%;',
  })
  action: ActionListItem[];
}
