import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { InitialData } from 'app/app.types';
import {
  MERCHANTS_ROUTE,
  PAYMENTS_ROUTE,
  PAYOUTS_ROUTE,
  SOFTWARE_PLATFORMS_ROUTE,
  TERMINALS_ROUTE,
} from 'app/core/constants';
import { Observable, filter, of } from 'rxjs';
import { ProductCode } from '../../projects/tilled-api-client/src/model/productCode';
import { FuseNavigationItem } from '../@fuse/components/navigation';
import { AuthService } from './core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  private cardPresentCapability = false;
  private isReseller = false;
  /**
   * Constructor
   */
  constructor(private _authService: AuthService) {
    this._authService.account$.pipe(filter((a) => a != null)).subscribe((account) => {
      for (const capability of account?.capabilities) {
        if (capability.product_code?.payment_method_type === ProductCode.PaymentMethodTypeEnum.CARD_PRESENT) {
          this.cardPresentCapability = true;
        }
      }
    });
    this._authService.isResellerAccount$.subscribe((isReseller) => {
      this.isReseller = isReseller;
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
    if (this.cardPresentCapability) {
      defaultNavigation.push({
        id: 'terminals',
        isMerchantScope: true,
        isPartnerScope: false,
        title: 'Terminals',
        type: 'basic',
        icon: 'heroicons_outline:calculator',
        link: `/${TERMINALS_ROUTE}`,
        children: [
          {
            id: 'terminals.terminal-details',
            title: 'Terminal Details',
            type: 'basic',
            link: `${TERMINALS_ROUTE}/:account_id/:id`,
          },
        ],
      });
    }

    if (this.isReseller) {
      const merchantsIndex = defaultNavigation.findIndex((item) => item.id === 'merchants');
      defaultNavigation[merchantsIndex].title = 'Portfolio Management';
      defaultNavigation[merchantsIndex].icon = 'mat_outline:corporate_fare';
    }

    return of({
      messages: null,
      navigation: {
        classy: defaultNavigation,
        compact: defaultNavigation,
        default: defaultNavigation,
        futuristic: defaultNavigation,
        horizontal: defaultNavigation,
      },
      notifications: null,
    });
  }
}

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/dashboard',
  },
  {
    id: 'merchants',
    title: 'Merchant Management',
    isPartnerScope: true,
    type: 'collapsable',
    icon: 'heroicons_outline:rectangle-stack',
    children: [
      {
        id: 'merchants.software-platforms',
        isResellerScope: true,
        title: 'Software Platforms',
        type: 'basic',
        link: `/${SOFTWARE_PLATFORMS_ROUTE}`,
        children: [
          {
            id: 'merchants.software-platforms.pricing-templates',
            title: 'Pricing Templates',
            type: 'basic',
            link: '/:account_id/pricing-templates/:id',
          },
        ],
      },
      {
        id: 'merchants.accounts',
        title: 'Merchants',
        type: 'basic',
        link: `/${MERCHANTS_ROUTE}`,
      },
      {
        id: 'terminals',
        isMerchantScope: false,
        isPartnerScope: true,
        excludeFromReseller: true,
        title: 'Terminals',
        type: 'basic',
        link: `/${TERMINALS_ROUTE}`,
      },
      {
        id: 'merchants.pricing-templates',
        title: 'Pricing Templates',
        type: 'basic',
        link: '/pricing-templates',
        excludeFromReseller: true, // Pricing templates for resellers will be moved to the software platform details page
      },
    ],
  },
  /*{
    id: 'outbound-transfers',
    title: 'Outbound Transfers',
    type: 'basic',
    icon: 'heroicons_outline:arrow-right',
    link: `/${OUTBOUND_TRANSFERS_ROUTE}`,

    children: [
      {
        id: 'outbound-transfers.outbound-transfer-details',
        title: 'Outbound Transfer Details',
        type: 'basic',
        link: `/:account_id/${OUTBOUND_TRANSFERS_ROUTE}/:id`,
      },
    ],
  },*/
  {
    id: 'payments',
    title: 'Payments',
    type: 'basic',
    icon: 'heroicons_outline:credit-card',
    link: `/${PAYMENTS_ROUTE}`,

    children: [
      {
        id: 'payments.payment-details',
        title: 'Payment Details',
        type: 'basic',
        link: `/:account_id/${PAYMENTS_ROUTE}/:id`,
      },
    ],
  },
  {
    id: 'payouts',
    title: 'Payouts',
    type: 'basic',
    icon: 'heroicons_outline:building-library',
    link: `/${PAYOUTS_ROUTE}`,
  },
  {
    id: 'disputes',
    title: 'Disputes',
    type: 'basic',
    icon: 'heroicons_outline:exclamation-triangle',
    link: '/disputes',
  },
  {
    id: 'reports',
    title: 'Reports',
    icon: 'heroicons_outline:folder',
    type: 'basic',
    link: '/reports',
  },
  {
    id: 'customers',
    title: 'Customers',
    type: 'basic',
    isMerchantScope: true,
    isPartnerScope: false,
    icon: 'heroicons_outline:users',
    link: '/customers',
  },
  {
    id: 'commissions',
    title: 'Commissions',
    isAdminScope: true,
    isMerchantScope: false,
    isPartnerScope: true,
    type: 'basic',
    icon: 'mat_outline:article',
    link: '/commissions',
  },
  {
    id: 'settings',
    title: 'Account',
    type: 'basic',
    icon: 'mat_outline:settings',
    link: '/account',
  },
  {
    id: 'developers',
    isPartnerScope: true,
    title: 'Developers',
    type: 'basic',
    link: '/developers',
    icon: 'mat_outline:code',
    excludeFromReseller: true,
  },
];
// TODO: If we wanted to offer different navigation, we would need to spend some time building these out.
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
