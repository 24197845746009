/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Event { 
    /**
     * The id of the account that originated the event.
     */
    account_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Object containing the API resource relevant to the event. For example, a `charge.succeeded` event will have a full charge object as the value.
     */
    data: object;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Description of the event (e.g. `charge.succeeded` or `charge.refunded`).  `account.updated` Occurs whenever an account status or property has changed. This includes capability statuses.  `charge.captured` Occurs whenever a previously uncaptured charge is captured.  `charge.expired` Occurs whenever an uncaptured charge expires.  `charge.failed` Occurs whenever a failed charge attempt occurs.  `charge.succeeded` Occurs whenever a new charge is created and is successful.  `charge.pending` Occurs whenever a pending charge is created.  `charge.refunded` occurs whenever a charge is refunded, including partial refunds  `charge.refund.pending` Occurs whenever a refund is pending, on a card present payment method.  `charge.refund.updated` Occurs whenever a refund is updated, on selected payment methods.  `charge.updated` Occurs when a charge description is updated  `customer.created` Occurs whenever a new customer is created.  `customer.deleted` Occurs whenever a customer is deleted.  `customer.updated` Occurs whenever any property of a customer changes.  `payment_intent.canceled` Occurs when a PaymentIntent is canceled.  `payment_intent.created` Occurs when a new PaymentIntent is created.  `payment_intent.payment_failed` Occurs when a PaymentIntent has failed the attempt to create a payment method or a payment.  `payment_intent.processing` Occurs when a PaymentIntent has started processing.  `payment_intent.requires_action` Occurs when a PaymentIntent transitions to requires_action state  `payment_intent.succeeded` Occurs when a PaymentIntent has successfully completed payment.  `payment_method.attached` Occurs whenever a new payment method is attached to a customer.  `payment_method.detached` Occurs whenever a payment method is detached from a customer.  `report_run.succeeded` Occurs whenever a report run has successfully been uploaded to S3 and our File system.  `report_run.failed` Occurs whenever a report run has failed to be uploaded to S3 or our File system.
     */
    type: Event.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace Event {
    export type TypeEnum = 'account.updated' | 'charge.captured' | 'charge.expired' | 'charge.failed' | 'charge.succeeded' | 'charge.pending' | 'charge.refunded' | 'charge.refund.pending' | 'charge.refund.updated' | 'charge.updated' | 'customer.created' | 'customer.deleted' | 'customer.updated' | 'dispute.created' | 'dispute.updated' | 'payment_intent.canceled' | 'payment_intent.created' | 'payment_intent.payment_failed' | 'payment_intent.processing' | 'payment_intent.requires_action' | 'payment_intent.succeeded' | 'payment_intent.amount_capturable_updated' | 'payment_method.attached' | 'payment_method.detached' | 'payment_method.updated' | 'payout.created' | 'payout.failed' | 'payout.paid' | 'payout.updated' | 'platform_fee.created' | 'platform_fee.refunded' | 'subscription.created' | 'subscription.canceled' | 'subscription.updated' | 'report_run.succeeded' | 'report_run.failed' | 'esignature_document.sent' | 'esignature_document.completed' | 'esignature_signer.sent' | 'esignature_signer.viewed' | 'esignature_signer.completed' | 'outbound_transfer.pending' | 'outbound_transfer.failed' | 'outbound_transfer.canceled' | 'outbound_transfer.succeeded';
    export const TypeEnum = {
        ACCOUNT_UPDATED: 'account.updated' as TypeEnum,
        CHARGE_CAPTURED: 'charge.captured' as TypeEnum,
        CHARGE_EXPIRED: 'charge.expired' as TypeEnum,
        CHARGE_FAILED: 'charge.failed' as TypeEnum,
        CHARGE_SUCCEEDED: 'charge.succeeded' as TypeEnum,
        CHARGE_PENDING: 'charge.pending' as TypeEnum,
        CHARGE_REFUNDED: 'charge.refunded' as TypeEnum,
        CHARGE_REFUND_PENDING: 'charge.refund.pending' as TypeEnum,
        CHARGE_REFUND_UPDATED: 'charge.refund.updated' as TypeEnum,
        CHARGE_UPDATED: 'charge.updated' as TypeEnum,
        CUSTOMER_CREATED: 'customer.created' as TypeEnum,
        CUSTOMER_DELETED: 'customer.deleted' as TypeEnum,
        CUSTOMER_UPDATED: 'customer.updated' as TypeEnum,
        DISPUTE_CREATED: 'dispute.created' as TypeEnum,
        DISPUTE_UPDATED: 'dispute.updated' as TypeEnum,
        PAYMENT_INTENT_CANCELED: 'payment_intent.canceled' as TypeEnum,
        PAYMENT_INTENT_CREATED: 'payment_intent.created' as TypeEnum,
        PAYMENT_INTENT_PAYMENT_FAILED: 'payment_intent.payment_failed' as TypeEnum,
        PAYMENT_INTENT_PROCESSING: 'payment_intent.processing' as TypeEnum,
        PAYMENT_INTENT_REQUIRES_ACTION: 'payment_intent.requires_action' as TypeEnum,
        PAYMENT_INTENT_SUCCEEDED: 'payment_intent.succeeded' as TypeEnum,
        PAYMENT_INTENT_AMOUNT_CAPTURABLE_UPDATED: 'payment_intent.amount_capturable_updated' as TypeEnum,
        PAYMENT_METHOD_ATTACHED: 'payment_method.attached' as TypeEnum,
        PAYMENT_METHOD_DETACHED: 'payment_method.detached' as TypeEnum,
        PAYMENT_METHOD_UPDATED: 'payment_method.updated' as TypeEnum,
        PAYOUT_CREATED: 'payout.created' as TypeEnum,
        PAYOUT_FAILED: 'payout.failed' as TypeEnum,
        PAYOUT_PAID: 'payout.paid' as TypeEnum,
        PAYOUT_UPDATED: 'payout.updated' as TypeEnum,
        PLATFORM_FEE_CREATED: 'platform_fee.created' as TypeEnum,
        PLATFORM_FEE_REFUNDED: 'platform_fee.refunded' as TypeEnum,
        SUBSCRIPTION_CREATED: 'subscription.created' as TypeEnum,
        SUBSCRIPTION_CANCELED: 'subscription.canceled' as TypeEnum,
        SUBSCRIPTION_UPDATED: 'subscription.updated' as TypeEnum,
        REPORT_RUN_SUCCEEDED: 'report_run.succeeded' as TypeEnum,
        REPORT_RUN_FAILED: 'report_run.failed' as TypeEnum,
        ESIGNATURE_DOCUMENT_SENT: 'esignature_document.sent' as TypeEnum,
        ESIGNATURE_DOCUMENT_COMPLETED: 'esignature_document.completed' as TypeEnum,
        ESIGNATURE_SIGNER_SENT: 'esignature_signer.sent' as TypeEnum,
        ESIGNATURE_SIGNER_VIEWED: 'esignature_signer.viewed' as TypeEnum,
        ESIGNATURE_SIGNER_COMPLETED: 'esignature_signer.completed' as TypeEnum,
        OUTBOUND_TRANSFER_PENDING: 'outbound_transfer.pending' as TypeEnum,
        OUTBOUND_TRANSFER_FAILED: 'outbound_transfer.failed' as TypeEnum,
        OUTBOUND_TRANSFER_CANCELED: 'outbound_transfer.canceled' as TypeEnum,
        OUTBOUND_TRANSFER_SUCCEEDED: 'outbound_transfer.succeeded' as TypeEnum
    };
}


