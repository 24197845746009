import { Pipe, PipeTransform } from '@angular/core';
import { InternalPaymentIntent, PricingTemplate } from '@tilled-api-client';

/**
 * Takes a InternalPaymentIntent.PaymentMethodTypesEnum
 */
@Pipe({
    name: 'paymentMethod',
    standalone: true,
})
export class PaymentMethodPipe implements PipeTransform {
  /**
   *
   * @param paymentMethod InternalPaymentIntent.PaymentMethodTypesEnum
   * @returns
   */
  transform(paymentMethod: InternalPaymentIntent.PaymentMethodTypesEnum, paymentType?: false): string {
    if (paymentType) {
      return this.transformToPaymentType(paymentMethod);
    }
    if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.CARD) {
      return 'Card';
    } else if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.ACH_DEBIT) {
      return 'ACH Debit';
    } else if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.EFT_DEBIT) {
      return 'EFT Debit';
    } else if (paymentMethod === InternalPaymentIntent.PaymentMethodTypesEnum.CARD_PRESENT) {
      return 'Card Present';
    }
    return '';
  }

  transformToPaymentType(
    paymentMethod: InternalPaymentIntent.PaymentMethodTypesEnum | PricingTemplate.PaymentMethodTypeEnum,
  ): string {
    if (paymentMethod === 'card') {
      return 'Card-not-present';
    } else if (paymentMethod === 'ach_debit' || paymentMethod === 'eft_debit') {
      return 'Bank-to-bank';
    } else if (paymentMethod === 'card_present') {
      return 'Card-present';
    }
    return '';
  }
}
